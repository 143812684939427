export const countdown = {
  overTime: 0,
  overTimeInterval: null,
  call: null,
  key: "count_time",
  setTime() {
    const ot = localStorage.getItem(this.key);
    const n = parseInt(new Date().getTime() / 1000);
    let t = (this.overTime = parseInt(ot - n));

    if (typeof this.call == "function") {
      if (t < 0) {
        t = 0;
      }
      this.call(t);
    }
    return t;
  },
  start(key, time) {
    this.key = key;
    var endTime = parseInt(new Date().getTime() / 1000) + time;
    localStorage.setItem(this.key, endTime);
  },
  run(key, call = () => {}) {
    this.key = key;
    //回调
    this.call = call;

    if (this.overTimeInterval == null) {
      if (this.setTime() > 0) {
        var _this = this;
        this.overTimeInterval = setInterval(function () {
          //递减
          _this.setTime();
          //console.log(_this.overTime, "倒计时");
          if (_this.overTime <= 0) {
            clearInterval(_this.overTimeInterval);
            _this.overTimeInterval = null;
            console.log("倒计时结束");
            return;
          }
        }, 1000);
      } else {
        console.log("倒计时为0结束");
      }
    }
  },
  stop() {
    clearInterval(this.overTimeInterval);
    this.overTimeInterval = null;
  },
  clear() {
    localStorage.removeItem(this.key);
    if(this.call != undefined){
      this.call(0);
    }
  },
};
