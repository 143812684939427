<template>
  <div>MESSAGE</div>
  <div :style="{ background: '#fff',maxWidth:'900px',margin:'0 auto' }">
    <chat></chat>
  </div>
</template>

<script>
import Chat from '@/components/Chat.vue'
export default {
  components: { Chat },
  name: "storeMessage"
}
</script>

<style>
</style>