<template>
  <div id="MainWrapper" class="container-fluid mm-page">
    <div id="headerwrapper" class="">
      <div id="header" class="clearfix site-wrapper">
        <!-- 头部 -->
        <div class="header-top clearfix" style="margin-bottom: 10px">
          <div id="logo" class="">
            <router-link :to="{ name: 'index' }">
              <img src="@/static/logo10.png" style="width: 220px" />
            </router-link>
          </div>
          <!--Logo ends-->

          <!-- MOBILE LAYOUT STARTS -->
          <div class="mobile-right-controls hidden-md hidden-lg">
            <a>
              <div class="mobile-menu-toggle">
                <span class="mobi-menu-toggle-button"></span>
              </div>
            </a>
            <div class="mobile-search-toggle"></div>
          </div>

          <div id="toplinks" class="clearfix">
            <!-- 登录状态 -->
            <login-state :type="1"></login-state>
            <!-- <div id="searchbox">
              <div id="searchTextboxContainer">
                <input type="text" value="Search" id="searchTextbox" onclick="if (this.value == 'Search'){this.value='';}" onblur="if (this.value == ''){this.value='Search';}" class="ui-autocomplete-input" autocomplete="off" />
              </div>
              <div id="SearchButtonContainer">
                <input type="submit" value="GO" id="searchButton" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="site-wrapper" style="background-color: #f2f2f2; z-index: 1">
      <div class="header-bottom clearfix">
        <!-- Main Top Navigation Start -->
        <div id="topnavigation" class="col-xs-12">
          <ul id="section-Homepage-level1">
            <!-- <li :class="['level1 haschildren first', name == 'about_docs' ? 'selected' : '']">
                <router-link :to="{ name: 'about_docs', query: { key: 'company_profile' } }">
                  <span>{{ $t('footer.about.company') }}</span>
                </router-link>
              </li> -->
            <li :class="['level1 haschildren', name == 'about_qualification' ? 'selected' : '']">
              <router-link :to="{ name: 'about_qualification' }">
                <span>{{ $t('_footer._platform._qualification') }}</span>
              </router-link>
            </li>
            <li :class="['level1 haschildren first', name == 'about_model' ? 'selected' : '']">
              <router-link :to="{ name: 'about_model' }">
                <span>{{ $t('_abt._syms') }}</span>
              </router-link>
            </li>
            <li :class="['level1 haschildren', name == 'about_liability' ? 'selected' : '']">
              <router-link :to="{ name: 'about_liability' }">
                <span>{{ $t('_abt._qyzr') }}</span>
              </router-link>
            </li>
            <li :class="['level1 haschildren', name == 'about_contact' ? 'selected' : '']">
              <router-link :to="{ name: 'about_contact' }">
                <span>{{ $t('_abt._lxwm') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Main Top Navigation Start -->
      </div>
    </div>

    <!-- <div class="static-banner-wrapper" style="margin-top: -50px">
      <div class="static-banner-inner">
        <div class="BannerContainer">
          <img class="BannerImage" :src="require('@/static/img2/image_' + i + '.jpg')" alt="" style="border-width: 0px" />
        </div>
      </div>
    </div> -->

    <!-- 路由 -->
    <router-view></router-view>

    <div id="footerwrapper" class="clearfix">
      <div id="footerwrapperinner" class="site-wrapper clearfix" style="margin-bottom: 0px">
        <div class="footer-left">
          <div id="footerlinks" class="clearfix">
            <ul hidden>
              <li><a href="javascript:">Site map</a></li>
              <li><a href="javascript:">Contact us</a></li>
              <li><a href="javascript:">Privacy &amp; cookies</a></li>
              <li><a href="javascript:">Terms and conditions</a></li>
              <li><a href="javascript:">Accessibility</a></li>
              <li><a href="javascript:" title="Opens in a new window" target="_blank">Online</a></li>
              <li><a title="Opens in a new window" target="_blank" href="javascript:">B2B</a></li>
              <li><a href="javascript:" target="_blank">Modern Slavery Statement</a></li>
              <li><a class="__CookieSetting" href="javascript:void(0);">Cookie Settings</a></li>
            </ul>
          </div>

          <div class="footer-copyright-text">
            <!--Copyright Content Start-->
            <p class="copyright">© 2023 Sears Global Plc. All rights reserved.</p>
            <!--<p class="copyright">&copy; 2020 Next Plc. All rights reserved.</p>-->
          </div>
          <!--Credit Content Start-->
        </div>
        <div class="footer-right clearfix" hidden>
          <div class="footer-right-header">For the latest style news</div>
          <div class="footer-social-links">
            <ul>
              <li class="social-link facebook-link">
                <a href="javascript:" title="Facebook – link to website (opens in a new window)" target="_blank">facebook</a>
              </li>
              <li class="social-link twitter-link">
                <a href="javascript:" title="Twitter – link to website (opens in a new window)" target="_blank">twitter</a>
              </li>
              <li class="social-link pinterest-link">
                <a href="javascript:" title="Pinterest – link to website (opens in a new window)" target="_blank">pinterest</a>
              </li>
              <li class="social-link instagram-link">
                <a href="javascript:" title="Instagram – link to website (opens in a new window)" target="_blank">instagram</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginState from '@/components/common/LoginState.vue'
export default {
  name: 'LayoutVue',
  components: {
    LoginState
  },
  data() {
    return {
      name: 'about_model',
      i: 1
    }
  },
  watch: {
    $route(_new) {
      this.name = this.getName(_new.name)
      this.i = parseInt(Math.random() * 4) + 1
    }
  },
  created() {
    this.name = this.getName(this.$route.name)
    this.i = parseInt(Math.random() * 4) + 1
  },
  methods: {
    getName(name) {
      if (name == 'about_liabilitys') {
        return 'about_liability'
      }
      return name
    }
  }
}
</script>

<style>
@import url('@/static/css2/about.css');
</style>
