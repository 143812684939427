<template>
  <div data-spm="1000024" name="feedback" style="display: block">
    <div class="product-reviews" id="feedback">
      <div class="product-evaluation">
        <div id="transction-feedback" class="feedback-container" data-widget-cid="widget-1">
          <div class="customer-reviews">{{ $t('detail.customer.reviews') }} ({{ commentInfo.total }})</div>
          <!-- 统计栏 -->
          <div class="rate-detail util-clearfix">
            <ul class="rate-list">
              <li>
                <span class="r-title">5 Stars</span>
                <span class="r-graph">
                  <b class="r-graph-scroller" :style="'width:' + (commentInfo.s5 && commentInfo.s5.rate) + '%;'"></b>
                </span>
                <span class="r-num fb-star-list-href" data="5 Stars">{{ commentInfo.s5 && commentInfo.s5.rate }}%</span>
              </li>
              <li>
                <span class="r-title">4 Stars</span>
                <span class="r-graph">
                  <b class="r-graph-scroller" :style="'width:' + (commentInfo.s4 && commentInfo.s4.rate) + '%;'"></b>
                </span>
                <span class="r-num fb-star-list-href" data="4 Stars">{{ commentInfo.s4 && commentInfo.s4.rate }}%</span>
              </li>
              <li>
                <span class="r-title">3 Stars</span>
                <span class="r-graph">
                  <b class="r-graph-scroller" :style="'width:' + (commentInfo.s3 && commentInfo.s3.rate) + '%;'"></b>
                </span>
                <span class="r-num fb-star-list-href" data="3 Stars">{{ commentInfo.s3 && commentInfo.s3.rate }}%</span>
              </li>
              <li>
                <span class="r-title">2 Stars</span>
                <span class="r-graph">
                  <b class="r-graph-scroller" :style="'width:' + (commentInfo.s2 && commentInfo.s2.rate) + '%;'"></b>
                </span>
                <span class="r-num fb-star-list-href" data="2 Stars">{{ commentInfo.s2 && commentInfo.s2.rate }}%</span>
              </li>
              <li>
                <span class="r-title">1 Star</span>
                <span class="r-graph">
                  <b class="r-graph-scroller" :style="'width:' + (commentInfo.s1 && commentInfo.s1.rate) + '%;'"></b>
                </span>
                <span class="r-num fb-star-list-href" data="1 Stars">{{ commentInfo.s1 && commentInfo.s1.rate }}%</span>
              </li>
            </ul>
            <div class="rate-score">
              <span class="rate-score-number">
                <b>{{ commentInfo.avg }}</b>
                <span> / 5</span>
              </span>
              <div class="star-view-big">
                <span :style="'width:' + ((commentInfo.avg / 5).toFixed(3) * 100) + '%'"></span>
              </div>
            </div>
          </div>
          <!-- 筛选栏 -->
          <div class="filter-wrap clearfix">
            <span class="f-title">All Feedback </span>
            <div class="f-star-dropdown">
              <span class="fb-star-selector">All Stars (<em>{{ commentInfo.total }}</em>)</span>
              <div id="filter_star_list" class="fb-star-list">
                <ul>
                  <li class="active"><a class="fb-star-list-href" data="all Stars" href="javascript:void(0);"
                      rel="nofollow">All Stars</a></li>
                  <li><a class="fb-star-list-href" data="5 Stars" href="javascript:void(0);" rel="nofollow">5 Stars</a>
                  </li>
                  <li><a class="fb-star-list-href" data="4 Stars" href="javascript:void(0);" rel="nofollow">4 Stars</a>
                  </li>
                  <li><a class="fb-star-list-href" data="3 Stars" href="javascript:void(0);" rel="nofollow">3 Stars</a>
                  </li>
                  <li><a class="fb-star-list-href" data="2 Stars" href="javascript:void(0);" rel="nofollow">2 Stars</a>
                  </li>
                  <li><a class="fb-star-list-href" data="1 Stars" href="javascript:void(0);" rel="nofollow">1 Star</a>
                  </li>
                  <li class="separator">
                    <hr>
                  </li>
                  <li><a class="fb-star-list-href" data="All positive" href="javascript:void(0);" rel="nofollow">All
                      Positive</a></li>
                  <li><a class="fb-star-list-href" data="All cricital" href="javascript:void(0);" rel="nofollow">All
                      Negative </a></li>
                </ul>
              </div>
            </div>
            <div class="f-filter-list">
              <label>
                <input id="cb-withPictures-filter" type="checkbox" value="withPictures">Photos
              </label>
              <label class="f-filter-additional">
                <input id="cb-withAdditionalFeedback-filter" type="checkbox" value="withAdditionalFeedback">Additional
                Feedback
              </label>
            </div>
          </div>
          <!-- 筛选栏 -->
          <!-- <div class="feedback-action">
              <div class="fb-filter-country"><label>
                  <input id="cb-onlyFromMyCountry-filter" type="checkbox" value="onlyFromMyCountry">Only from your country
                </label>
              </div>
              <div id="simple-pager" class="util-clearfix simple-pager">
                <div class="ui-pagination ui-pagination-front ui-pagination-pager util-right">
                  <span href="#" class="ui-pagination-prev ui-pagination-disabled">Previous</span>

                  <a pageno="2" class="ui-pagination-next ui-goto-page">Next</a>

                  <label class="ui-label">1/277</label>
                </div>
              </div>
              <div class="fb-sort">
                <span class="fb-sort-selector">Sort by default</span>
                <div class="fb-sort-list" id="sort-by-container">
                  <ul>
                    <li><a class="fb-sort-list-href" data="sortlarest@feedback" href="javascript:void(0);" rel="nofollow">Sort by latest</a></li>
                  </ul>
                </div>
              </div>
              <div class="fb-translate">
                <label>
                  <input id="cb-translate" type="checkbox" value="translate" checked="">Translate to English
                </label>
              </div>
            </div> -->
          <!-- 评论列表 -->
          <div class="feedback-list-wrap">
            <div class="feedback-item clearfix" v-for="(item, i) in commentList.data" :key="i">
              <div class="fb-user-info">
                <span class="user-name">
                  <a href="" target="_blank" rel="nofollow" name="member_detail">{{ item.nickname }}</a>
                </span>
                <div class="user-country">
                  <img :src="item.avatar" style="width:30px" />
                </div>
              </div>
              <div class="fb-main">
                <div class="f-rate-info">
                  <span class="star-view"><span :style="'width:' + ((item.star / 5).toFixed(2) * 100) + '%'"></span></span>
                </div>
                <div class="user-order-info">
                  <span class="first">
                    <strong style="display:flex;">
                      <div v-for="(svo, sk) in (item.detail && item.detail.sku)" :key="sk" style="margin-right: 5px;">
                        {{ sk }}:{{ svo }}</div>
                    </strong>
                  </span>
                </div>
                <div class="f-content">
                  <dl class="buyer-review">
                    <dt class="buyer-feedback">
                      <span>{{ item.comment }}</span>
                      <span class="r-time-new">{{ item.create_time }}</span>
                    </dt>
                    <dd class="r-photo-list" data-role="photo-list" data-image-num="5">
                      <ul class="util-clearfix">
                        <li :class="['pic-view-item', img[i] == k ? 'active' : '']" v-for="(vo, k) in item.imgs" :key="k"
                          @click="onImg(i, k)">
                          <img :src="vo">
                        </li>
                      </ul>
                      <div class="big-photo-view" v-if="img[i] >= 0" style="display: inline-block;">
                        <a href="javascript:;" class="close-view" data-role="close" @click="onImgClose(i)"></a>
                        <div class="photo-view-main">
                          <div class="photo-view-content" data-role="photo-view" data-curr-index="">
                            <img :src="item.imgs[img[i]]" />
                          </div>
                          <a href="javascript:;" class="fb-arrow fb-left-arrow" data-role="prev"></a>
                          <a href="javascript:;" class="fb-arrow fb-right-arrow" data-role="next"></a>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页栏 -->
          <div id="complex-pager" class="util-clearfix complex-pager">
            <div class="ui-pagination ui-pagination-front ui-pagination-body util-clearfix">
              <div class="ui-pagination-navi util-left">
                <!-- 上一页 -->
                <span class="ui-pagination-prev ui-pagination-disabled" v-if="commentList.pageNo == 1">&lt;&lt;
                  Previous</span>
                <a class="ui-pagination-prev ui-goto-page" v-else @click="onPrev()">&lt;&lt; Previous</a>

                <!-- 页数 -->
                <template v-for="p in commentList.totalPage" :key="p">
                  <span class="ui-pagination-active" v-if="commentList.pageNo == p">{{ p }}</span>
                  <a pageno="2" class="ui-goto-page" v-else @click="getCommentList(p)">{{ p }}</a>
                </template>
                <span>...</span>

                <!-- 下一页 -->
                <span class="ui-pagination-next ui-pagination-disabled"
                  v-if="commentList.pageNo == commentList.totalPage">Next &gt;&gt;</span>
                <a pageno="2" class="ui-pagination-next ui-goto-page" v-else @click="onNext()">Next &gt;&gt;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: "EvaluationVue",
  data() {
    return {
      //评论列表
      commentInfo: {},
      commentList: [],

      //选中查看
      img: {},
    };
  },
  created() {

  },
  methods: {
    init() {
      //获取评论
      this.getCommentInfo();
      this.getCommentList(1);
    },
    onImg(i, k) {
      this.img[i] = k;
    },
    onImgClose(i) {
      delete this.img[i];
    },
    /**
     * 评论详情
     */
    getCommentInfo() {
      this.$api.goods
        .getCommentInfo({
          id: this.$route.query.id,
        })
        .then((res) => {
          this.commentInfo = res.data;
        });
    },
    /**
     * 获取评论列表
     */
    onPrev() {
      this.getCommentList(this.commentList.pageNo - 1);
    },
    onNext() {
      this.getCommentList(this.commentList.pageNo + 1);
    },
    getCommentList(page) {
      //获取商品
      // let hide = this.$message.loading({ content: "loading...", key: "msg" });
      this.$api.goods
        .getCommentList({
          id: this.$route.query.id,
          page: page,
          size: 5,
        })
        .then((res) => {
          // hide();
          this.commentList = res.data;
        });
    },
  },
};
</script>
<style scoped src="@/static/css/extend.css"></style>
<style scoped src="../../static/css/index-new.css"></style>