import request from '@/utils/request'

const api = {
  getPrizeList: 'turntable/getPrizeList',
  getMyPrizeList: 'turntable/getMyPrizeList',
  getAlready: 'turntable/getAlready',
  getBigPrizeList: 'turntable/getBigPrizeList',
  getConsume: 'turntable/getConsume',
  getMyPrize: 'turntable/getMyPrize',
  draw: 'turntable/draw',
  receive: 'turntable/receive'
}
export default {
  receive(parameter) {
    return request({
      url: api.receive,
      method: 'post',
      data: parameter
    })
  },
  getBigPrizeList(parameter) {
    return request({
      url: api.getBigPrizeList,
      method: 'post',
      data: parameter
    })
  },
  getPrizeList(parameter) {
    return request({
      url: api.getPrizeList,
      method: 'post',
      data: parameter
    })
  },
  getAlready(parameter) {
    return request({
      url: api.getAlready,
      method: 'post',
      data: parameter
    })
  },
  getMyPrizeList(parameter) {
    return request({
      url: api.getMyPrizeList,
      method: 'post',
      data: parameter
    })
  },
  getConsume(parameter) {
    return request({
      url: api.getConsume,
      method: 'post',
      data: parameter
    })
  },
  getMyPrize(parameter) {
    return request({
      url: api.getMyPrize,
      method: 'post',
      data: parameter
    })
  },
  draw(parameter) {
    return request({
      url: api.draw,
      method: 'post',
      data: parameter
    })
  }
}
