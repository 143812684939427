<template>
  <!-- 头部 -->
  <HeadNav></HeadNav>

  <!-- 搜索框 -->
  <!-- <SearchBox ref="SearchBox"></SearchBox> -->

  <!-- 内容 -->
  <a-spin tip="Loading..." :spinning="loading">
    <div id="root" style="-margin-top: 121px">
      <div class="glodetail-wrap">
        <!-- 导航 -->
        <div style class="store-header">
          <div class="store-header-nav store-header-nav-light">
            <ul class="next-menu next-hoz next-nav next-primary next-active next-bottom store-header-nav-light">
              <li class="next-menu-item next-nav-item">
                <div class="next-menu-item-inner">
                  <span class="next-menu-item-text">
                    <router-link class="store-header-link" :to="{ name: 'storeHome', query: { id: store && store.id } }">
                      {{ $t('detail.store.home') }}
                    </router-link>
                  </span>
                </div>
              </li>
              <li role="listitem" title="Sale Items" tabindex="-1" class="next-menu-item next-nav-item">
                <div class="next-menu-item-inner">
                  <span class="next-menu-item-text" aria-selected="false">
                    <router-link class="store-header-link" :to="{ name: 'storeSales', query: { id: store && store.id } }">
                      {{ $t('detail.sale.items') }}
                    </router-link>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="product-main" data-spm="1000016" data-spm-max-idx="1" data-spm-anchor-id="a2g0o.detail.0.1000016.4bde74c0nxkifs">
          <div class="product-main-wrap">
            <!-- 显示图片 -->
            <div class="img-view-wrap" data-spm="1000017">
              <div style="position: relative; top: 0px">
                <div class="image-viewer">
                  <div class="image-view-magnifier-wrap" @mousemove="onViewStart($event)" @mouseleave="onViewStop">
                    <img v-if="attrSkuImg?.id" ref="view" class="magnifier-image" style="height: auto" :src="attrSkuImg.img" />
                    <img v-else ref="view" class="magnifier-image" style="height: auto" :src="getInfo('imgs') && getInfo('imgs')[currentThumb].img" />
                    <div class="magnifier-loading-wrap" style="display: none">
                      <div class="magnifier-loading-cover"></div>
                      <div class="magnifier-loading"></div>
                    </div>
                    <div class="magnifier-cover"></div>
                  </div>
                  <!-- 缩略图 -->
                  <div class="images-view-wrap">
                    <ul class="images-view-list">
                      <li v-for="(item, i) in getInfo('imgs')" :key="i" :class="[currentThumb === i ? 'active' : '']" @mousemove="onThumb(i)">
                        <div class="images-view-item">
                          <img :src="item.thumb" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- 商品信息 -->
            <div class="product-info">
              <div class="product-title">
                <h1 class="product-title-text">{{ getInfo('name') }}</h1>
              </div>
              <!-- 评星 -->
              <div class="product-reviewer">
                <div class="overview-rating" aria-haspopup="true" aria-expanded="false">
                  <div class="next-rating next-rating-large next-rating-grade-high" tabindex="0" role="group" aria-label="评分选项">
                    <!-- 空星 -->
                    <div class="next-rating-base next-rating-base-disabled">
                      <div class="next-rating-underlay" aria-hidden="true">
                        <span class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </span>
                        <span class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </span>
                        <span class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </span>
                        <span class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </span>
                        <span class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </span>
                      </div>
                      <!-- 实星 -->
                      <div class="next-rating-overlay" :style="'width: ' + getStarWidth() + 'px'">
                        <label class="next-rating-icon" v-for="i in parseInt(info.star)" :key="i">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </label>
                        <label class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </label>
                        <label class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </label>
                        <label class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </label>
                        <label class="next-rating-icon">
                          <i class="next-icon next-icon-favorites-filling next-medium"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <span class="overview-rating-average">
                    {{ info.star }}
                  </span>
                  <i class="next-icon next-icon-arrow-down next-xxs" style="margin-left: 0px"></i>
                </div>
                <!-- <span class="product-reviewer-reviews black-link">0 Reviews</span> -->
                <span class="product-reviewer-sold">{{ info.orders }} {{ $t('detail.orders') }}</span>
              </div>

              <!-- 平常价格 -->
              <div class="product-price">
                <div class="product-price-current">
                  <span class="product-price-value">US ${{ getAttrVal('price', 2) }}</span>
                </div>
                <div class="product-price-original">
                  <div class="product-price-del">
                    <span class="product-price-value">US ${{ getAttrVal('cost_price', 2) }}</span>
                  </div>
                  <span class="product-price-mark">-{{ info && info.discount }}%</span>
                </div>
              </div>

              <div class="split-line-thin"></div>
              <!-- 商品sku -->
              <div class="product-sku">
                <div class="sku-wrap">
                  <!-- 属性 -->
                  <div class="sku-property" v-for="(item, i) in attr" :key="i">
                    <div class="sku-title">
                      {{ item.name }}:
                      <span class="sku-title-value">
                        {{ selected[i] && selected[i].name }}
                      </span>
                    </div>
                    <ul class="sku-property-list">
                      <li :class="['sku-property-item', selected[i] && selected[i].id == vo.id ? 'selected' : '', hasAttr(vo.id) ? '' : 'disabled']" v-for="(vo, k) in item.children" :key="k" @click="onAttr(vo, i)">
                        <div class="sku-property-text" v-if="vo.type == 1">
                          <span>{{ vo.name }}</span>
                        </div>
                        <div class="sku-property-image" v-if="vo.type == 2">
                          <img :src="vo.thumb" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- <div class="sku-property">
                    <div class="sku-title">
                      Size: <span class="sku-title-value">One Size</span>
                    </div>
                    <ul class="sku-property-list">
                      <li class="sku-property-item selected" aria-haspopup="true" aria-expanded="false">
                        <div class="sku-property-text"><span>One Size</span></div>
                      </li>
                      <li class="sku-size-info">Size Info</li>
                    </ul>
                  </div>-->
                </div>
              </div>
              <!-- 购买数量 -->
              <div class="product-quantity clearfix">
                <div class="product-quantity-title">{{ $t('detail.quantity') }}:</div>
                <span class="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker">
                  <span class="next-input-group">
                    <span class="next-input-group-addon next-before">
                      <button @click="onSubNum()" :disabled="buyNum == 1" type="button" class="next-btn next-medium next-btn-normal" role="button">
                        <i class="next-icon next-icon-minus next-xs next-btn-icon next-icon-alone"></i>
                      </button>
                    </span>
                    <span class="next-input next-medium next-input-group-auto-width">
                      <input height="100%" autocomplete="off" min="1" v-model="buyNum" @input="onNumber" />
                    </span>
                    <span class="next-input-group-addon next-after">
                      <button @click="onAddNum()" :disabled="buyNum == getAttrVal('total')" type="button" class="next-btn next-medium next-btn-normal" role="button">
                        <i class="next-icon next-icon-add next-xs next-btn-icon next-icon-alone"></i>
                      </button>
                    </span>
                  </span>
                </span>
                <div class="product-quantity-info">
                  <div class="product-quantity-tip">
                    <span>
                      <span class>{{ getAttrVal('total') }} {{ $t('detail.pieces.available') }}</span>
                    </span>
                  </div>
                </div>
                <!-- <div class="product-quantity-info">
                  <div class="product-quantity-package">
                    Additional 3% off (5 Pieces or more)
                  </div>
                  <div class="product-quantity-tip">
                    <span><span class="">414 Pieces available </span></span>
                  </div>
                </div>-->
              </div>
              <div class="product-warranty"></div>
              <!-- 运送地点 -->
              <!-- <div class="product-delivery">
                <span class="product-delivery-title">Ships to</span>
                <span class="product-delivery-icon">
                  <span class="comet-icon comet-icon-address">
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                      <path d="M515.84 75.306667c208.853333 0 365.866667 145.706667 365.866667 355.413333 0 119.936-80.64 263.082667-240.341334 431.872l-17.749333 18.517333c-18.048 18.624-37.034667 37.546667-56.96 56.768a73.173333 73.173333 0 0 1-101.696-0.042666l-9.813333-9.536-9.898667-9.706667-19.285333-19.221333-18.645334-19.029334C236.352 703.829333 149.973333 554.88 149.973333 430.72c0-209.706667 157.013333-355.413333 365.866667-355.413333z m0 62.72c-175.232 0-303.146667 118.72-303.146667 292.693333 0 96.682667 68.373333 221.056 206.506667 370.901333l16.576 17.749334c14.08 14.890667 28.842667 30.016 44.266667 45.397333l18.816 18.56 9.728 9.429333a10.453333 10.453333 0 0 0 14.506666 0c6.506667-6.272 12.885333-12.501333 19.157334-18.688l18.517333-18.453333a2115.925333 2115.925333 0 0 0 35.072-36.202667l16.576-17.749333c8.128-8.810667 16.021333-17.536 23.68-26.176l14.954667-17.152c112.298667-130.645333 167.936-240.384 167.936-327.616 0-173.973333-127.914667-292.693333-303.146667-292.693333z m8.96 125.44c91.605333 0 165.76 74.666667 165.76 166.698666s-74.154667 166.698667-165.76 166.698667-165.76-74.666667-165.76-166.698667c0-92.010667 74.154667-166.698667 165.76-166.698666z m0 62.72c-56.853333 0-103.04 46.506667-103.04 103.978666 0 57.472 46.186667 103.978667 103.04 103.978667s103.04-46.506667 103.04-103.978667c0-57.472-46.186667-103.978667-103.04-103.978666z" />
                    </svg>
                  </span>
                </span>
                <span class="product-delivery-to">American Samoa</span>
              </div> -->
              <!-- 运费说明 -->
              <div class="product-dynamic-shipping">
                <div>
                  <div class="dynamic-shipping">
                    <div class="dynamic-shipping-line dynamic-shipping-titleLayout">
                      <span>
                        <span style="color: #222222">
                          <strong>{{ $t('detail.delivery') }}</strong>
                        </span>
                      </span>
                    </div>
                    <div class="dynamic-shipping-line dynamic-shipping-contentLayout">
                      <span>
                        <span style="color: #01a971">{{ $t('detail.free.shipping') }}</span>
                      </span>
                    </div>
                    <!-- <div class="dynamic-shipping-line dynamic-shipping-contentLayout">
                      <span>
                        <span style="color: #666666">From</span>
                        <span style="color: #222222">China</span>
                        <span style="color: #666666">to</span>
                        <span style="color: #222222">Hong Kong,China</span>
                        <span style="color: #666666">via Aliexpress Selection Standard</span>
                      </span>
                    </div> -->
                    <div class="dynamic-shipping-line dynamic-shipping-contentLayout">
                      <span>
                        <span style="color: #666666">{{ $t('detail.estimated.delivery') }}:</span>
                        <span style="color: #222222">1-5</span>
                        <span style="color: #666666">{{ $t('detail.days') }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 购买 -->
              <div class="product-action">
                <span class="buy-now-wrap">
                  <button @click="onBuyNow" type="button" class="next-btn next-large next-btn-primary buynow">
                    {{ $t('detail.buy.now') }}
                  </button>
                </span>
                <span class="addcart-wrap">
                  <button @click="onAddToCart" type="button" class="next-btn next-large next-btn-primary addcart">
                    {{ $t('detail.add.to.cart') }}
                  </button>
                </span>
                <span class="addcart-wrap">
                  <button @click="onAddToCartAll" type="button" class="next-btn next-large next-btn-primary addcart">
                    {{ $t('_ssd._qjrgwc') }}
                  </button>
                </span>
                <span @click="onWishlist()" class="add-wishlist-wrap">
                  <div class="add-wishlist">
                    <i :class="['next-icon', 'next-large', info.is_wish === 1 ? 'next-icon-favourite' : 'next-icon-favourited']"></i>
                    <span class="add-wishlist-num">{{ info.wish_num_str }}</span>
                  </div>
                </span>
              </div>
              <!-- 其他说明 -->
              <div class="buyer-pretection">
                <div class="buyer-pretection-wrap clearfix">
                  <a class="buyer-pretection-info clearfix" href="javascript:">
                    <div class="buyer-pretection-icon">
                      <svg class="icon-svg" style="font-size: 30px">
                        <use xlink:href="#icon-BP" />
                      </svg>
                    </div>
                    <div class="buyer-pretection-content">
                      <div class="buyer-pretection-title bold">35-{{ $t('detail.buyer.protection') }}</div>
                      <div class="buyer-pretection-context">
                        {{ $t('detail.money.guarantee') }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="product-eu-law"></div>
            </div>
            <div class="may-like" style="display: none"></div>
            <!-- 右侧推荐物品 -->
            <div class="may-like">
              <div class="may-like-title">{{ $t('detail.recommended.for.you') }}</div>
              <div v-for="(item, i) in recommended" :key="i" class="simple-card-container" style="width: 135px">
                <div class="img-container" style="width: 135px; height: 135px">
                  <router-link :to="{ name: 'detail', query: { id: item.id } }">
                    <img class="simple-card-img" :src="item.goods && item.goods.img" />
                  </router-link>
                </div>
                <div class="simple-card-price">US ${{ item.goods && item.goods.price }}</div>
              </div>
            </div>

            <!-- 悬浮菜单 -->
            <div class="product-fix-sns">
              <div class="product-fix-wrap"></div>
            </div>
          </div>
        </div>

        <!-- 下面商品详情 -->
        <div class="product-extend">
          <!-- 店铺横幅 -->
          <div class="store-banner product-store-banner" v-if="storeContent.banner !== ''" style="background: #fff">
            <img :src="storeContent.banner" style="width: 100%" alt="" />
          </div>
          <!-- 往下拉时，固定头部 -->
          <div class="detail-container container flex">
            <!-- 左侧店铺分类列表 -->
            <div class="product-extend-sidebar">
              <div class="top-sell" data-spm="100009" data-spm-max-idx="6">
                <div class="top-sell-title">{{ $t('detail.top.selling.products') }}</div>

                <div v-for="(item, i) in topSellingList" :key="i" class="recommend-item top-sell-rcmd-item">
                  <div class="item-img-container" style="height: 176px; width: 176px">
                    <router-link :to="{ name: 'detail', query: { id: item.id } }">
                      <img class="item-img" :src="item.goods && item.goods.img" />
                    </router-link>
                  </div>
                  <div class="item-info">
                    <div class="item-price line-limit-length">
                      <span>US ${{ item.goods && item.goods.price }}</span>
                    </div>
                    <div class="item-rating">
                      <span class="item-sold sold-normal-float">{{ item.orders }}&nbsp;{{ $t('detail.sold') }}</span>
                      <span class="item-rating-info">
                        <svg class="icon-svg rating-star" style="font-size: 16px; color: var(--main-color1)">
                          <use xlink:href="#icon-rating-star"></use>
                        </svg>
                        <span class="item-rating-number">{{ item.star }}</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="view-more-block">
                  <a href="javascript:" class="view-more" data-spm-anchor-id="a2g0o.detail.100009.6">
                    <span>View More&nbsp;‎</span>
                    <svg class="icon-svg icon-arrow" style="font-size: 5px">
                      <use xlink:href="#icon-arrow-right"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <!-- 销量排行 -->
            <!-- 左侧大板块 -->
            <div class="product-detail" id="product-detail">
              <div class="instore-info-container">
                <!-- 店铺信息 -->
                <div class="store-info">
                  <div class="store-info-top notranslate">
                    <div class="shop-name">
                      <router-link :to="{ name: 'storeHome', query: { id: store && store.id } }">
                        {{ store && store.name }}
                      </router-link>
                    </div>
                    <!-- 等级 -->
                    <div>
                      <!-- <div class="lev-bg" v-if="store.level > 10">
                      <div v-for="(vo, k) in store.level - 10" :key="k">
                        <img src="@/static/level/c.png" />
                      </div>
                    </div> -->
                      <div class="lev-bg" v-if="store.level > 5">
                        <div v-for="(vo, k) in store.level - 5" :key="k">
                          <img src="@/static/level/c.png" />
                        </div>
                      </div>
                      <div class="lev-bg" v-else>
                        <div v-for="(vo, k) in store.level" :key="k">
                          <img src="@/static/level/b.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="store-info-content">
                    <div class="store-info-data">
                      <div class="follower-num">
                        {{ store.follow_str }}&nbsp;
                        <span class="store-info-text">{{ $t('base.followers') }}</span>
                      </div>
                    </div>
                    <div class="store-info-contact">
                      <div class="contact-block">
                        <a @click="onMessage(store.sid, info.id)">
                          <!-- <router-link :to="{ name: 'message', query: { code: store.sid, goods_id: info.id } }" v-if="store.sid && info.id"> -->
                          <svg class="icon-svg img-contact" style="font-size: 16px">
                            <use xlink:href="#icon-contact" />
                          </svg>
                          <span class="contact-text">{{ $t('base.contact') }}</span>
                          <!-- </router-link> -->
                        </a>
                      </div>
                    </div>
                    <div class="store-info-btn">
                      <div v-if="store.is_follow == 1" @click="onFollow()" class="add-store-list follow-btn">
                        <i class="next-icon next-icon-add next-xxs"></i>
                        <span class="asl-btn">{{ $t('base.follow') }}</span>
                      </div>
                      <div v-if="store.is_follow == 2" @click="onFollow()" class="add-store-list store-wished follow-btn">
                        <span class="asl-btn">{{ $t('base.following') }}</span>
                      </div>
                      <div class="visit-store-btn">
                        <span>
                          <router-link :to="{ name: 'storeHome', query: { id: store.id } }">
                            {{ $t('base.visit.store') }}
                          </router-link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 店铺旁边的推荐商品 -->
                <div class="instore-info-rcmds">
                  <div v-for="(item, i) in recommendedStore" :key="i" class="simple-card-container" style="width: 108px">
                    <div class="img-container" style="width: 108px; height: 108px">
                      <router-link :to="{ name: 'detail', query: { id: item.id } }">
                        <img class="simple-card-img" :src="item.goods && item.goods.img" />
                      </router-link>
                    </div>
                    <div class="simple-card-price">US ${{ item.goods && item.goods.price }}</div>
                  </div>
                </div>
              </div>

              <!-- 商品内容 -->
              <div class="product-detail-tab">
                <div class="detail-extend-tab">
                  <!-- 菜单栏 -->
                  <div class="detail-tab-bar">
                    <ul class="tab-lists avoid-user-select">
                      <li :class="['tab-item', tabbar == 1 ? 'active' : '']" @click="onTabbar(1)">
                        <div class="tab-inner">
                          <span class="tab-inner-text">{{ $t('detail.description') }}</span>
                        </div>
                      </li>
                      <li :class="['tab-item', tabbar == 2 ? 'active' : '']" @click="onTabbar(2)">
                        <div class="tab-inner">
                          <span class="tab-inner-text">
                            {{ $t('detail.customer.reviews') }}
                            <span class="fdbc-num">&nbsp;</span>
                          </span>
                        </div>
                      </li>
                      <li :class="['tab-item', tabbar == 3 ? 'active' : '']" @click="onTabbar(3)" v-if="info.goods && info.goods.specs.length">
                        <div class="tab-inner">
                          <span class="tab-inner-text">{{ $t('detail.specifications') }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <!-- 商品详情 -->
                    <div v-show="tabbar == 1">
                      <div class="product-overview">
                        <div id="product-description" data-spm="1000023" class="product-description">
                          <div class="origin-part box-sizing" v-html="getInfo('content')"></div>
                        </div>
                      </div>
                    </div>
                    <!-- 评价 -->
                    <evaluation ref="evaluation" v-show="tabbar == 2"></evaluation>

                    <!-- 规格 -->
                    <div v-show="tabbar == 3">
                      <div class="product-specs" data-pl="product-specs">
                        <ul class="product-specs-list util-clearfix">
                          <li class="product-prop line-limit-length" v-for="(item, i) in info.goods && info.goods.specs" :key="i">
                            <span class="property-title">{{ item.attrName }}:&nbsp;</span>
                            <span class="property-desc line-limit-length" title="ColdYingan">{{ item.attrValue }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 平台推荐其他商品 -->

              <div class="bottom-recommendation">
                <div class="product-more">
                  <div class="in-store-more">
                    <span class="product-more-title">Seller Recommendations</span>
                    <div class="_1nker">
                      <router-link :to="{ name: 'detail', query: { id: item.id } }" class="_1lP57 _2S8eq" style="width: 190px" v-for="(item, i) in recommended15" :key="i">
                        <div class="_1p6Lx" style="padding-bottom: 100%">
                          <img class="_36QXb product-img" :src="item.img" />
                        </div>
                        <div class="_1KpBb">
                          <div class="WvaUg">
                            <div class="_1CCSZ">
                              <span style="font-size: 12px">US $</span>
                              <span style="font-size: 20px">{{ item.price }}</span>
                              <!-- <span style="font-size: 12px">.</span> -->
                              <!-- <span style="font-size: 12px">7</span> -->
                            </div>
                            <div class="_3QAcC"></div>
                          </div>
                          <!-- <div class="_3SmzP">
                          <div><span class="_2VtIx vcAi3" style="color: rgb(253, 56, 79)">Extra 1% off with coins</span></div>
                        </div> -->
                          <div class="_33O19">
                            <span class="_2PeJI">{{ item.orders }} {{ $t('detail.orders') }}</span>
                          </div>
                          <div class="_24F0J">
                            <h1 class="WccSj">
                              {{ item.name }}
                            </h1>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
  <!-- 回到顶部 -->
  <GoTop></GoTop>
  <!-- 页脚 -->
  <Footer></Footer>
</template>

<script>
import GoTop from '@/components/GoTop.vue'
import HeadNav from '@/components/common/HeadNav.vue'
import Footer from '@/components/Footer.vue'
// import SearchBox from '@/components/detail/SearchBox.vue'
import Evaluation from '@/components/detail/Evaluation.vue'
export default {
  name: 'detailVue',
  components: {
    GoTop,
    HeadNav,
    // SearchBox,
    Footer,
    Evaluation
  },
  data() {
    return {
      //加载中
      loading: true,
      //商品信息
      goods: {},
      info: {},
      attr: [],
      sku: {},
      tags: {},
      store: {
        name: '',
        follow: 0,
        is_follow: 1
      },
      storeContent: {
        content: '',
        banner: ''
      },
      recommended: [],
      recommendedStore: [],
      recommended15: [],
      topSellingList: [],

      //选中
      selected: {},
      haveattr: [],
      //选择完成
      attrSku: {},

      //图片
      currentThumb: 0,

      //购买
      buyNum: 1,

      //菜单
      tabbar: 1,

      //选中带图的规格
      attrSkuImg: {}
    }
  },
  watch: {
    $route() {
      location.reload()
    }
  },
  created() {
    let query = this.$route.query

    this.info = {
      star: 0
    }

    let that = this
    this.$api.goods
      .getGoodsInfo({ id: query.id })
      .then((res) => {
        that.loading = false

        that.goods = res.data
        that.sku = res.data.sku
        that.info = res.data.goods
        that.attr = res.data.attr
        that.tags = res.data.tags

        // 获取推荐商品
        let store_id = res.data.goods.store_id
        that.$api.goods.getRecommendedList({ store_id: store_id }).then((dat) => {
          that.recommended = dat.data.list3
          that.recommendedStore = dat.data.list6
          that.recommended15 = dat.data.list15
        })

        //获取销量商品
        that.$api.goods.getTopSellingList({ store_id: store_id }).then((dat) => {
          that.topSellingList = dat.data
        })

        // 获取店铺信息
        that.$api.storeInfo.getStoreInfo({ id: store_id }).then((dat) => {
          that.store = dat.data.store
          if (dat.data.content) {
            that.storeContent = dat.data.content
          }
          //顶部店铺
          // that.$refs.SearchBox.setStore(dat.data.store)
        })

        //初始化评论
        that.$refs.evaluation.init()
      })
      .catch(() => {
        that.loading = false
        setTimeout(() => {
          that.$router.go(-1)
        }, 1500)
      })
  },
  methods: {
    onMessage(code, goods_id) {
      if (!this.$common.isLogin()) {
        this.$message.warning('Please log in before contacting the merchant')
        return
      }

      let info = this.$common.getInfo()

      if (info.type == 2) {
        this.$message.warning('Unable to contact fellow merchants')
        return
      }

      this.$router.push({ name: 'message', query: { code: code, goods_id: goods_id } })
    },
    onTabbar(i) {
      this.tabbar = i
    },
    onSubNum() {
      this.buyNum--
    },
    onAddNum() {
      this.buyNum++
    },
    onNumber() {
      let num = this.getAttrVal('total')
      if (this.buyNum > num) {
        this.buyNum = num
      }
    },
    /**
     * 获取显示价格
     */
    getAttrVal(key, type = 1) {
      if (this.attrSku[key]) {
        return this.attrSku[key]
      } else if (this.info.min && type == 1) {
        return this.info[key]
      } else if (this.info.min && type == 2) {
        return this.info.min[key] + '-' + this.info.max[key]
      }
      return ''
    },
    hasAttr(id) {
      if (this.haveattr.length > 0) {
        return this.haveattr.includes(id)
      }
      return true
    },
    /**
     * 选择规格
     */
    onAttr(vo, i) {
      if (this.haveattr.length > 0 && !this.haveattr.includes(vo.id) && this.attr.length > 1) {
        // return;
      }
      if (this.selected[i] && this.selected[i].id === vo.id) {
        delete this.selected[i]

        //带图的规格
        if (vo.type == 2) {
          this.attrSkuImg = {}
        }

      } else {
        this.selected[i] = vo

        //带图的规格
        if (vo.type == 2) {
          if(vo.img){
            this.attrSkuImg = vo
          }
        }
      }

      //计算有的规格
      this.haveattr = this.getAttr()

      //触发规格属性
      this.attrSku = this.getSku()

      console.log('selected', this.selected)
      console.log('haveattr', this.haveattr)
      console.log('attrSku', this.attrSku)
    },
    /**
     * 判断交集规格
     */
    getAttr() {
      console.log(this.selected, 'selected')
      let temp = []
      const arr = this.tags
      for (let i in this.selected) {
        const id = this.selected[i].id
        if (temp.length == 0) {
          temp = [].concat(arr[id])
        } else {
          temp = temp.filter((item) => arr[id].indexOf(item) > -1)
        }
      }
      return temp
    },
    getSku() {
      let temp = []
      if (this.hasCheck()) {
        let key = 'k'
        for (let i in this.selected) {
          if (key != 'k') {
            key += '_'
          }
          key += this.selected[i].id
        }
        console.log(key, this.sku, 'sku')
        temp = this.sku[key]

        this.buyNum = 1
      }
      return temp
    },
    /**
     * 是否全部选中
     */
    hasCheck() {
      let isCheck = true
      for (let n = 0; n < this.attr.length; n++) {
        if (this.selected[n] === undefined) {
          isCheck = false
        }
      }
      //console.log("是否选择", isCheck);
      return isCheck
    },
    getInfo(key) {
      if (!this.info.goods) {
        return ''
      }
      return this.info.goods[key]
    },
    getStarWidth() {
      if (!this.info.star) {
        return 0
      }
      let star = this.info.star || 0
      let num = 90 * (star / 5)
      return num
    },
    onThumb(i) {
      this.attrSkuImg = {}
      this.currentThumb = i
    },
    onViewStart(e) {
      // console.log(e.offsetX,e.offsetY)
      let offsetX = e.offsetX - 450 / 2
      let offsetY = e.offsetY - 450 / 2
      console.log(offsetX, offsetY)
      this.$refs.view.style.width = 'auto'
      this.$refs.view.style.transform = `translate(${offsetX * -1 * 0.8}px, ${offsetY * -1 * 0.8}px) scale(1.6)`
    },
    onViewStop() {
      this.$refs.view.style.width = '100%'
      this.$refs.view.style.transform = `translate(0px, 0px) scale(1)`
    },
    /**
     * 购买
     */
    onBuyNow() {
      // if (!this.attrSku.id) {
      //   this.$message.warning('Please select the specific specifications of the product')
      //   return
      // }

      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      if (Reflect.ownKeys(this.attrSku).length == 0 || this.attrSku.length == 0) {
        this.$message.warning('Please select product specifications')
        return
      }

      this.$router.push({
        name: 'confirm',
        query: {
          type: 'goods',
          goods_id: this.info.id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        }
      })
    },
    /**
     * 增加到购物车
     */
    onAddToCartAll() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCartAll({
          goods_id: this.info.id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    },
    /**
     * 增加到购物车
     */
    onAddToCart() {
      //是否登录
      if (!this.$common.isLogin()) {
        this.$message.warning({
          content: 'Please log in first before purchasing'
        })
        return
      }

      let user = this.$common.getInfo()
      if (user.type == 2) {
        this.$message.warning({
          content: 'Merchant cannot purchase'
        })
        return
      }

      let that = this
      this.$message.loading({ content: 'add cart...', key: 'msg' })
      this.$api.order
        .addShopCart({
          goods_id: this.info.id,
          sku_id: this.attrSku.id,
          num: this.buyNum
        })
        .then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          console.log(res)
        })
    },
    /**
     * 添加到喜欢列表
     */
    onWishlist() {
      let that = this
      this.$message.loading({ content: 'add wishlist...', key: 'msg' })
      this.$api.user
        .saveWishlist({
          goods_id: this.info.id
        })
        .then((res) => {
          //设置当前喜欢状态
          that.info.is_wish = res.data.code
          that.info.wish_num_str = res.data.num

          //提示成功
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
        })
    },
    /**
     * 添加到喜欢列表
     */
    onFollow() {
      let that = this
      this.$message.loading({ content: 'follow store...', key: 'msg' })
      this.$api.user
        .saveFollow({
          store_id: this.store.id
        })
        .then((res) => {
          //设置当前喜欢状态
          that.store.is_follow = res.data.code
          that.store.follow_str = res.data.num
          //提示成功
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
        })
    }
  }
}
</script>
<style>
._1nker {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

._2S8eq {
  margin: 0 8px 8px 0;
}

.lev-bg {
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
</style>
<style scoped src="../static/css/store-info.css"></style>
<style scoped src="@/static/css/extend.css"></style>
<style scoped src="@/static/css/productFixSns.css"></style>
<style scoped src="@/static/css/gallery-9g91h.css"></style>
