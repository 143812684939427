<template>
  <a-modal v-model:visible="RechargeVisible" :title="$t('_txcd._tjyhk')" :footer="null" :style="{ width: '800px' }">
    <div :style="{ padding: '0 20px' }">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
        <a-form-item label="id" name="id" :rules="[{ required: params.id > 0, message: $t('withdrawal.address.desc') }]" v-show="false">
          <a-input v-model:value="params.id" type="text" />
        </a-form-item>
        <!-- 姓名 -->
        <a-form-item :label="$t('_txcd._skr')" name="name" :rules="[{ required: false, message: $t('_txcd._skr._ms') }]">
          <a-input v-model:value="params.name" type="text" :placeholder="$t('_txcd._skr._ms')" />
        </a-form-item>

        <!-- 收款账号 -->
        <a-form-item :label="$t('_txcd._skzh')" name="account" :rules="[{ required: false, message: $t('_txcd._skzh._ms') }]">
          <a-input v-model:value="params.account" type="text" :placeholder="$t('_txcd._skzh._ms')" />
        </a-form-item>

        <!-- 银行类型 -->
        <a-form-item :label="$t('_txcd._yhlx')" name="bank_type" :rules="[{ required: false, message: $t('_txcd._yhlx._ms') }]">
          <a-input v-model:value="params.bank_type" type="text" :placeholder="$t('_txcd._yhlx._ms')" />
        </a-form-item>

        <!-- 银行路由 -->
        <a-form-item :label="$t('_txcd._yhly')" name="routing" :rules="[{ required: false, message: $t('_txcd._yhly._ms') }]">
          <a-input v-model:value="params.routing" type="text" :placeholder="$t('_txcd._yhly._ms')" />
        </a-form-item>

        <!-- 银行代码 -->
        <a-form-item :label="$t('_txcd._yhdm')" name="code" :rules="[{ required: true, message: $t('_txcd._yhdm._ms') }]">
          <a-input v-model:value="params.code" type="text" :placeholder="$t('_txcd._yhdm._ms')" />
        </a-form-item>

        <!-- 银行名称 -->
        <a-form-item :label="$t('_txcd._yhmc')" name="bank_name" :rules="[{ required: true, message: $t('_txcd._yhmc._ms') }]">
          <a-input v-model:value="params.bank_name" type="text" :placeholder="$t('_txcd._yhmc._ms')" />
        </a-form-item>

        <!-- 银行地址 -->
        <a-form-item :label="$t('_txcd._yhdz')" name="bank_address" :rules="[{ required: true, message: $t('_txcd._yhdz._ms') }]">
          <a-input v-model:value="params.bank_address" type="text" :placeholder="$t('_txcd._yhdz._ms')" />
        </a-form-item>
        <!-- 备注 -->
        <!-- <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('wallet.desc.text') }}</div>
          </div>
        </a-form-item> -->
        <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
          <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('withdrawal.submit') }}</a-button>
          <a-button style="margin-left: 10px" @click="hide()">{{ $t('recharge.cancel.text') }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RechargeVue',
  data() {
    return {
      //存币
      RechargeVisible: false,
      selectIndex: 0,
      currencyList: [],
      protocolList: [],
      current: {},

      //加载按钮
      btnLoad: false,

      //参数
      params: {
        id: 0,
        name: '',
        account:'',
        address:'',
        bank_type:'',
        routing:'',
        code:'',
        bank_name:'',
        bank_address:'',
      },

      //编辑记录
      record: {}
    }
  },
  created() {},
  methods: {

    /**
     * 存币
     */
    show(record = {}) {
      this.RechargeVisible = true
      // 初始化
      this.params = {
        address: ''
      }

      if (record.id) {
        this.record = record
        this.params = record
      }
    },
    hide() {
      this.RechargeVisible = false
    },

    /**
     * 提交提现
     * @param {*} values
     */
    onFinish(values) {
      //参数
      console.log('params:', values)

      this.btnLoad = true
      this.$message.loading({ content: 'bind address...', key: 'msg' })

      let that = this
      this.$api.store
        .saveStoreBankAddress(values)
        .then((res) => {
          that.btnLoad = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          //关闭弹窗，重新刷新
          that.hide()
          //that.refresh()
          that.$emit('BindAddressSuccess')
        })
        .catch(() => {
          that.btnLoad = false
        })
      //})
    },
    onFinishFailed(e) {
      console.log(e, this.params)
    }
  }
}
</script>

<style>
.wallet-title {
  margin-right: 5px;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.sendcode {
  border: 1px solid #ffe5dd;
  border-radius: 5px;
  padding: 0px 10px;
  background: #ffe5dd;
  color: #ff5722;
}
</style>
