import request from '@/utils/request'

const api = {
  getHalloween: 'activity/getHalloween',
  getChristmas: 'activity/getChristmas'
}

export default {
  getHalloween(parameter) {
    return request({
      url: api.getHalloween,
      method: 'post',
      data: parameter
    })
  },
  getChristmas(parameter) {
    return request({
      url: api.getChristmas,
      method: 'post',
      data: parameter
    })
  }
}
