<template>
  <div>
    <div class="comet-modal-mask comet-fade-enter-done comet-fade-enter comet-fade-enter-active" v-if="isShow"></div>
    <div tabindex="-1" class="comet-modal-wrap" v-if="isShow">
      <div class="comet-modal payment-main-container pop-modal-container comet-zoom-appear-done comet-zoom-enter-done comet-zoom-enter comet-zoom-enter-active" style="width: 960px;">
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
        <button @click="onClose()" type="button" aria-label="Close" class="comet-modal-close">
          <span class="comet-icon comet-icon-close ">
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
              <path d="M181.088 135.936l1.536 1.44L512 466.741333l329.376-329.365333a32 32 0 0 1 46.688 43.712l-1.44 1.536L557.258667 512l329.365333 329.376a32 32 0 0 1-43.712 46.688l-1.536-1.44L512 557.258667 182.624 886.624a32 32 0 0 1-46.688-43.712l1.44-1.536L466.741333 512 137.376 182.624a32 32 0 0 1 43.712-46.688z"></path>
            </svg>
          </span>
        </button>
        <div class="comet-modal-content comet-modal-no-footer">
          <div class="comet-modal-header pop-modal--payment-method-header--3TFfAHd">
            <div class="comet-modal-title">{{$t('payment.method')}}</div>
          </div>
          <div class="comet-modal-body pop-modal--payment-container-body--3TzH3_f payment-container-body">
            <!-- 钱包列表 -->
            <div @click="onChecked(i)" v-for="(item,i) in walletList" :key="i">
              <div class="card--payment-card--3UuUfXQ card--bordered--r27nH79" style="cursor: pointer;">
                <div class="radio-item--item-container--16_7JfO">
                  <div class="radio-item--item-row--1pqvAFa">
                    <!-- 未选中 -->
                    <label v-if="checked !== i" class="comet-radio">
                      <span class="comet-radio-icon">
                        <span class="comet-radio-circle"></span>
                      </span>
                    </label>
                    <!-- 已选中 -->
                    <label v-if="checked == i" class="comet-radio comet-radio-checked radio-item--item-radio--1PIiS00">
                      <span class="comet-icon comet-icon-stepon ">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 64c-200.298667 0-362.666667 162.368-362.666667 362.666667s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667-162.368-362.666667-362.666667-362.666667z m0 149.333334c117.824 0 213.333333 95.509333 213.333333 213.333333s-95.509333 213.333333-213.333333 213.333333-213.333333-95.509333-213.333333-213.333333 95.509333-213.333333 213.333333-213.333333z"></path>
                        </svg>
                      </span>
                    </label>
                    <!-- <img :src="icon[item.name]" class="credit-card--card-brand-icon--2bUnkAV"> -->
                    <!-- <span style="margin-left: 5px;">{{item.name}}</span> -->
                    <span style="margin-right: 5px">
                      <dollar-outlined style="color:#4caf50" />
                    </span>
                    <span>
                      ${{item.balance}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="icon-text--icon-text-container--2NR9BJK icon-text--center--1bXnIj9 icon-text--pc--9eLS9Z2" style="margin-bottom: 70px;"></div>
            <div id="payment-botton-section" class="pay-button--payment-button-container--1qd_Wn4 pay-button--pc--2JKqIuL">
              <div class="pay-button--payment-botton-children--1_U-ftm">
                <div class="icon-text--icon-text-container--2NR9BJK icon-text--center--1bXnIj9 icon-text--pc--9eLS9Z2">
                  <!-- <img src="https://gw.alicdn.com/imgextra/i3/O1CN01H1wAZx1mDrgeaQ1O8_!!6000000004921-2-tps-56-64.png" class="icon-text--icon-text-icon--k7aiy-c " alt="remind"> -->
                  <!-- <span class="icon-text--icon-text-text--3cC5EUQ" style="font-size: 11px; color: rgb(102, 102, 102);">Your payment information is safe with us</span> -->
                </div>
              </div>
              <button @click="onConfirm()" type="button" class="comet-btn comet-btn-primary comet-btn-large pay-button--payment-confirm-btn--m5wFaLf pay-button--pc--2JKqIuL">
                <span>{{$t('address.confirm')}}</span>
              </button>
            </div>
          </div>
        </div>
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { DollarOutlined } from "@ant-design/icons-vue"
export default {
  name: "paymentVue",
  components: {
    DollarOutlined
  },
  props: {
    isInit: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      isShow: false,
      //钱包图标
      icon: {
        BTC: require("../../static/pay/btc.png"),
        ETH: require("../../static/pay/eth.png"),
        USDT: require("../../static/pay/usdt.png"),
        TRX: require("../../static/pay/trx.png"),
      },
      //钱包
      walletList: [],
      checked: 0,
    };
  },
  created () {
    this.getWallet();
  },
  methods: {
    getWallet () {
      let that = this;
      this.$api.user.getWallet().then((res) => {
        that.walletList = res.data;
        if (that.isInit) {
          this.setWallet();
        }
      });
    },
    /**
     * 选择已有的
     */
    onChecked (i) {
      this.checked = i;
    },
    onConfirm () {
      //当前选中
      this.setWallet();
      this.open();
    },
    setWallet () {
      let wallet = this.walletList[this.checked];
      wallet.icon = this.icon[wallet.name];
      this.$emit("setWallet", this.walletList[this.checked]);
    },
    open () {
      this.isShow = !this.isShow;
    },
    onClose () {
      this.open();
    },
  },
};
</script>
<style scoped src="../../static/css/address.css"></style>
<style scoped src="../../static/css/payment.css"></style>
<style scoped src="../../static/css/confirm-index.css"></style>