<template>
  <main id="next-plp-entrypoint" data-container-page style="background-color: #fff">
    <div id="platform_modernisation_plp" class="platform_modernisation_plp x-next-plat-mod">
      <div class="MuiGrid-root MuiGrid-container plp-13d406l">
        <div id="plp-search-banner-entrypoint">
          <div data-testid="plp-search-banner" id="plp-search-banner">
            <div data-testid="plp-search-banner-container" class="search-banner-container">
              <div data-testid="plp-search-banner-image-column" class="search-banner-image-column">
                <div data-testid="plp-search-banner-large-image" class="search-banner-large-image">
                  <img src="@/static/img2/halloween.jpg" alt="Halloween" height="100%" width="100%" />
                </div>
              </div>
              <div data-testid="plp-search-banner-text-column" class="search-banner-text-column" style="padding-top: 20px">
                <div data-testid="plp-search-banner-copy" id="search-banner-copy">
                  <div data-testid="plp-search-banner-copy-text" class="search-banner-copy-text">
                    <div>{{ $t('_wsj._desc1') }}</div>
                    <div data-testid="search-banner-read-more-content" id="search-banner-read-more-content" class="readmore">
                      {{ $t('_wsj._wsjms') }}
                    </div>
                  </div>
                  <!-- <div data-testid="plp-search-banner-copy-read-more" class="search-banner-copy-read-more">
                    <button data-testid="search-banner-read-more" id="search-banner-read-more" data-openclose="open" class="show">+ Read more</button>
                  </div> -->
                </div>
                <div data-testid="plp-search-banner-quick-links" class="search-banner-quick-links">
                  <a class="search-quick-link">{{ $t('_wsj._b1') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b2') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b3') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b4') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b5') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b6') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b7') }}</a>
                  <a class="search-quick-link">{{ $t('_wsj._b8') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 导航 吸顶 -->
        <header hidden id="plp-horizontal-filter-bar" class="plp-kysf98">
          <nav data-testid="plp-filter-cotton22-template" class="plp-0">
            <div data-testid="horizontal-filter-first-row" class="plp-nq15gz">
              <div class="plp-1cw99s5">
                <div data-testid="horizontal-filter-facet-New In" class="plp-14yi1ck">
                  <label class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd plp-bd9ihn" tabindex="0" data-testid="plp-facet-checkbox-feat-feat:newin"
                    ><span class="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-root MuiCheckbox-colorPrimary plp-laih9l"><input type="checkbox" class="PrivateSwitchBase-input plp-1m9pwf3" tabindex="-1" data-indeterminate="false" /><span class="plp-1ca2wdi"></span></span
                    ><span class="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label plp-jzj8yy"
                      ><span class="plp-1yr05cr"
                        ><span class="plp-64r9k9">New In</span><span data-testid="plp-facet-count-label-feat-feat:newin" class="plp-1l6sjej">(<!-- -->143<!-- -->)</span></span
                      ></span
                    ></label
                  >
                </div>
              </div>

              <div v-for="(vo, i) in menuList" :key="i" class="plp-1wxz3dc" @mouseover="menu[i] = true" @mouseleave="menu[i] = false">
                <div class="plp-1l0rfzm" style="position: relative">
                  <div class="plp-131w2xa">
                    <button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium plp-155p2il" tabindex="0" type="button" data-testid="plp-filter-label-button-gender">
                      <span class="plp-1gxs3he">{{ vo.title }}</span>
                    </button>
                    <img src="@/static/img2/chevron.svg" alt="chevron" data-testid="plp-filter-chevron-gender" class="plp-1w7nqog" />
                  </div>

                  <!-- 弹出框 -->
                  <div :class="['plp-11jibpp MuiPopperUnstyled-root plp-m1', menu[i] ? 'active' : '']" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; top: 54px" data-popper-placement="bottom-start">
                    <div>
                      <div class="plp-p7x3p6">
                        <span class="plp-1rwsnke">{{ vo.desc }}</span>
                      </div>
                      <div class="plp-19f8tnj">
                        <div v-for="(vvo, ii) in vo.list" :key="ii" class="plp-14yi1ck">
                          <label class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd plp-bd9ihn" tabindex="0">
                            <span class="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-root MuiCheckbox-colorPrimary plp-laih9l"><input class="PrivateSwitchBase-input plp-1m9pwf3" tabindex="-1" type="checkbox" data-indeterminate="false" /><span class="plp-1ca2wdi"></span></span>
                            <span class="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label plp-jzj8yy">
                              <span class="plp-1yr05cr">
                                <span class="plp-64r9k9">{{ vvo.title }}</span>
                                <span data-testid="plp-facet-count-label-gender-gender:women" class="plp-1l6sjej">({{ vvo.num }})</span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div data-testid="plp-horizontal-filter-more-less-button" id="plp-horizontal-filter-more-less-button" class="plp-15p26aa">
                <button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium plp-1cf664u" tabindex="0" type="button">More</button><span data-testid="plp-horizontal-filter-more-less-icon" class="plp-1gk39u0"> <img src="@/static/img2/chevron.svg" alt="More Icon" /></span>
              </div> -->
            </div>
          </nav>
        </header>

        <div class="plp-1xn0trq"></div>

        <!-- 商品列表 -->
        <div class="MuiGrid-root MuiGrid-container plp-product-grid-wrapper plp-1crrp7n" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="getHalloween" infinite-scroll-distance="5">
          <div class="MuiGrid-root MuiGrid-container plp-1t08jfd">
            <!--  -->
            <div v-for="(vo, i) in goodsList" :key="i" class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-md-4 MuiGrid-grid-lg-3 MuiGrid-grid-xl-3 plp-uy3tsm" data-index="0" id="plp-product-summary-tile-D73972">
              <div id="plp-product-summary-entrypoint-D73972" data-pid="D73972">
                <div id="next-product-summary-entrypoint-D73972" data-hydrate-completed="true">
                  <section class="x-next-plat-mod platform_modernisation_product_summary" id="platform_modernisation_product_summary_D73972">
                    <div class="MuiPaper-root MuiPaper-elevation MuiPaper-elevation0 MuiCard-root produc-1axi8nq" data-testid="product_summary_tile_d73972">
                      <div class="produc-t0lbh8">
                        <div class="produc-8adz9g">
                          <div class="swiper-container swiper-container-virtual swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events product-swiper">
                            <div class="swiper-wrapper" style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px)">
                              <div class="swiper-slide swiper-slide-prev" data-swiper-slide-index="0" style="left: 0px; width: 100%; margin-right: 2px">
                                <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="MuiCardMedia-root produc-1mup83m">
                                  <img :src="vo.img" class="produc-gzdrrz" />
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="product-summary-favourites-container produc-15kajhm">
                          <div class="product-summary-favourites-container produc-1l011g9">
                            <button data-testid="product-summary-favourites-button" class="produc-qfav0x">
                              <svg id="product-summary-favourites-icon-D73972" class="produc-3u8b5o">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#inactive" viewBox="0 0 26 24"></use>
                              </svg>
                            </button>
                          </div>
                        </div> -->
                      </div>
                      <div class="MuiCardContent-root produc-1ivfcou">
                        <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover produc-fpg4o7" style="text-decoration: none">
                          <h2 class="produc-ivbv8a">
                            <p class="MuiTypography-root MuiTypography-body1 produc-hj8ksk">{{ vo.name }}</p>
                          </h2>
                          <div>
                            <div class="produc-1kp3g5s">
                              <div class="MuiTypography-root MuiTypography-body1 produc-nj4oqg">
                                <div style="display: flex; gap: 10px">
                                  <span>$ {{ vo.price }}</span>
                                  <span style="color: #666; text-decoration: line-through">$ {{ vo.cost_price }}</span>
                                </div>
                                <span class="produc-amq216"></span>
                              </div>
                            </div>
                            <div class="produc-x29n4d">
                              <p class="MuiTypography-root MuiTypography-body1 produc-nj4oqg">
                                <span>$ {{ vo.cost_price }}</span>
                                <span class="produc-amq216"></span>
                              </p>
                            </div>
                          </div>
                        </a>
                        <!-- <div class="produc-10u05r7">
                          <div class="MuiCollapse-root MuiCollapse-vertical produc-1xwzhlq" style="min-height: 24px">
                            <div class="MuiCollapse-wrapper MuiCollapse-vertical produc-hboir5">
                              <div class="MuiCollapse-wrapperInner MuiCollapse-vertical produc-8atqhb">
                                <ul data-testid="product_summary_colourchips" class="produc-v88ev0">
                                  <li class="produc-d1vtxe" aria-current="false">
                                    <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover produc-1a84hbc" title="Orange Pumpkin" href="https://www.next.co.uk/g624507s2/d73972#d73972"><img src="https://xcdn.next.co.uk/Common/Items/Default/Default/ItemImages/AltItemSwatch/21x21/D73972.jpg" class="lazyloaded" data-src="https://xcdn.next.co.uk/Common/Items/Default/Default/ItemImages/AltItemSwatch/21x21/D73972.jpg" width="20" height="20" data-testid="product_summary_colourchip_d73972" alt="Orange Pumpkin Halloween Pyjamas (9mths-12yrs)" /></a>
                                  </li>
                                  <li class="selected produc-d1vtxe" aria-current="true">
                                    <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover produc-1a84hbc" title="Grey Glow In The Dark Ghost" href="https://www.next.co.uk/g624507s3/d73973#d73973"><img src="https://xcdn.next.co.uk/Common/Items/Default/Default/ItemImages/AltItemSwatch/21x21/D73973.jpg" class="lazyloaded" data-src="https://xcdn.next.co.uk/Common/Items/Default/Default/ItemImages/AltItemSwatch/21x21/D73973.jpg" width="20" height="20" data-testid="product_summary_colourchip_d73973" alt="Grey Glow In The Dark Ghost Halloween Pyjamas (9mths-12yrs)" /></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 加载更多 -->
        <div v-show="loadmore" class="loadmore" style="padding-bottom: 20px">
          <img class="_2O2Fm" src="@/static/img/loading.gif" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'HalloweenVue',
  data() {
    return {
      menuList: [
        {
          title: 'Gender',
          desc: 'Select Gender',
          list: [
            {
              id: 580,
              title: 'Women',
              num: 50
            },
            {
              id: 581,
              title: "Men's",
              num: 36
            },
            {
              id: 583,
              title: 'Kids',
              num: 36
            }
          ]
        },
        {
          title: 'Category',
          desc: 'Select Category',
          list: [
            {
              id: 1,
              title: 'Dresses',
              num: 19
            },
            {
              id: 2,
              title: 'T-Shirts',
              num: 19
            },
            {
              id: 3,
              title: 'Bed Sets',
              num: 9
            }
          ]
        },
        {
          title: 'Size',
          desc: 'Select Size',
          list: [
            {
              id: 1,
              title: '4',
              num: 19
            },
            {
              id: 2,
              title: '5',
              num: 19
            },
            {
              id: 3,
              title: '6',
              num: 9
            }
          ]
        },
        {
          title: 'Brand',
          desc: 'Select Brand',
          list: [
            {
              id: 1,
              title: '4',
              num: 19
            },
            {
              id: 2,
              title: '5',
              num: 19
            },
            {
              id: 3,
              title: '6',
              num: 9
            }
          ]
        }
      ],
      menu: [false, false, false, false, false],

      //加载更多
      scrollDisabled: false,
      loadmore: false,
      goodsList: [],
      page: 1
    }
  },
  created() {
    this.page = 1
    this.getHalloween()
  },
  methods: {
    getHalloween() {
      if (this.loadmore) {
        return
      }
      this.loadmore = true

      let that = this
      this.$api.activity.getHalloween({ page: this.page }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsList.push(item)
        })
        that.loadmore = false
      })
    }
  }
}
</script>
<style scoped>
@import url('@/static/css2/halloween.css');
</style>
<style scoped>
.plp-m1 {
  opacity: 0;
  visibility: hidden;
}

.plp-m1.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
</style>
