<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.setting') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 1 -->
  <a-card style="margin-bottom: 20px">
    <div>{{ $t('auth.smrz') }}</div>
    <a-form :model="auth" name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
      <a-form-item :label="$t('auth.rzlx')" :required="true">
        <a-radio-group v-model:value="type" :options="plainOptions" :disabled="!isAllow" />
      </a-form-item>

      <template v-if="type == 1">
        <a-form-item :label="$t('auth.sfzm')" :required="type == 1">
          <upload-file @onUpload="onFrontUpload" :imageUrl="imageFrontUrl" :disabled="!isAllow"></upload-file>
        </a-form-item>

        <a-form-item :label="$t('auth.sffm')" :required="type == 1">
          <upload-file @onUpload="onBackUpload" :imageUrl="imageBackUrl" :disabled="!isAllow"></upload-file>
        </a-form-item>

        <a-form-item :label="$t('auth.zsxm')" name="name" :rules="[{ required: type == 1, message: 'Please input your name!' }]">
          <a-input v-model:value="auth.name" :disabled="!isAllow" />
        </a-form-item>

        <a-form-item :label="$t('auth.zjhm')" name="card" :rules="[{ required: true, message: 'Please input your code!' }]">
          <a-input v-model:value="auth.card" :disabled="!isAllow" />
        </a-form-item>
      </template>

      <template v-if="type == 2">
        <a-form-item :label="$t('auth.yyzz')" :required="type == 2">
          <upload-file @onUpload="onCompanyUpload" :imageUrl="imageCompanyUrl" :disabled="!isAllow"></upload-file>
        </a-form-item>

        <a-form-item :label="$t('auth.gsmc')" name="company" :rules="[{ required: type == 2, message: 'Please input your company!' }]">
          <a-input v-model:value="auth.company" :disabled="!isAllow" />
        </a-form-item>

        <a-form-item :label="$t('auth.zjhm')" name="card" :rules="[{ required: true, message: 'Please input your code!' }]">
          <a-input v-model:value="auth.card" :disabled="!isAllow" />
        </a-form-item>
      </template>

      <a-form-item :label="$t('auth.zt')" v-if="auth.status > 0">
        <div v-if="auth.status == 1">
          <a-tag color="orange">{{ $t('auth.shz') }}</a-tag>
        </div>
        <div v-if="auth.status == 2">
          <a-tag color="green">{{ $t('auth.ytg') }}</a-tag>
        </div>
        <div v-if="auth.status == 3">
          <a-tag color="red">{{ $t('auth.ybh') }}</a-tag>
        </div>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 4, span: 8 }" v-if="isAllow">
        <a-button type="primary" html-type="submit" :loading="btnLoad">{{ $t('auth.ljtj') }}</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import UploadFile from '@/components/UploadFile.vue'
export default {
  name: 'settingVue',
  components: {
    UploadFile
  },
  data() {
    return {
      //status
      isAllow: true,

      //店铺信息
      auth: {
        name: '',
        card: '',
        company: ''
      },

      imageFrontUrl: '',
      imgFront: '',

      imageBackUrl: '',
      imgBack: '',

      imageCompanyUrl: '',
      imgCompany: '',

      type: 1,
      plainOptions: [
        { label: this.$t('auth.gr'), value: 1 },
        { label: this.$t('auth.gs'), value: 2 }
      ]
    }
  },
  created() {
    //获取信息
    this.getStoreAuth()
  },
  methods: {
    getStoreAuth() {
      const that = this
      this.$api.store.getAuth().then((res) => {
        that.type = res.data.type

        that.auth = res.data
        if (that.auth.status == 1 || that.auth.status == 2) {
          //不允许访问
          that.isAllow = false
        } else {
          //允许访问
          that.isAllow = true
        }

        that.imageFrontUrl = res.data.front
        that.imageBackUrl = res.data.back
        that.imageCompanyUrl = res.data.license
      })
    },
    onFrontUpload(img) {
      this.imageFrontUrl = img
      this.imgFront = img
    },
    onBackUpload(img) {
      this.imageBackUrl = img
      this.imgBack = img
    },
    onCompanyUpload(img) {
      this.imageCompanyUrl = img
      this.imgCompany = img
    },
    /**------------------------------
     * 保存个人信息
     * @param {*} values
     */
    onFinish(values) {
      this.btnLoad = true

      values.type = this.type

      if (this.type == 1) {
        values.front = this.imgFront
        values.back = this.imgBack
      } else if (this.type == 2) {
        values.license = this.imgCompany
      }

      console.log('params:', values)

      let that = this
      this.$api.store.saveAuth(values).then((res) => {
        console.log(res)
        that.btnLoad = false
        if (res.code == 1) {
          that.$message.warning(res.msg)
        } else {
          that.$message.success(res.msg)
          that.getStoreAuth()
        }
      })
    },
    onFinishFailed(errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }
}
</script>
<style scoped src="../../../static/css/store-css.css"></style>

<style>
.a-del {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.vo-name {
  font-size: 12px;
  white-space: normal;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.op-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.del-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
