<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('products.add.from.warehouse') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>

  <div :style="{ background: '#fff', padding: '20px 20px', minHeight: '800px' }">
    <div class="nav-content">
      <div>
        <a-button
          size="large"
          @click="
            () => {
              $router.go(-1)
            }
          "
        >
          <template #icon>
            <rollback-outlined />
          </template>
          {{ $t('products.back.product') }}
        </a-button>
      </div>
      <div>
        <a-space :size="20">
          <div>{{ $t('products.total') }}:{{ pagination.total }}</div>
          <div>
            <a-cascader size="large" :style="{ width: '400px' }" v-model:value="queryParam.category" :options="categorys" :placeholder="$t('products.search.category')" />
          </div>
          <a-input-search :style="{ width: '400px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px">
        <a-popconfirm :title="$t('products.ask.add')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onAddGoods()" :disabled="selectedRowKeys.length == 0">
          <a-button type="primary" :disabled="selectedRowKeys.length == 0">
            <template #icon>
              <plus-circle-outlined />
            </template>
            {{ $t('products.batch.add') }}
          </a-button>
        </a-popconfirm>
        <div class="ddws" v-if="search == 'halloween'">
          <div>{{ $t('_wsj._flss') }}：</div>
          <div class="ddwsc" @click="onWsj([1, 14, 580])">{{ $t('_wsj._wsnz') }}</div>
          <div class="ddwsc" @click="onWsj([2, 67, 581])">{{ $t('_wsj._wsn') }}</div>
          <div class="ddwsc" @click="onWsj([9, 352, 582])">{{ $t('_wsj._wst') }}</div>
          <div class="ddwsc" @click="onWsj([7, 272, 583])">{{ $t('_wsj._wszs') }}</div>
          <div class="ddwsc" @click="onWsj([7, 282, 584])">{{ $t('_wsj._wslp') }}</div>
        </div>
        <div class="ddws" v-if="search == 'christmas'">
          <div>{{ $t('_wsj._flss') }}：</div>
          <div class="ddwsc" @click="onWsj([1, 14, 589])">{{ $t('_sdj._sdnz') }}</div>
          <div class="ddwsc" @click="onWsj([2, 67, 590])">{{ $t('_sdj._sdn') }}</div>
          <div class="ddwsc" @click="onWsj([7, 272, 592])">{{ $t('_sdj._sdzs') }}</div>
          <div class="ddwsc" @click="onWsj([7, 282, 591])">{{ $t('_sdj._sdjj') }}</div>
          <div class="ddwsc" @click="onWsj([9, 352, 593])">{{ $t('_sdj._sdetlw') }}</div>
        </div>
      </div>
      <a-table
        :rowKey="
          (record) => {
            return record.id
          }
        "
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="getHouseProducts"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'img'">
            <img :src="record.img" width="50" style="margin-right: 5px" />
          </template>
          <template v-if="record && column.dataIndex === 'name'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px">{{ record.name }}</div>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'price'">
            <div>${{ record.price }}</div>
          </template>
          <template v-if="record && column.dataIndex === 'profit_value'">
            <div>
              <a-tag color="green"> ≈ ${{ record.profit_value }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <!-- <a>Detail</a>
              <a-divider type="vertical" /> -->
              <a-popconfirm :title="$t('products.ask.add')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onAdd(record)">
                <a href="javascript:">{{ $t('products.add') }}</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { PlusCircleOutlined, RollbackOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'productsVue',
  components: {
    PlusCircleOutlined,
    RollbackOutlined
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: false,
          key: 'id',
          width: '4%'
        },
        {
          title: this.$t('products.table.img'),
          dataIndex: 'img',
          width: '5%'
        },
        {
          title: this.$t('products.table.name'),
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: this.$t('products.table.category'),
          dataIndex: 'cate_name',
          width: '10%'
        },
        {
          title: this.$t('products.table.stock'),
          dataIndex: 'total',
          width: '10%'
        },
        {
          title: this.$t('products.table.price'),
          dataIndex: 'price',
          width: '10%',
          sorter: true
        },
        {
          title: this.$t('products.table.profit'),
          dataIndex: 'profit_value',
          width: '30%'
        },
        {
          title: this.$t('products.table.action'),
          key: 'action',
          width: '10%'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: '',
        category: []
      },

      //筛选
      categorys: [],

      //批量选中
      selectedRowKeys: [],
      selectedRows: [],

      //搜索关键词
      search: ''
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: this.onSelectChange
      }
    }
  },
  created() {
    this.search = this.$route.query.search
    if (this.search) {
      this.queryParam.search = this.search
    }
    this.getHouseProducts(this.pagination)

    // 获取分类
    const that = this
    this.$api.category.getCategorys().then((res) => {
      that.categorys = res.data.options
    })
  },
  methods: {
    onWsj(cateIds) {
      this.queryParam.search = ''
      this.queryParam.category = cateIds
      this.onSearch()
    },
    /**
     * 选中框
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRowKeys, selectedRows)
    },
    /**
     * 添加单个商品
     * @param {*} record
     */
    onAdd(record) {
      this.$message.loading({ content: 'submit...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.addNewHouseProduct({ goods_ids: [record.id] }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.getHouseProducts(that.pagination)
      })
    },
    /**
     * 批量添加
     */
    onAddGoods() {
      this.$message.loading({ content: 'submit...', key: 'msg', duration: 50 })
      let that = this
      this.$api.store.addNewHouseProduct({ goods_ids: this.selectedRowKeys }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.selectedRowKeys = []
        that.getHouseProducts(that.pagination)
      })
    },
    /**
     * 搜索
     */
    onSearch() {
      this.getHouseProducts(this.pagination)
    },
    /**
     * 获取仓库产品
     * @param {*} parameter
     */
    getHouseProducts(parameter, filters = {}, sorter = {}) {
      // console.log(parameter, filters, sorter)
      filters
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize,
        field: sorter.field,
        order: sorter.order
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getHouseProducts(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style scoped>
.ddws {
  display: flex;
  gap: 10px;
  margin: 0 15px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.ddwsc {
  cursor: pointer;
  border: 1px solid #ffb500;
  color: #f90;
  border-radius: 5px;
  padding: 4px 7px 2px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ddwsc:hover {
  background-color: #fff7e2;
}
</style>
