<template>
  <div class="s-main">
    <div class="search-key-box">
      <div class="d-loading" v-if="searching">
        <loading-outlined />
      </div>
      <input type="text" placeholder="I'm shopping for..." maxlength="50" autocomplete="off" class="search-key" v-model="searchVal" @input="onInput">
    </div>
    <div class="ui-autocomplete search-div" v-if="searchShop.length > 0">
      <ul class="ui-autocomplete-ctn" data-role="items">
        <li v-for="(item, i) in searchShop" :key="i" class="ui-autocomplete-item" @click="onShop(item)">
          <span class="suggest_key"><span class="ui-autocomplete-item-hl">{{ item.name }}</span> ({{ item.sid }})</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue"
export default {
  name: "SearchShop",
  components: {
    LoadingOutlined
  },
  data () {
    return {
      // 搜索值
      searchVal: '',
      searchShop: [],

      //搜索中
      searching: false,
    }
  },
  methods: {
    onInput () {

      //通知上级
      this.$emit('setSearchVal', this.searchVal)

      console.log(this.searchVal)
      // 搜索符合的店铺名称

      this.searching = true

      let that = this
      this.$api.home.searchShop({ search: this.searchVal }).then(res => {
        that.searching = false
        that.searchShop = res.data
      })
    },
    onShop (val) {
      this.$router.push({ name: 'storeHome', query: { id: val.id } })
    },
    onSearch () {
      this.$emit('onSearch', this.searchVal)
    }
  }
}
</script>
<style scoped src="../static/css/ae-header.css"></style>
<style scoped>
.s-main {
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-div {
  width: 100%;
  z-index: 99;
  position: absolute;
  left: 17px;
  top: 34px;
}

.ui-autocomplete-item:hover {
  background-color: #f2f2f2 !important;
}

.d-loading {
  position: absolute;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}
</style>
