<template>
  <div class="container">
    <div class="task-h1">
      <span>{{ $t('task.rwlb') }}</span>
    </div>
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link :to="{ name: 'index' }">{{ $t('task.sy') }}</router-link></a-breadcrumb-item
      >
      <a-breadcrumb-item>{{ $t('task.rwlbo') }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-tabs v-model:activeKey="activeKey" style="margin-top: 20px; padding: 5px">
      <a-tab-pane :key="1" :tab="$t('task.qbrw')">
        <div :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore" infinite-scroll-distance="5">
          <a-card style="margin-bottom: 10px; border-radius: 10px" v-for="(item, i) in list" :key="i" :class="'container-shadow ' + (item.status == 1 ? 'qdz' : item.status == 2 ? 'ywc' : 'djy')">
            <div class="task-name">
              <a-tag color="red" v-if="item.status == 1">{{ $t('task.qdz') }}</a-tag>
              <a-tag color="green" v-if="item.status == 2">{{ $t('task.ywc') }}</a-tag>
              <a-tag color="blue" v-if="item.status == 3">{{ $t('task.djy') }}</a-tag>
              <div class="t_name">{{ item.name }}</div>
            </div>
            <div v-html="item.content" class="task-content"></div>
            <div class="tg-bk01-box" style="margin: 20px 0">
              <router-link :to="{ name: 'detail', query: { id: goods.id } }" v-for="(goods, i) in item.columns" :key="i">
                <div class="tg-item">
                  <div class="tg-img">
                    <img :src="goods.img" alt="" />
                  </div>
                  <div class="tg-info">
                    <div class="tg-name">{{ goods.name }}</div>
                  </div>
                </div>
              </router-link>
            </div>
            <div :class="'task-desc-btn ' + (item.status == 1 ? 'qdzs' : item.status == 2 ? 'ywcs' : 'djys')">
              <div class="task-desc">
                <div>
                  <span>{{ $t('task.ddje') }}：</span>
                  <span>${{ item.total_min }} ~ ${{ item.total_max }}</span>
                </div>
                <div>
                  <span>{{ $t('task.cyrs') }}：</span>
                  <span>{{ item.join_num }}</span>
                </div>
                <div>
                  <span>{{ $t('task.ewsy') }}：</span>
                  <span>{{ item.income_rate }} %</span>
                </div>
                <div>
                  <span>{{ $t('task.jzsj') }}：</span>
                  <span>{{ item.cuttoff_time }}</span>
                </div>
              </div>
              <div class="task-btn">
                <router-link v-if="item.status === 1 && !item.join" :to="{ name: 'task_content', query: { id: item.id } }" class="_SOk">{{ $t('task.ljcy') }}</router-link>
                <router-link v-else :to="{ name: 'task_content', query: { id: item.id } }" class="_SOk Sk1_X">{{ $t('task.ckxq') }}</router-link>
              </div>
            </div>
          </a-card>
          <!-- 加载更多 -->
          <div v-show="loadmore" class="load-bt">
            <img class="_2O2Fm" src="@/static/img/loading.gif" />
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="2" :tab="$t('task.wcyd')">
        <div :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled2" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore2" infinite-scroll-distance="5">
          <a-card style="margin-bottom: 10px; border-radius: 10px" v-for="(item, i) in list2" :key="i">
            <div class="task-name">
              <a-tag color="red" v-if="item.status == 1">{{ $t('task.qdz') }}</a-tag>
              <a-tag color="green" v-if="item.status == 2">{{ $t('task.ywc') }}</a-tag>
              <a-tag color="blue" v-if="item.status == 3">{{ $t('task.djy') }}</a-tag>
              <div class="t_name">{{ item.name }}</div>
            </div>
            <div v-html="item.content" class="task-content"></div>
            <div class="tg-bk01-box" style="margin: 20px 0">
              <router-link :to="{ name: 'detail', query: { id: goods.id } }" v-for="(goods, i) in item.columns" :key="i">
                <div class="tg-item">
                  <div class="tg-img">
                    <img :src="goods.img" alt="" />
                  </div>
                  <div class="tg-info">
                    <div class="tg-name">{{ goods.name }}</div>
                  </div>
                </div>
              </router-link>
            </div>
            <div :class="'task-desc-btn ' + (item.status == 1 ? 'qdzs' : item.status == 2 ? 'ywcs' : 'djys')">
              <div class="task-desc">
                <div>
                  <span>{{ $t('task.ddje') }}：</span>
                  <span>${{ item.total_min }} ~ ${{ item.total_max }}</span>
                </div>
                <div>
                  <span>{{ $t('task.cyrs') }}：</span>
                  <span>{{ item.join_num }}</span>
                </div>
                <div>
                  <span>{{ $t('task.ewsy') }}：</span>
                  <span>{{ item.income_rate }} %</span>
                </div>
                <div>
                  <span>{{ $t('task.jzsj') }}：</span>
                  <span>{{ item.cuttoff_time }}</span>
                </div>
              </div>
              <div class="task-btn">
                <router-link v-if="item.status === 1 && !item.join" :to="{ name: 'task_content', query: { id: item.id } }" class="_SOk">{{ $t('task.ljcy') }}</router-link>
                <router-link v-else :to="{ name: 'task_content', query: { id: item.id } }" class="_SOk Sk1_X">{{ $t('task.ckxq') }}</router-link>
              </div>
            </div>
          </a-card>
          <!-- 加载更多 -->
          <div v-show="loadmore" class="load-bt">
            <img class="_2O2Fm" src="@/static/img/loading.gif" />
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: 'docsVue',
  data() {
    return {
      list: [],
      page: 1,
      list2: [],
      page2: 1,

      //加载更多
      scrollDisabled: false,
      loadmore: false,

      //加载更多
      scrollDisabled2: false,
      loadmore2: false,

      //tab
      activeKey: 1
    }
  },
  created() {
    this.key = this.$route.query.key
    this.loadMore()
  },
  methods: {
    languageNotify(e) {
      console.log(e, 'docs lang')
      this.getDocs()
    },
    // 触底加载
    loadMore() {
      if (this.loadmore) {
        return
      }
      this.loadmore = true
      this.getList()
    },
    getList() {
      //获取文章
      let that = this
      this.$api.orderTask.getStoreTaskList({ page: this.page, size: 10 }).then((res) => {
        if (res.data.data.length <= 0) {
          that.scrollDisabled = true
          that.loadmore = false
          return
        }
        that.page++
        res.data.data.forEach(function (item) {
          that.list.push(item)
        })
        that.loadmore = false
      })
    },
    // 触底加载
    loadMore2() {
      if (this.loadmore2) {
        return
      }
      this.loadmore2 = true
      this.getMyList()
    },
    getMyList() {
      //获取文章
      let that = this
      this.$api.orderTask.getMyJoinTaskList({ page: this.page2, size: 10 }).then((res) => {
        if (res.data.data.length <= 0) {
          that.scrollDisabled2 = true
          that.loadmore2 = false
          return
        }
        that.page2++
        res.data.data.forEach(function (item) {
          that.list2.push(item)
        })
        that.loadmore2 = false
      })
    }
  }
}
</script>

<style scoped>
.docs-title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.load-bt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

._SOk {
  cursor: pointer;
  background: #ffb500;
  border-radius: 15px;
  padding: 6px 28px;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0;
}

.Sk1_X {
  margin-left: 8px;
  margin-right: 8px;
}
.Sk1_X {
  background: #f3f4f4;
  color: #666;
}

.task-h1 {
  font-size: 19px;
  font-weight: 700;
  margin-top: 15px;
}

.task-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  font-weight: 700;
}

.task-content {
  color: #4e4e4e;
  margin-top: 25px;
  margin-bottom: 25px;
}

.task-desc-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-desc {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}

.task-btn {
  /* margin-top: 30px; */
}
.task-desc span:nth-child(1) {
  font-weight: 700;
  font-size: 16px;
}

.task-desc span:nth-child(2) {
  font-weight: 700;
  font-size: 16px;
  color: #ffb500;
}
.container >>> .ant-tabs-nav {
  background: #fff;
  padding: 0 18px;
  border-radius: 5px;
}
.t_name {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<style scoped>
@import url('@/static/css/task.css');
</style>
