<template>
  <div class="cm_bootstrap" id="divStorefrontContentSection">
    <div class="bottom-sidebar-layout-container clearfix">
      <div class="top-section">
        <div class="tablet-view">
          <div id="cm-placement-topBanner" class="cm-placement-topBanner width100">
            <div class="CMS-HTMLSnippet"></div>
            <div class="CMS-HTMLSnippet"></div>
          </div>
        </div>
        <div class="tablet-view">
          <div id="cm-placement-placement1" class="cm-placement-placement1 width100">
            <div id="multi-4-teaser1" class="multi-4-teaser-module" style="position: absolute; width: 100%; z-index: 1">
              <div class="multi-teaser shop-category" style="">
                <div class="multi-teaser-outer-container teaser-stack">
                  <div class="multi-teaser-inner-container" style="display: flex">
                    <div id="multi-4-teaser1_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Shop all Christmas " data-block="shop-category" href="javascript:" class="hp-hover-image-wrapper" title="Shop all Christmas ">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Christmas teaser 1" title="Shop all Christmas " src="@/static/christmas/shop-all-christmas-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tt1') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-4-teaser1_item_1" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Christmas Decorations " data-block="shop-category" href="javascript:" class="hp-hover-image-wrapper" title="Christmas Decorations">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Christmas Decorations" title="Christmas Decorations" src="@/static/christmas/christmas-decorations-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tt2') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-4-teaser1_item_2" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Shop all Gifting " data-block="shop-category" href="javascript:" class="hp-hover-image-wrapper" title="Shop all Gifting">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Christmas teaser 3" title="Shop all Gifting" src="@/static/christmas/shop-all-gifting-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tt3') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-4-teaser1_item_3" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Shop all Christmas Nightwear " data-block="shop-category" href="javascript:" class="hp-hover-image-wrapper" title="Shop All Christmas Clothing-1">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Shop All Christmas Clothing-1" title="Shop All Christmas Clothing-1" src="@/static/christmas/shop-all-christmas-clothing-1-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tt4') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="multi-1-teaser2" class="multi-1-teaser-module" style="">
              <div class="multi-teaser shop-hero-one" style="">
                <div class="multi-teaser-outer-container teaser-swipe">
                  <div class="multi-teaser-inner-container">
                    <div id="multi-1-teaser2_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a href="javascript:" class="hp-hover-image-wrapper">
                          <div class="">
                            <div class="video-wrapper">
                              <video autoplay="" loop="" muted="" playsinline="" name="media" poster="@/static/christmas/christmas-animation-desktop-poster-image-data.jpg" class="" style="" id="html5_video_2mgjq5hitnn">
                                <source src="@/static/christmas/christmas-animation-video-lp-compressed.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="CMS-HTMLSnippet" style="margin-top: 30px">
              <div class="christmas-header-banner" style="z-index: 1">
                <!--<img src="/nxtcms/resource/blob/5585838/229689859625af4b748c74891cb35af8/chrismas-logo-data.png" alt="Christmas at Next">-->
                <svg viewBox="0 0 426 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.4648 0.770831C24.9251 0.723788 29.3043 1.9546 33.0769 4.31549V11.5028C29.6864 8.88237 25.5382 7.40924 21.2415 7.2997C12.5839 7.2997 6.86394 13.142 6.86394 20.3853C6.86394 27.6287 12.5839 33.457 21.2415 33.457C25.546 33.389 29.707 31.9113 33.0769 29.2539V36.4412C29.3261 38.7941 24.9731 40.0292 20.5354 39.9998C8.82708 39.9998 0 31.3554 0 20.3853C0 9.41521 8.75648 0.770831 20.4648 0.770831Z" fill="#E5C287"></path>
                  <path d="M52.3646 1.53809H59.0712V16.8885H79.3475V1.53809H86.1538V39.2164H79.3475V23.2939H59.0712V39.2304H52.3077L52.3646 1.53809Z" fill="#E5C287"></path>
                  <path d="M106.966 1.53809H121.453C130.911 1.53809 135.94 6.49213 135.94 13.0091C135.94 18.0747 132.945 22.1077 127.343 23.7265L140.769 39.2304H132.172L119.461 24.48H113.73V39.2304H106.923L106.966 1.53809ZM128.861 13.0091C128.861 9.72968 125.996 7.90154 121.167 7.90154H113.715V18.1305H121.066C125.996 18.1305 128.861 16.3443 128.861 13.0091Z" fill="#E5C287"></path>
                  <path d="M165.385 1.53809V39.2304H158.462V1.53809H165.385Z" fill="#E5C287"></path>
                  <path d="M185.116 35.3635V27.8181C186.874 29.6166 192.663 33.7897 198.623 33.7897C204.341 33.7897 206.728 31.2325 206.728 28.6331C206.728 26.4692 205.57 24.9517 200.882 23.7012L195.693 22.2961C187.531 20.076 184.615 16.6757 184.615 11.6174C184.615 5.16803 191.119 0.770042 199.338 0.770042C203.765 0.739894 208.095 2.04437 211.745 4.50764V12.053C208.423 8.9952 204.099 7.20599 199.552 7.00866C195.093 7.00866 191.676 8.68079 191.676 11.2943C191.676 13.6267 193.763 14.7086 198.237 15.9451L203.412 17.3502C211.188 19.4578 213.846 23.1532 213.846 28.352C213.846 34.422 208.214 40.0003 198.623 40.0003C193.717 39.9971 188.957 38.3629 185.116 35.3635Z" fill="#E5C287"></path>
                  <path d="M229.231 1.53809H263.077V7.90154H249.468V39.2304H242.727V7.90154H229.231V1.53809Z" fill="#E5C287"></path>
                  <path d="M281.538 1.53809H288.293L302.272 19.526L316.265 1.53809H323.077V39.2164H316.265V12.5346L302.272 30.4528L288.293 12.5346V39.2304H281.538V1.53809Z" fill="#E5C287"></path>
                  <path d="M361.161 0L381.538 39.2308H374.135L369.495 30.3353H352.812L348.173 39.2308H340.769L361.161 0ZM355.691 23.9571H366.631L361.161 13.2089L355.691 23.9571Z" fill="#E5C287"></path>
                  <path d="M396.64 35.3635V27.8181C398.413 29.6166 404.205 33.7897 410.169 33.7897C415.889 33.7897 418.277 31.2325 418.277 28.6331C418.277 26.4692 417.119 24.9517 412.428 23.7012L407.237 22.2961C399.071 20.076 396.154 16.6757 396.154 11.6174C396.154 5.16803 402.661 0.770042 410.884 0.770042C415.313 0.739894 419.645 2.04437 423.297 4.50764V12.053C419.973 8.9952 415.647 7.20599 411.098 7.00866C406.636 7.00866 403.218 8.68079 403.218 11.2943C403.218 13.6267 405.306 14.7086 409.782 15.9451L414.959 17.3502C422.739 19.4578 425.385 23.1532 425.385 28.352C425.385 34.422 419.764 40.0003 410.169 40.0003C405.256 39.9969 400.489 38.363 396.64 35.3635Z" fill="#E5C287"></path>
                </svg>
                <div class="christmas-hero-text">
                  <h3>{{ $t('_chr._dt1') }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tablet-view">
          <div id="cm-placement-placement2" class="cm-placement-placement2 width100">
            <div id="multi-3-teaser3" class="multi-3-teaser-module" style="">
              <div class="multi-teaser shop-hero-two" style="">
                <div class="multi-teaser-outer-container teaser-stack">
                  <div class="multi-teaser-inner-container" style="display: flex">
                    <div id="multi-3-teaser3_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Christmas Clothing " href="javascript:" class="hp-hover-image-wrapper" title="Christmas Clothing">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" title="Christmas Clothing" src="@/static/christmas/christmas-jumpers-2-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt1') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-3-teaser3_item_1" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Matching Family Christmas " href="javascript:" class="hp-hover-image-wrapper" title="Matching Family Christmas ">
                          <div class="">
                            <div class="video-wrapper">
                              <video autoplay="" loop="" muted="" playsinline="" name="media" poster="@/static/christmas/matching-family-data.jpg" class="" style="" id="html5_video_qver8zqh2g">
                                <source src="@/static/christmas/16.11.23_Christmas_video-1.5MB.mp4" type="video/mp4" />
                              </video>
                            </div>
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt2') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-3-teaser3_item_2" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Advent Calendars " href="javascript:" class="hp-hover-image-wrapper" title="Advent Calendars">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Matching Family Christmas" title="Advent Calendars" src="@/static/christmas/matching-family-christmas-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt3') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="multi-3-teaser4" class="multi-3-teaser-module" style="">
              <div class="multi-teaser shop-hero-two" style="">
                <div class="multi-teaser-outer-container teaser-stack">
                  <div class="multi-teaser-inner-container" style="display: flex">
                    <div id="multi-3-teaser4_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Christmas at Home " href="javascript:" class="hp-hover-image-wrapper" title="Christmas at Home">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Christmas at Home" title="Christmas at Home" src="@/static/christmas/christmas-at-home-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt4') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-3-teaser4_item_1" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Cosy Shop " href="javascript:" class="hp-hover-image-wrapper" title="Cosy Shop">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Frame 5 (2)-min" title="Cosy Shop" src="@/static/christmas/frame-5-2-min-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt5') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-3-teaser4_item_2" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Furniture in Time For Christmas " href="javascript:" class="hp-hover-image-wrapper" title="Furniture in Time For Christmas">
                          <div class="portrait_ratio4x5-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":1000}]' alt="Furniture in Time For Christmas" title="Furniture in Time For Christmas" src="@/static/christmas/furniture-in-time-for-christmas-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._dtt6') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="multi-4-teaser5" class="multi-4-teaser-module" style="">
              <div class="multi-teaser shop-hero-two" style="">
                <div class="multi-teaser-outer-container teaser-stack">
                  <div class="multi-teaser-inner-container" style="display: flex">
                    <div id="multi-4-teaser5_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <div href="#" class="hp-hover-image-wrapper">
                          <div class="hp-teaser-wrapper"></div>
                          <div class="hp-text-on-image-wrapper">
                            <div class="cm-teaser-overlay" style="left: 0%; margin-right: -0%; top: 17%; margin-bottom: -17%; transform: translate(-0%, -17%); width: 100%">
                              <div class="cm-teaser-overlay__text cm-richtext">
                                <div class="align--left hht">{{ $t('_chr._tre1') }}</div>
                                <div class="align--left hht2">{{ $t('_chr._tre2') }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="multi-4-teaser5_item_1" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Gingerbread " href="javascript:" class="hp-hover-image-wrapper" title="Gingerbread">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":800}]' alt="Gingerbread" title="Gingerbread" src="@/static/christmas/gingerbread-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tre3') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-4-teaser5_item_2" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Christmas Pudding " href="javascript:" class="hp-hover-image-wrapper" title="Christmas Gonks">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":800}]' alt="Christmas Gonks" title="Christmas Gonks" src="@/static/christmas/christmas-gonks-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tre4') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-4-teaser5_item_3" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Christmas Gonks " href="javascript:" class="hp-hover-image-wrapper" title="Christmas Pudding">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":800,"ratioHeight":800}]' alt="Christmas Pudding" title="Christmas Pudding" src="@/static/christmas/christmas-pudding-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._tre5') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="multi-9-teaser6" class="multi-9-teaser-module" style="">
              <div class="multi-teaser shop-services" style="">
                <div class="multi-teaser-outer-container teaser-swipe isSwipeForNarrow teaser-circular">
                  <div class="multi-teaser-inner-container" style="display: flex">
                    <div id="multi-9-teaser6_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Cards &amp; Wrapping Paper " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Cards &amp; Wrapping Paper">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="11-01 - roundel 1" title="Cards &amp; Wrapping Paper" src="@/static/christmas/11-01-roundel-1-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre1') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_1" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Toys &amp; Games " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Toys &amp; Games">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="Toys &amp; Games (1)" title="Toys &amp; Games" src="@/static/christmas/toys-games-1--data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre2') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_2" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Food &amp; Drink Gifts " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Food &amp; Drink Gifts">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="food and drink - roundel" title="Food &amp; Drink Gifts" src="@/static/christmas/food-and-drink-roundel-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre3') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_3" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Stocking Fillers " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Stocking Fillers">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="11-09 - roundel 4" title="Stocking Fillers" src="@/static/christmas/11-09-roundel-4-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre4') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_4" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Secret Santa " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Secret Santa">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="Secret Santa-min" title="Secret Santa" src="@/static/christmas/secret-santa-min-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre5') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_5" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Baby's First Christmas " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Baby's First Christmas">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="Baby's First Christmas" title="Baby's First Christmas" src="@/static/christmas/baby-s-first-christmas-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre6') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_6" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Beauty Gifts " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Beauty Gifts">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="11-01 - roundel 7-min" title="Beauty Gifts" src="@/static/christmas/11-01-roundel-7-min-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre7') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_7" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Premium Gifts " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Premium Gifts">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="11-01 - roundel 8 (1)" title="Premium Gifts" src="@/static/christmas/11-01-roundel-8-1--data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre8') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div id="multi-9-teaser6_item_8" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <a aria-label="Personalised Gifts " data-module="multi-9-teaser-module " data-block="shop-services" href="javascript:" class="hp-hover-image-wrapper" title="Personalised Gifts">
                          <div class="portrait_ratio1x1-box">
                            <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" data-cm-aspect-ratio='[{"ratioWidth":362,"ratioHeight":362}]' alt="11-09 - roundel 9" title="Personalised Gifts" src="@/static/christmas/11-09-roundel-9-data.jpg" />
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header" style="">{{ $t('_chr._ttre9') }}</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 商品列表 -->
            <div id="multi-5-teaser9" class="multi-5-teaser-module" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="getChristmas" infinite-scroll-distance="5">
              <div class="multi-teaser shop-trending-now" style="">
                <!-- <div class="multi-teaser-title" style="">Latest news &amp; articles</div> -->
                <div class="multi-teaser-outer-container teaser-swipe isSwipeForNarrow">
                  <div class="multi-teaser-inner-container" style="display: flex; flex-wrap: wrap">
                    <div v-for="(vo, i) in goodsList" :key="i" id="multi-5-teaser9_item_0" class="multi-teaser-item">
                      <div class="layoutCls gutter-bt-teaser">
                        <router-link :to="{ name: 'detail', query: { id: vo.id } }" class="hp-hover-image-wrapper" title="Christmas Lighting Ideas ">
                          <div class="portrait_ratio4x5-box hibox">
                            <div class="hibox2">
                              <img class="hp-tile-slider__img c-banner-tile__img img-responsive cm-image" :src="vo.img" />
                            </div>
                          </div>
                          <div class="hp-teaser-wrapper">
                            <h3 class="hp-tile-module-teaser__header h-name" style="">{{ vo.name }}</h3>
                          </div>
                          <div class="hp-teaser-wrapper">
                            <div style="display: flex; gap: 10px; font-size: 13px; font-weight: 700">
                              <span>$ {{ vo.price }}</span>
                              <span style="color: #666; text-decoration: line-through">$ {{ vo.cost_price }}</span>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 加载更多 -->
        <div v-show="loadmore" class="loadmore" style="padding-bottom: 20px">
          <img class="_2O2Fm" src="@/static/img/loading.gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChristmasVue',
  data() {
    return {
      //加载更多
      scrollDisabled: false,
      loadmore: false,
      goodsList: [],
      page: 1
    }
  },
  created() {
    this.page = 1
    this.getChristmas()
  },
  methods: {
    getChristmas() {
      if (this.loadmore) {
        return
      }
      this.loadmore = true

      let that = this
      this.$api.activity.getChristmas({ page: this.page, size: 10 }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsList.push(item)
        })
        that.loadmore = false
      })
    }
  }
}
</script>

<style scoped>
@import url('@/static/christmas/modernised-header-preload-responsive.css');
@import url('@/static/christmas/christmas.css');
</style>
<style></style>
