<template>
  <div>
    <!-- 顶部导航 -->
    <HeadNav></HeadNav>
    <!-- 搜索烂 -->
    <!-- <ShopCarSearch></ShopCarSearch> -->
    <!-- 购物车内容 -->
    <div id="root" style="-margin-top: 135px">
      <div class="cart-wrap">
        <!-- 有商品 -->
        <div class="cart-body" v-if="cartList.length > 0">
          <div class="cart-main">
            <div class="cart-header-content">
              <div class="cart-header-title">{{ $t('shopping.title') }}</div>
              <!-- <div class="cart-header-activity">
                <img src="@/static/img/ld.png">
                <div>
                  <div>Sale ends on: <span>Nov 12, 11:59 PM PT</span></div>
                  <div>Enjoy savings before the sale ends</div>
                </div>
              </div> -->
            </div>
            <!-- 全选 -->
            <!-- <div class="cart-header-checkbox" style="top: 0px;">
              <label class="comet-checkbox">
                <span class="comet-checkbox-icon">
                  <span class="comet-checkbox-circle"></span>
                </span>
                <input type="checkbox" class="comet-checkbox-input" value="">
                <span>Select all items</span>
              </label>
              <button type="button" class="comet-btn comet-btn-text">
                <span>Delete</span>
              </button>
            </div> -->
            <div class="cart-list">
              <div class="infinite-scroll-component__outerdiv">
                <div class="infinite-scroll-component comet-infinite-scroll" style="height: auto; overflow: hidden auto">
                  <!-- 购物车列表 -->
                  <div class="cart-store-block" v-for="(item, i) in cartList" :key="i">
                    <!-- 店铺名称 -->
                    <div class="cart-store">
                      <div class="cart-store-content">
                        <!-- 按店铺未选中 -->
                        <label @click="onShopCheck(i, true)" v-if="!checked[i].all" class="comet-checkbox">
                          <span class="comet-checkbox-icon">
                            <span class="comet-checkbox-circle"></span>
                          </span>
                        </label>
                        <!-- 按店铺未选中 -->
                        <label @click="onShopCheck(i, false)" v-if="checked[i].all" class="comet-checkbox comet-checkbox-checked">
                          <span class="comet-icon comet-icon-rightfill">
                            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                              <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m225.290667 297.376a32 32 0 0 0-45.248 0L469.258667 605.472 353.226667 490.144l-1.546667-1.429333a32 32 0 0 0-43.573333 46.826666l138.666666 137.813334 1.536 1.44a32 32 0 0 0 43.648-1.493334l245.333334-245.333333 1.44-1.546667a32 32 0 0 0-1.44-43.712z"></path>
                            </svg>
                          </span>
                        </label>
                        <img src="@/static/img/store.png" class="cart-store-icon" />
                        <a href="javascript:" target="_blank">
                          <span>{{ item.name }}</span>
                        </a>
                      </div>
                      <!-- <a class="cart-store-more">Get coupons</a> -->
                    </div>
                    <!-- 商品列表 -->
                    <div class="cart-product-wrap" v-for="(vo, k) in item.goods_list" :key="k">
                      <div class="cart-product">
                        <!-- 选项与图片 -->
                        <div class="cart-product-body" style="opacity: 1">
                          <!-- 未选中 -->
                          <label @click="onCheck(i, k, true)" v-if="!checked[i].checked[k]" class="comet-checkbox">
                            <span class="comet-checkbox-icon comet-icon-rightfill">
                              <span class="comet-checkbox-circle"></span>
                            </span>
                          </label>
                          <!-- 已选中 -->
                          <label @click="onCheck(i, k, false)" v-if="checked[i].checked[k]" class="comet-checkbox comet-checkbox-checked">
                            <span class="comet-icon comet-icon-rightfill">
                              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m225.290667 297.376a32 32 0 0 0-45.248 0L469.258667 605.472 353.226667 490.144l-1.546667-1.429333a32 32 0 0 0-43.573333 46.826666l138.666666 137.813334 1.536 1.44a32 32 0 0 0 43.648-1.493334l245.333334-245.333333 1.44-1.546667a32 32 0 0 0-1.44-43.712z"></path>
                              </svg>
                            </span>
                          </label>
                          <!-- 商品图片 -->
                          <a href="javascript:" target="_blank">
                            <div class="cart-product-img" :style="'background-image: url(' + (vo.goods && vo.goods.img) + '); opacity: 1;'"></div>
                          </a>
                        </div>

                        <!-- 信息 -->
                        <div class="cart-product-info">
                          <!-- 商品名称 -->
                          <div class="cart-product-name">
                            <a href="javascript:" target="_blank">{{ vo.goods && vo.goods.name }}</a>
                            <!-- 删除 -->
                            <div class="cart-product-name-ope" @click="onRmShopCart(item.goods_list, k, vo)">
                              <span class="comet-icon comet-icon-delete" style="font-size: 16px">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                                  <path
                                    d="M615.253333 85.226667a99.2 99.2 0 0 1 99.072 94.336l0.128 4.544 0.042667 18.538666 186.304 0.021334a32 32 0 0 1 3.072 63.850666l-3.072 0.149334-37.653333-0.021334-57.066667 570.368a121.6 121.6 0 0 1-116.096 109.781334l-4.928 0.106666H353.642667a121.6 121.6 0 0 1-120.832-107.946666L166.016 266.624 128 266.666667a32 32 0 0 1-3.072-63.850667L128 202.666667l182.165333-0.021334 0.704-21.504A99.2 99.2 0 0 1 405.461333 85.333333l4.544-0.106666h205.226667z m183.082667 188.586666H230.997333l65.429334 557.952a57.6 57.6 0 0 0 53.589333 51.029334l3.626667 0.106666h331.413333a57.6 57.6 0 0 0 57.322667-52.053333l55.957333-557.034667z m-179.52 152.874667l3.072 0.042667c17.493333 0.938667 31.146667 15.146667 31.744 32.298666l-0.021333 3.072-13.461334 246.528a33.6 33.6 0 0 1-67.136-0.597333l0.042667-3.072 13.44-246.528c0.96-17.493333 15.146667-31.146667 32.32-31.744z m-209.578667 0c17.173333 0.618667 31.36 14.250667 32.32 31.744l13.44 246.528 0.042667 3.072a33.6 33.6 0 0 1-67.136 0.597333l-13.44-246.528-0.042667-3.072a33.6 33.6 0 0 1 31.744-32.298666zM615.253333 149.226667h-205.226666c-17.941333 0-32.832 13.44-34.944 30.912l-0.256 3.114666-0.64 19.392h276.309333l-0.042667-18.346666a35.2 35.2 0 0 0-32.170666-34.944l-3.029334-0.128z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <!-- 显示规格 -->
                          <div class="cart-product-sku">
                            <button type="button" class="comet-btn comet-btn-small comet-btn-borderless" style="display: flex">
                              <span v-for="(svo, sk) in vo.sku.remark" :key="sk" style="margin-right: 5px">{{ sk }}:{{ svo }}</span>
                            </button>
                          </div>
                          <!-- 显示价格 -->
                          <div class="cart-product-block">
                            <div class="cart-product-block-left">
                              <!-- 显示价格 -->
                              <div class="cart-product-price">
                                <span>US ${{ vo.sku.price }}</span>
                                <span class="cart-product-price-s">
                                  <span style="text-decoration: line-through; color: #cccccc">US ${{ vo.sku.cost_price }}</span>
                                  <span class="price-dist">-{{ vo.sku.discount }}%</span>
                                </span>
                              </div>
                              <!-- 运费免费 -->
                              <div class="cart-product-ship">{{ $t('detail.free.shipping') }}</div>
                            </div>

                            <!-- 右边数量 -->
                            <div class="cart-product-block-right">
                              <div class="cart-product-price-picker">
                                <div class="comet-input-number">
                                  <!-- 减少 -->
                                  <div :class="['comet-input-number-btn', vo.num <= 1 ? 'comet-input-number-btn-disabled' : '']" @click="onSubNum(vo)">
                                    <span class="comet-icon comet-icon-subtract">
                                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                        <path d="M864 480a32 32 0 0 1 1.877333 63.946667L864 544H160a32 32 0 0 1-1.877333-63.946667L160 480h704z"></path>
                                      </svg>
                                    </span>
                                  </div>
                                  <!-- 当前数量 -->
                                  <input type="text" class="comet-input-number-input" v-model="vo.num" @input="onNumber(vo)" />
                                  <!-- 增加 -->
                                  <div :class="['comet-input-number-btn', vo.num >= vo.sku.total ? 'comet-input-number-btn-disabled' : '']" @click="onAddNum(vo)">
                                    <span class="comet-icon comet-icon-add">
                                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                        <path d="M864 480a32 32 0 0 1 1.877333 63.946667L864 544H160a32 32 0 0 1-1.877333-63.946667L160 480h704z"></path>
                                        <path d="M512 128a32 32 0 0 1 31.946667 30.122667L544 160v704a32 32 0 0 1-63.946667 1.877333L480 864V160a32 32 0 0 1 32-32z"></path>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cart-side">
            <div class="cart-summary">
              <div class="cart-summary-title">{{ $t('confirm.summary') }}</div>
              <div class="">
                <!-- 计费列表 -->
                <div class="cart-summary-list">
                  <!-- 小计 -->
                  <div class="cart-summary-item" style="font-weight: 600; cursor: default" v-if="sumTotal !== '0.00'">
                    <div class="cart-summary-item-label">{{ $t('confirm.total.item.costs') }}</div>
                    <div class="cart-summary-item-content">
                      <span style="padding: 0px 2px; font-size: 14px; color: rgb(51, 51, 51)">US ${{ subTotal }}</span>
                      <span class="comet-icon comet-icon-arrowdown" style="padding: 0px 4px; visibility: hidden">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M85.802667 280.96a32 32 0 0 0-5.397334 42.517333l2.005334 2.602667 338.901333 393.856a107.52 107.52 0 0 0 158.101333 5.333333l3.050667-3.221333 358.570667-395.349333a32 32 0 0 0-45.077334-45.312l-2.346666 2.304L535.082667 679.04a43.52 43.52 0 0 1-62.784 1.770667l-2.432-2.624L130.922667 284.330667a32 32 0 0 0-45.12-3.392z"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <!-- 运费 -->
                  <!-- <div class="cart-summary-item" style="font-weight: 600; cursor: default;" v-if="sumTotal !== '0.00'">
                    <div class="cart-summary-item-label">Shipping fee</div>
                    <div class="cart-summary-item-content">
                      <span style="padding: 0px 2px; font-size: 14px; color: rgb(51, 51, 51);">US $3.00</span>
                      <span class="comet-icon comet-icon-arrowdown " style="padding: 0px 4px; visibility: hidden;">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M85.802667 280.96a32 32 0 0 0-5.397334 42.517333l2.005334 2.602667 338.901333 393.856a107.52 107.52 0 0 0 158.101333 5.333333l3.050667-3.221333 358.570667-395.349333a32 32 0 0 0-45.077334-45.312l-2.346666 2.304L535.082667 679.04a43.52 43.52 0 0 1-62.784 1.770667l-2.432-2.624L130.922667 284.330667a32 32 0 0 0-45.12-3.392z"></path>
                        </svg>
                      </span>
                    </div>
                  </div> -->
                  <!-- 总计 -->
                  <div class="cart-summary-item" style="font-weight: 900; cursor: default">
                    <div class="cart-summary-item-label">{{ $t('confirm.total') }}</div>
                    <div class="cart-summary-item-content">
                      <span style="padding: 0px 2px; font-size: 20px; color: rgb(51, 51, 51)">US ${{ sumTotal }}</span>
                      <span class="comet-icon comet-icon-arrowdown" style="padding: 0px 4px; visibility: hidden">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M85.802667 280.96a32 32 0 0 0-5.397334 42.517333l2.005334 2.602667 338.901333 393.856a107.52 107.52 0 0 0 158.101333 5.333333l3.050667-3.221333 358.570667-395.349333a32 32 0 0 0-45.077334-45.312l-2.346666 2.304L535.082667 679.04a43.52 43.52 0 0 1-62.784 1.770667l-2.432-2.624L130.922667 284.330667a32 32 0 0 0-45.12-3.392z"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 立即下单 -->
              <button @click="onSubmit()" type="button" class="comet-btn comet-btn-primary comet-btn-large comet-btn-block cart-summary-button">
                <span>{{ $t('confirm.checkout') }}</span>
              </button>
            </div>
            <div class="cart-icons">
              <!-- <div class="cart-icons-title">Payment methods</div>
              <div class="cart-icons-list">
                <img class="cart-icons-img" src="https://img.alicdn.com/tfs/TB1xcMWdEKF3KVjSZFEXXXExFXa-68-48.png">
                <img class="cart-icons-img" src="https://img.alicdn.com/tfs/TB19TEYdB1D3KVjSZFyXXbuFpXa-53-48.png">
                <img class="cart-icons-img" src="https://img.alicdn.com/tfs/TB18So3dBKw3KVjSZFOXXarDVXa-41-48.png">
                <img class="cart-icons-img" src="https://img.alicdn.com/imgextra/i4/O1CN01wsEOAW1w5IUcKByeV_!!6000000006256-2-tps-648-765.png">
                <img class="cart-icons-img" src="https://img.alicdn.com/imgextra/i4/O1CN01CZadgx1WHxNfiV2OU_!!6000000002764-2-tps-242-104.png">
              </div> 
              <div class="cart-icons-line"></div>
              -->
              <div class="cart-icons-title" style="display: flex; align-items: center">{{ $t('detail.buyer.protection') }}</div>
              <div class="cart-icons-list">
                <img src="@/static/img/dun_lv.png" height="21" style="vertical-align: middle" />
                &nbsp;&nbsp;{{ $t('detail.refund.desc') }}
              </div>
            </div>
          </div>
        </div>
        <!-- 无商品 -->
        <div class="cart-body" v-else>
          <div class="cart-main">
            <div class="cart-empty">
              <div class="es--comet-pro-fallback--3ctNGin es--pc--1mZE03B cart-empty-wrap">
                <img src="@/static/img/cart.png" class="es--comet-pro-fallback-image--35CZGig es--pc--1mZE03B" />
                <span class="es--comet-pro-fallback-title--1dzE5qT es--pc--1mZE03B" style="font-size: 16px">{{ $t('shopping.back.buy') }}</span>
                <div class="es--comet-pro-fallback-button-container--1Tnc92l es--vertical--1KfvoOr">
                  <router-link :to="{ name: 'index' }" class="comet-btn comet-btn-primary comet-btn-large es--comet-pro-fallback-button--14Sy9hq">
                    <span>{{ $t('shopping.start.shopping') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comet-back-top" style="bottom: 100px"></div>
    </div>

    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from '@/components/common/HeadNav.vue'
// import ShopCarSearch from '@/components/member/ShopCarSearch.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'shopcarVue',
  components: {
    HeadNav,
    // ShopCarSearch,
    Footer
  },
  data() {
    return {
      //购物车信息
      cartList: [],

      //选中列表
      checked: {},

      //总计金额
      cartids: [],
      subTotal: '0.00',
      sumTotal: '0.00'
    }
  },
  created() {
    //获取购物车
    this.getShopCartList()
  },
  methods: {
    /**
     *  获取购物车
     */
    getShopCartList() {
      let that = this
      this.$api.order.getShopCartList().then((res) => {
        that.cartList = res.data

        //组合购物车选中数据结构
        that.cartList.forEach((item, i) => {
          that.checked[i] = {
            all: false,
            checked: {}
          }
          item.goods_list.forEach((vo, k) => {
            that.checked[i].checked[k] = false
          })
        })
        console.log(that.checked)
      })
    },
    onShopCheck(i, val) {
      if (this.checked[i]) {
        this.checked[i].all = val
        const list = this.checked[i].checked
        for (var n in list) {
          list[n] = val
        }
      }
      console.log(this.checked)

      //计算总价
      this.setTotal()
    },
    onCheck(i, k, val) {
      this.checked[i].checked[k] = val
      this.checked[i].all = this.hasAll(this.checked[i].checked)

      console.log(this.checked)

      //计算总价
      this.setTotal()
    },
    hasAll(arr) {
      for (var i in arr) {
        if (arr[i] === false) {
          return false
        }
      }
      return true
    },
    /**
     * 计算总金额
     */
    setTotal() {
      let list = this.cartList
      let total = 0
      let cartids = []
      for (var i in this.checked) {
        const box = this.checked[i].checked
        for (var k in box) {
          if (box[k]) {
            let goods = list[i].goods_list[k]
            let price = goods.sku.price
            let num = goods.num
            total += parseFloat(parseInt(num) * parseFloat(price))

            cartids.push(goods.cart_id)
          }
        }
      }

      if (total > 0) {
        this.subTotal = total.toFixed(2)
        this.sumTotal = total.toFixed(2)
      } else {
        this.subTotal = '0.00'
        this.sumTotal = '0.00'
      }

      this.cartids = cartids

      console.log('总金额', this.subTotal, this.sumTotal, this.cartids)
    },
    onSubNum(vo) {
      if (vo.num <= 1) {
        return
      }
      vo.num--

      //请求接口
      this.$api.order.saveShopCart({ cart_id: vo.cart_id, type: 'sub' }).then((res) => {
        console.log(res)
      })

      //计算总价
      this.setTotal()
    },
    onAddNum(vo, num = 0) {
      if (vo.num > vo.sku.total) {
        return
      }
      if (num == 0) {
        vo.num++
      }

      //请求接口
      this.$api.order.saveShopCart({ cart_id: vo.cart_id, num: num, type: 'add' }).then((res) => {
        console.log(res)
      })

      //计算总价
      this.setTotal()
    },
    onNumber(vo) {
      console.log(vo)
      let num = vo.sku.total
      if (vo.num > num) {
        vo.num = num
      }

      this.onAddNum(vo, vo.num)

      //计算总价
      // this.setTotal()
    },
    onRmShopCart(item, k, vo) {
      let that = this
      this.$message.loading({ content: 'remove cart...', key: 'msg' })
      this.$api.order.rmShopCart({ id: vo.cart_id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        //重新加载
        that.getShopCartList()
      })
    },
    /**
     * 立即下单
     */
    onSubmit() {
      this.$router.push({
        name: 'confirm',
        query: { type: 'cart', cart_ids: this.cartids }
      })
    }
  }
}
</script>

<style scoped src="@/static/css/shopcar-index.css"></style>
<style scoped src="@/static/css/slider-kTGCl.css"></style>
<style scoped src="@/static/css/gallery.css"></style>
<style scoped src="@/static/css/index.css"></style>
