import { createStore } from 'vuex'
import api from '../api'
import common from './common'
import socket from './socket'

const _base = {
  namespaced: true,
  state() {
    return {
      msgCenter: {
        msg: 0,
        order: 0,
        comment: 0
      }
    }
  },
  mutations: {
    setMsg(state, msg) {
      state.msgCenter = msg
    }
  },
  actions: {
    getBase(context) {
      //判断是否登录
      if (!common.isLogin()) {
        return false
      }

      api.home.getBase().then((dat) => {
        context.commit('setMsg', dat.data)
      })

      console.log(socket.getReadyState(), '连接状态')
      //判断连接
      socket.on('msg', function (data) {
        console.log('回调', data)
        context.commit('setMsg', data)
      })
    }
  }
}

const _order = {
  namespaced: true,
  state() {
    return {
      buy: {}
    }
  },
  mutations: {
    setBuy(state, data) {
      state.buy = data
    },
    setCart(state, data) {
      state.comment = data
    }
  },
  actions: {}
}

// 创建一个新的 store 实例
const store = createStore({
  modules: {
    base: _base,
    order: _order
  }
})

export default store
