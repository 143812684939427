<template>
  <div>
    <div class="comet-modal-mask comet-fade-enter-done comet-fade-enter comet-fade-enter-active" v-if="isShow"></div>
    <div tabindex="-1" class="comet-modal-wrap" v-if="isShow">
      <div class="comet-modal pl-address-model-cls-pc large comet-zoom-enter-done comet-zoom-enter comet-zoom-enter-active" style="width: 850px;">
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
        <button @click="onClose()" type="button" aria-label="Close" class="comet-modal-close">
          <span class="comet-icon comet-icon-close ">
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.placeorder.0.i42.3830321e8Xqlnl">
              <path d="M181.088 135.936l1.536 1.44L512 466.741333l329.376-329.365333a32 32 0 0 1 46.688 43.712l-1.44 1.536L557.258667 512l329.365333 329.376a32 32 0 0 1-43.712 46.688l-1.536-1.44L512 557.258667 182.624 886.624a32 32 0 0 1-46.688-43.712l1.44-1.536L466.741333 512 137.376 182.624a32 32 0 0 1 43.712-46.688z" data-spm-anchor-id="a2g0o.placeorder.0.i43.3830321e8Xqlnl"></path>
            </svg>
          </span>
        </button>
        <div class="comet-modal-content comet-modal-no-footer">
          <div class="comet-modal-header">
            <div class="comet-modal-title">{{$t('address.title')}}</div>
          </div>
          <!-- 选择地址 -->
          <div class="comet-modal-body" v-if="step == 1">
            <div class="cm-address large">
              <div class="cm-address-list cm-address-list-trade">
                <div @click="onChecked(i)" v-for="(item,i) in addressList" :key="i" class="cm-address-item cm-address-item-trade trade-left-item large">
                  <div class="cm-address-item-wrapper">
                    <div class="cm-address-item-select">
                      <!-- 未选中 -->
                      <label v-if="checked !== i" class="comet-radio">
                        <span class="comet-radio-icon">
                          <span class="comet-radio-circle"></span>
                        </span>
                        <input type="radio" class="comet-radio-input" value="">
                      </label>
                      <!-- 选中 -->
                      <label v-if="checked == i" class="comet-radio comet-radio-checked">
                        <span class="comet-icon comet-icon-stepon ">
                          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                            <path d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667 85.333333 747.637333 85.333333 512 276.362667 85.333333 512 85.333333z m0 64c-200.298667 0-362.666667 162.368-362.666667 362.666667s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667-162.368-362.666667-362.666667-362.666667z m0 149.333334c117.824 0 213.333333 95.509333 213.333333 213.333333s-95.509333 213.333333-213.333333 213.333333-213.333333-95.509333-213.333333-213.333333 95.509333-213.333333 213.333333-213.333333z"></path>
                          </svg>
                        </span>
                        <input type="radio" class="comet-radio-input" value="" checked="">
                      </label>
                    </div>
                    <div class="cm-address-item-content-wrapper">
                      <div class="cm-address-item-content">
                        <div class="address-name">
                          <span>{{item.contact}}</span>
                          <span class="s-text">{{item.phone_country}}</span>
                          <span class="s-text">{{item.phone}}</span>
                        </div>
                        <div class="address-info">{{item.address}}</div>
                        <!-- 默认 -->
                        <div class="cm-address-item-opt-btn" v-if="item.default == 2">
                          <span class="cm-address-item-content-default-tag">{{$t('address.default')}}</span>
                        </div>
                      </div>
                      <!-- 操作 -->
                      <div class="cm-address-item-operate">
                        <span>
                          <a @click.stop="edit(item)" href="javascript:;">{{$t('address.edit')}}</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cm-address-add cm-address-add-trade large">
                <button type="button" class="comet-btn comet-btn-primary add-address" @click="onCreate()">
                  <span>{{$t('address.new')}}</span>
                </button>
              </div>
            </div>
          </div>
          <!-- 添加新地址 -->
          <div class="" v-if="step == 2">
            <div class=" large">
              <div class="cm-address-list-trade">
                <div class="address-form-wrap">
                  <div class="ship-info">
                    <div id="country-selector"></div>
                    <div class="group-content personInfo">
                      <p class="title">{{$t('address.contact')}}</p>
                      <div class="input-default contactPerson">
                        <div>
                          <span data-meta="Field" class="next-input next-large">
                            <input :placeholder="$t('address.contact')" height="100%" autocomplete="off" v-model="param.contact">
                          </span>
                          <p class="error-msg"></p>
                        </div>
                        <p class="tips"></p>
                      </div>
                      <div class="input-default phoneCountry">
                        <div class="phone-input">
                          <div class="label-input">
                            <span data-meta="Field" class="next-input next-large">
                              <input id="phoneCountry" placeholder="+1 (246)" height="100%" autocomplete="off" v-model="param.phone_country">
                            </span>
                          </div>
                          <div class="phone-text">
                            <span data-meta="Field" class="next-input next-large">
                              <input id="mobileNo" :placeholder="$t('address.phone')" height="100%" autocomplete="off" v-model="param.phone">
                            </span>
                          </div>
                          <p class="error-msg"></p>
                          <p class="error-msg"></p>
                        </div>
                      </div>
                    </div>
                    <div class="group-content addressInfo">
                      <p class="title">{{$t('address.address')}}</p>
                      <div class="input-default2 address">
                        <div><span data-meta="Field" class="next-input next-large">
                            <input :placeholder="$t('address.address')" height="100%" autocomplete="off" v-model="param.address">
                          </span>
                          <p class="error-msg"></p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label :class="['next-checkbox-wrapper',param.is_default === 1?'':'checked']">
                        <span class="next-checkbox">
                          <span class="next-checkbox-inner">
                            <i class="next-icon next-icon-select next-xs"></i>
                          </span>
                          <input type="checkbox" class="next-checkbox-input" @click="onDefault()">
                        </span>
                        <span class="next-checkbox-label">
                          <span>{{$t('address.set.default')}}</span>
                        </span>
                      </label>
                    </div>
                    <div class="address-save">
                      <button type="button" class="next-btn next-medium next-btn-primary" role="button" @click="onSubmit()">{{$t('address.confirm')}}</button>
                      <button type="button" class="next-btn next-medium next-btn-normal" role="button" @click="onCancel()">{{$t('address.cancel')}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none;"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import Country from "@/components/order/Country.vue";
export default {
  name: "shoppingAddress",
  components: {
    // Country,
  },
  props: {
    onlyAdd: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      isShow: false,
      step: 1,

      // 参数
      param: {
        id: 0,
        contact: "",
        phone: "",
        phone_country: "+246",
        address: "",
        is_default: 1,
      },

      //地址列表
      addressList: [],
      checked: 0,
    };
  },
  created () {
    if (!this.onlyAdd) {
      this.getAddress();
    }
  },
  methods: {
    /**
     * 获取用户的收货地址
     */
    getAddress () {
      let that = this;
      this.$api.user.getAddress().then((res) => {
        that.addressList = res.data;
        //当前选中
        if (that.addressList.length > 0) {
          this.$emit("setAddress", this.addressList[0]);
        }
      });
    },
    /**
     * 选择已有的
     */
    onChecked (i) {
      this.checked = i;
      //当前选中
      this.$emit("setAddress", this.addressList[i]);
      this.hide();
    },
    /**
     * 打开
     */
    open (i = 1) {
      this.isShow = true;
      if (i == 2) {
        this.onCreate()
      }
    },
    /**
    * 打开
    */
    edit (item) {
      this.isShow = true;
      this.param = {
        id: item.id,
        contact: item.contact,
        phone: item.phone,
        phone_country: item.phone_country,
        address: item.address,
        is_default: item.default,
      }
      this.step = 2;
    },
    /**
     * 关闭
     * @param {*} i 
     */
    hide () {
      this.isShow = false;
    },
    /**
     * 关闭
     */
    onClose () {
      this.hide();
    },
    /**
     * 创建地址
     */
    onCreate () {
      this.param = {
        contact: "",
        phone: "",
        phone_country: "+246",
        address: "",
        is_default: 1,
      }
      this.step = 2;
    },
    /**
     * 取消
     */
    onCancel () {
      if (this.onlyAdd) {
        this.hide()
      } else {
        if (this.addressList.length > 0) {
          this.step = 1;
        } else {
          this.hide();
        }
      }
    },
    // onOpen() {
    //   this.$refs.country.open();
    // },
    onDefault () {
      this.param.is_default = this.param.is_default == 1 ? 2 : 1;
    },
    valid () {
      if (this.param.contact == "") {
        this.$message.error(this.$t("contact.empty"));
        return false;
      }

      if (this.param.phone_country == "") {
        this.$message.error(this.$t("phone.country.empty"));
        return false;
      }

      if (this.param.phone == "") {
        this.$message.error(this.$t("phone.empty"));
        return false;
      }

      return true;
    },
    onSubmit () {
      if (!this.valid()) {
        return;
      }

      //提交
      let that = this;
      this.$api.user.saveAddress(this.param).then((res) => {
        that.$message.success(res.msg);
        that.$emit('setAddress', res);
        if (!that.onlyAdd) {
          that.getAddress();
          that.step = 1;
        }
      });
    },
  },
};
</script>
<style scoped src="../../static/css/address.css"></style>
<style scoped src="../../static/css/address2.css"></style>
<style scoped src="../../static/css/confirm-index.css"></style>