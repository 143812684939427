export default {
  'nav.account': '个人中心',
  'nav.language': '简体中文',
  'signup.success': '注册成功',

  //error
  'contact.empty': '联系方式不能为空',
  'phone.country.empty': '电话前缀不能为空',
  'phone.empty': '电话不能为空',
  'email.valid': '请输入正确的邮箱',

  // base
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': '请拉动滑条验证',
  'base.send.code': '发送验证码',
  'base.send.time': '({time}) 重发验证码',
  'base.send.success': '发送成功',
  'base.view.more': '浏览更多',
  'base.Language': '语言',
  'base.previous': '上一页',
  'base.next': '下一页',
  'base.total.page': '共 {page} 页',
  'base.go.to.page': '转到页面',
  'base.go': '前往',
  'base.followers': '关注量',
  'base.follow': '关注',
  'base.following': '已关注',
  'base.visit.store': '浏览店铺',
  'base.contact': '联系我们',
  'base.delivery': '预计交货时间:1-7 天',
  'base.search': '搜索',
  'base.search.text': '输入关键字搜索',
  'base.server': '在线客服',

  //index
  'text.categories.title': '商品分类',
  'text.welcome': '欢迎购物!',
  'text.wishlist': '喜欢列表',
  'text.account': '个人中心',
  'text.orders': '订单',
  'text.messages': '消息',
  'text.suggest.title': '独家优惠',
  'text.suggest.content': '只为新的全球至尊会员!',
  'text.super.deals': '顶级产品,令人难以置信的价格.',

  //signup
  'signup.register': '注册',
  'signup.signin': '登录',
  'signup.store.signin': '商家登录',
  'signup.sign.out': '注销',
  'signup.email': '邮箱地址',
  'signup.password': '密码',
  'signup.qr.password': '确认密码',
  'signup.confirm.password': '确认密码',
  'signup.forgot.password': '忘记密码',
  'signup.invitation': '邀请码',
  'signup.char': '字符',
  'signup.contains': '包含数字、字母或符号',
  'signup.qr.contains': '两次密码不一致',
  'signup.create.account': '创建账户',
  'signup.agree': '创建帐户，则同意',
  'signup.member.agreement': '会员协议',
  'signup.and': '与',
  'signup.privacy.policy': '隐私政策',
  'signup.email.code': '邮箱验证',
  'signup.last.step': '最后一步',
  'signup.send.email': '请输入发送至{email}的4位数代码',
  'signup.modify.email': '修改邮箱',
  'signup.verify.email': '验证邮箱',
  'signup.have.store': '有商家账号',
  'signup.goto.signin': '商家登录',
  'signup.no.store': '没有商家账号',
  'signup.goto.store': '注册商家',
  'signup.next': '下一步',
  'signup.your.email': '你的邮箱',
  'signup.code.text': '验证码',
  'signup.submit.signup': '立即注册',
  'signup.smrz': '实名认证',
  'signup.derb': '第二步',
  'signup.qsrxm': '请输入证件姓名',
  'signup.qsrhm': '请输入证件号码',

  //forgot
  'forgot.title': '重置密码',
  'forgot.btn.check': '验证邮箱',
  'forgot.reset.now': '立即重置',

  //store
  'store.info.open': '开店{y}周',

  // footer
  'footer.great.value': '巨大的价值',
  'footer.great.value.desc': '我们为超过1亿件商品提供具有竞争力的价格.',
  'footer.shopping': '全球购物',
  'footer.shopping.desc': '我们向200多个国家和地区发货,我们的网站提供7种语言.',
  'footer.safe.payment': '安全支付',
  'footer.safe.payment.desc': '使用世界上最流行和最安全的支付方式进行支付.',
  'footer.shop.with.confidence': '放心购物',
  'footer.shop.with.confidence.desc': '我们的买家保护政策涵盖您的整个购买过程.',
  'footer.help.center': '帮助中心',
  'footer.help.center.desc': '全天候协助,打造顺畅的购物体验.',
  'footer.terms.conditions': '条款和条件',
  'footer.return.policy': '退货政策',
  'footer.support.policy': '支持政策',
  'footer.privacy.policy': '隐私政策',
  'footer.be.seller': '成为商家',
  'footer.apply.now': '立即申请',
  'footer.stay.connected': '保持联系',

  'footer.about.us': '关于我们',
  'footer.about.company': '公司简介',
  'footer.about.video': '视频介绍',
  'footer.contact': '联系方式',

  'footer.my.account': '我的账户',
  'footer.my.logout': '退出登录',
  'footer.my.order': '订单历史',
  'footer.my.wish': '我的愿望清单',
  'footer.my.join': '成为会员合作伙伴',
  'footer.email': '电子邮箱',
  'footer.gfemail': '官方邮箱',
  'footer.fwemail': '服务邮箱',
  'footer.address': '地址',

  'apply.success': '申请成功',
  'apply.success.desc': '已申请成功,前往登录账户',

  // categories
  'category.title': '相关分类',
  'category.all': '所有分类',

  // detail
  'detail.store.home': '店铺首页',
  'detail.sale.items': '商品列表',
  'detail.recommend': '推荐商品',
  'detail.orders': '销量',
  'detail.quantity': '数量',
  'detail.pieces.available': '可用',
  'detail.delivery': '送货',
  'detail.free.shipping': '免运费',
  'detail.estimated.delivery': '预计到达',
  'detail.days': '天',
  'detail.buy.now': '购买',
  'detail.add.to.cart': '加入购物车',
  'detail.buyer.protection': '买家保护',
  'detail.money.guarantee': '退款保证',
  'detail.refund.desc': '如果物品与描述不符或未送达，可获得全额退款',
  'detail.description': '商品描述',
  'detail.customer.reviews': '顾客评论',
  'detail.specifications': '商品规格',
  'detail.top.selling.products': '畅销商品',
  'detail.recommended.for.you': '为你推荐',
  'detail.sold': '销量',
  'detail.receipt': '确认收货',
  'detail.receipt.title': '确定该订单收货?',
  'detail.receipt.content': '确认后,该订单交易完成',
  'detail.comment': '评论',

  // confirm
  'confirm.shipping.address': '收件地址',
  'confirm.change': '改变',
  'confirm.payment.methods': '支付方式',
  'confirm.summary': '结算',
  'confirm.total.item.costs': '商品合计',
  'confirm.total': '总计',
  'confirm.checkout': '去结算',
  'confirm.place.order': '立即下单',
  'confirm.pay.now': '立即支付',
  'confirm.order.desc': "单击'下订单'后，我确认我已阅读并确认",
  'confirm.order.policy': '所有条款和政策',
  'confirm.payment': '全球购物确保您的信息和付款安全',

  // address
  'address.title': '收件地址',
  'address.default': '默认',
  'address.edit': '编辑',
  'address.delete': '删除',
  'address.new': '创建新地址',
  'address.contact': '联系人',
  'address.address': '地址',
  'address.phone': '电话号码',
  'address.set.default': '设置默认',
  'address.confirm': '确认',
  'address.cancel': '取消',
  'address.del.title': '确认删除地址?',
  'address.del.content': '您确定要删除此送货地址吗?',

  'payment.method': '支付方式',

  // Shopping Cart
  'shopping.title': '购物车',
  'shopping.back.buy': '返回特价购物',
  'shopping.start.shopping': '开始购物',

  // member
  'member.account': '个人中心',
  'member.overview': '概述',
  'member.orders': '订单',
  'member.payment': '我的钱包',
  'member.address': '收货地址',
  'member.wishlist': '喜欢列表',
  'member.followlist': '关注列表',
  'member.message.center': '消息中心',
  'member.setting': '个人设置',
  'member.shop.info': '商铺信息',
  'member.shop.setting': '商铺设置',
  'member.order.notify': '新订单通知',
  'member.order.tips': '通过发送邮件到商家账号邮箱',

  // member.order
  'member.order.title': '我的订单',
  'member.order.view.all': '查看全部',
  'member.order.all': '全部',
  'member.order.unpaid': '未支付',
  'member.order.paid': '已支付',
  'member.order.procurement': '待采购',
  'member.order.seller.paid': '已采购',
  'member.order.processing': '待处理',
  'member.order.shipped': '配送中',
  'member.order.return': '退货中',
  'member.order.completed': '已完成',
  'member.order.refund': '已退款',
  'member.order.all.time': '全部',
  'member.order.empty': '暂无订单',
  'member.order.date': '日期',
  'member.order.purchase.date': '采购',
  'member.order.cpoy': '复制',
  'member.order.id': '订单',
  'member.order.detail': '订单详情',
  'member.order.logistics': '物流信息',

  // member.payment
  'member.payment.title': '我的钱包',
  'member.wallet.balance': '钱包余额',
  'member.crypto.recharge': '充值',
  'member.crypto.withdrawal': '提现',
  'member.crypto.bank': '银行卡',
  'member.wallet.record': '存币记录',
  'member.bankcard.record': '银行卡记录',
  'member.withdrawal.record': '提现记录',
  'member.income.record': '收益记录',
  'member.transaction.record': '交易记录',
  'member.wallet.freeze': '冻结资金',
  'member.wallet.profit': '预计收益',

  //recharge
  'recharge.currency': '币种',
  'recharge.protocol': '协议',
  'recharge.qrcode': '二维码',
  'recharge.address': '钱包地址',
  'recharge.copy': '复制地址',
  'recharge.ok.text': '确认',
  'recharge.cancel.text': '取消',

  //bank
  'bank.recharge.title': '银行卡充值',
  'bank.title': '银行名称',
  'bank.name': '姓名',
  'bank.account': '账户',
  'bank.routing': '路由',
  'bank.code': '代码',
  'bank.bankname': '银行名称',
  'bank.bankaddress': '银行地址',
  'bank.ok.text': '下一步',
  'bank.ok.prev': '上一步',
  'bank.submit': '立即提交',
  'bank.amount': '充值金额',
  'bank.amount.desc': '请输入充值金额',
  'bank.type': '类型',
  'bank.usd': '美元',
  'bank.eur': '欧元',
  'bank.receipt.number': '回执单号',
  'bank.receipt.number.desc': '请输入交易回执单号',
  'bank.credential.picture': '凭证图片',
  'bank.credential.picture.desc': '请上传凭证图片',
  'bank.remark': '说明',
  'bank.upload': '上传图片',
  'bank.text.title': '注意事项',
  'bank.text.t1': '账不要任何备注,不填',
  'bank.text.t2': '香港户国家选择香港,不要加中国',
  'bank.text.t3': '美国汇款,工作日,下午三点前汇欧洲汇款,银行上班时间可汇!',
  'bank.text.t4': '到款时间T+1,欧洲最迟T+3到款',
  'bank.text.t5': '转款之前告知平台,确定账户是否可用,如果封户，不赔付.',
  'bank.text.t6': '通过银行卡入金一切已客服信息为准.',

  //banklist
  'bank.list.title': '银行名称',
  'bank.list.code': '银行代码',
  'bank.list.amount': '转账金额',
  'bank.list.number': '回执单号',
  'bank.list.img': '凭证图片',
  'bank.list.status': '状态',
  'bank.list.time': '时间',
  'bank.list.status1': '审核中',
  'bank.list.status2': '已通过',
  'bank.list.status3': '已驳回',

  // Withdrawal
  'withdrawal.address.desc': '请输入钱包地址!',
  'withdrawal.number': '数量',
  'withdrawal.real.number': '实际到账',
  'withdrawal.number.desc': '请输入提现数量!',
  'withdrawal.btn.all': '全部',
  'withdrawal.balance': '余额',
  'withdrawal.commission': '佣金',
  'withdrawal.actual.amount': '到账',
  'withdrawal.notice': '提示',
  'withdrawal.notice.text': '转账前,请确认收款地址信息正确无误.资产一经转出,不可退回.',
  'withdrawal.notice.content': '{name}({cp_name}) 佣金:当前市值{fee_rate}%/笔,最低标准:{fee_min} {name}/笔',
  'withdrawal.submit': '提交',
  'withdrawal.choice': '选择加密货币',
  'withdrawal.yzm': '验证码',
  'withdrawal.fs': '发送',
  'withdrawal.qsryzm': '请输入邮箱验证码',

  // recive
  'recive.method': '协议',
  'recive.amount': '金额',
  'recive.address': '地址',
  'recive.date': '时间',
  'recive.status': '状态',
  'recive.create.at': '交易时间',
  'recive.type': '类型',
  'recive.befor': '交易之前',
  'recive.balance': '余额',
  'recive.freeze': '冻结',
  'recive.review': '审核中',
  'recive.success': '已通过',
  'recive.reject': '已驳回',

  // advertise
  'advertise.title': '推广中心',
  'advertise.shop.title': '店铺推广',
  'advertise.shop.status': '状态',
  'advertise.shop.expired': '已过期',
  'advertise.shop.promotion': '推广中',
  'advertise.shop.expire.date': '到期时间',
  'advertise.shop.renew': '广告续费',
  'advertise.shop.payable': '应付金额',
  'advertise.shop.explanation': '推广说明',
  'advertise.shop.text': '参与平台广告推广，提升店铺曝光度，促进交易订单量',
  'advertise.shop.paynow': '立即续费',
  'advertise.shop.modal.title': '确认支付',
  'advertise.shop.modal.desc': '确认为支付推广费用',
  'advertise.shop.modal.btn': '确认支付',

  //Wish List
  'wishlist.title': '喜欢列表',
  'wishlist.delete': '删除',
  'wishlist.orders': '销量',

  //Follow List
  'followlist.title': '关注列表',
  'followlist.delete': '删除',
  'followlist.follow': '关注',

  // store
  'store.dashboard': '仪表盘',
  'store.products': '产品',
  'store.products.list': '产品列表',
  'store.products.reviews': '产品回复',
  'store.orders': '订单',
  'store.wallet': '钱包',
  'store.message': '消息中心',
  'store.setting': '设置',
  'store.order.total.profit': '预计营业总收益',

  //dashboard
  'dashboard.store.hour.views': '实时访问量',
  'dashboard.store.today.views': '今日访问量',
  'dashboard.product.total': '产品总量',
  'dashboard.product.today': '今日新增',
  'dashboard.order.total': '订单总量',
  'dashboard.sales.total': '预计营业额',
  'dashboard.sales.real': '实际营业额',
  'dashboard.sales.pay': '总支出',
  'dashboard.sales.profit': '总收益',
  'dashboard.commission.total': '总收益',
  'dashboard.commission.today': '今日收益',
  'dashboard.order.sales': '商品销售量',
  'dashboard.sales.list': '销售排行',
  'dashboard.goods.cate.rate': '商品分类占比',
  'dashboard.goods.wish': '喜欢商品排行',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // products
  'products.add.new': '添加新产品',
  'products.add.from.warehouse': '从仓库添加产品',
  'products.delete': '删除',
  'products.add': '添加',
  'products.table.img': '图片',
  'products.table.name': '商品名称',
  'products.table.category': '所属分类',
  'products.table.wish': '喜欢',
  'products.table.stock': '库存',
  'products.table.price': '价格',
  'products.table.profit': '收益',
  'products.table.action': '操作',
  'products.search.category': '按分类搜索',
  'products.back.product': '返回产品列表',
  'products.total': '商品总计',
  'products.yes': '是的',
  'products.no': '取消',
  'products.batch.add': '批量增加',
  'products.ask.add': '确定要增加商品吗?',
  'products.batch.delete': '批量删除',
  'products.ask.delete': '确定要删除商品吗?',
  'products.top': '置顶',
  'products.syzd': '首页置顶',
  'products.zdwz': '置顶位置',
  'products.t1': '推广',
  'products.t2': '优选',
  'products.t3': '精品',
  'products.t4': '无',

  // reviews
  'reviews.title': '产品评论',
  'reviews.product.name': '商品',
  'reviews.user.name': '用户',
  'reviews.star': '评分',
  'reviews.comment': '评论内容',
  'reviews.sku': '商品规格',
  'reviews.imgs': '图集',
  'reviews.created': '日期',

  // store.order
  'store.order.purchase': '立即采购',
  'store.order.purchase.desc': '确定要付款采购该产品?',
  'store.order.purchase.yes': '立即支付',
  'store.order.purchase.no': '取消',
  'store.order.desc': '采购该订单需要支付80%的货款，订单完成后获得收益。 ',
  'store.order.no': '订单号',
  'store.order.number': '数量',
  'store.order.buyer': '买家',
  'store.order.total': '总金额',
  'store.order.will.earning': '收入',
  'store.order.profit': '收益',
  'store.order.dividends': '分红',
  'store.order.payment.status': '支付状态',
  'store.order.seller.buy.status': '采购状态',
  'store.order.status': '订单状态',
  'store.order.date': '订单日期',
  'store.order.purchase.date': '采购日期',
  'store.order.action': '操作',
  'store.order.purchase.amount': '采购金额',

  //income
  'income.create.at': '记录时间',
  'income.order.sn': '订单编号',
  'income.realpay': '订单金额',
  'income.profit': '收益',

  //Setting
  'setting.avatar': '商家头像',
  'setting.upload': '上传',
  'setting.shop.name': '店铺名称',
  'setting.shop.phone': '联系电话',
  'setting.shop.address': '店铺地址',
  'setting.shop.save': '保存',
  'setting.upload.pic': '上传图片',
  'setting.send.pic': '发送图片',

  //beseller
  'beseller.title': '申请成为商家',
  'beseller.account': '账号信息',
  'beseller.store': '店铺信息',
  'beseller.store.name': '店铺名称',
  'beseller.store.address': '店铺地址',

  //savehome
  'store.home.title': '首页设置',
  'store.home.topimg': '顶部图',
  'store.home.banner': '轮播图',
  'store.home.up3': '备注：最少上传3张',
  'store.upload.more': '上传多图',
  'store.home.columns': '栏目',
  'store.home.bgimg': '背景图片',
  'store.goods.remark': '备注：每行5个商品，每个板块最多10个商品',
  'store.home.select': '选择商品',
  'store.home.add': '增加栏目',

  //个人信息
  'setting.update.user': '个人信息',
  'setting.update.info': '修改信息',
  'setting.user.avatar': '用户头像',
  'setting.user.nickname': '用户昵称',
  'setting.user.nickname.desc': '请输入用户昵称',

  'setting.safe.title': '安全信息',
  'setting.user.passwd': '修改密码',
  'setting.passwd.title1': '旧密码',
  'setting.passwd.desc1': '请输入旧密码',
  'setting.passwd.title2': '新密码',
  'setting.passwd.desc2': '请输入新密码',
  'setting.passwd.title3': '确认密码',
  'setting.passwd.desc3': '请输入确认密码',

  'setting.invitation': '邀请朋友',
  'setting.copy': '复制',

  'adv.add.products': '增加推广商品',
  'adv.list.title': '商品推广',
  'adv.type': '推广类型',
  'adv.begin.time': '推广时间',
  'adv.end.time': '结束时间',
  'adv.status': '状态',
  'adv.status.s1': '推广中',
  'adv.status.s2': '已结束',
  'adv.add.back': '返回已推广列表',
  'adv.recharge': '充值推广点数',
  'adv.select.goods': '选择商品',
  'adv.select.btn': '推广商品',
  'adv.recharge.title': '充值推广点数',
  'adv.recharge.balance': '点数余额',
  'adv.point': '点',
  'adv.point.rate': '点数比例',
  'adv.recharge.amount': '充值金额',
  'adv.recharge.input.amount': '输入充值金额',
  'adv.select.mod': '选择板块',
  'adv.mod1': '推荐栏目',
  'adv.mod2': '优选栏目',
  'adv.mod3': '热销栏目',
  'adv.mod4': '精品列表',
  'adv.mod5': '分类列表',
  'adv.mod6': '详情推广',
  'adv.ge': '数量',
  'adv.xs': '小时',
  'adv.xzbk': '选择板块',
  'adv.syme': '剩余名额',
  'adv.tgsc': '推广时长',
  'adv.tgjg': '推广价格',
  'adv.syye': '当前余额',
  'adv.tgye': '推广余额',
  'adv.yj1': '预计 ',
  'adv.yj2': '开始推广',
  'adv.pay.point': '消费点数',

  'wallet.ykcsxf': '已扣除手续费',
  'wallet.sxf': '手续费',
  'wallet.zxje': '最小金额',
  'wallet.24hxe': '24小时限额',
  'wallet.desc.text': '官方在任何情况下都不会要求您向某一账户转账，也不会向您索要验证码。请勿参与代买，洗钱、非法集资等违法行为，谨防网络诈骗',
  'wallet.cunbi.text': '您只能向此地址充入{name},充入其他资产将无法找回',

  'credit.title': '信用额度',
  'credit.ed': '额度',
  'credit.hk': '还款',
  'credit.kyye': '可用余额',
  'credit.dqqk': '当前欠款',
  'credit.hkje': '还款金额',
  'credit.hkje.desc': '请输入还款金额',
  'credit.cgje': '采购金额',
  'credit.qrz': '去认证',
  'credit.srxyj': '输入信用支付金额',

  'store.gzl': '关注量',
  'store.wxz': '无限制',

  'auth.smrz': '实名认证',
  'auth.rzlx': '类型',
  'auth.sfzm': '身份证正面',
  'auth.sffm': '身份证反面',
  'auth.zsxm': '真实姓名',
  'auth.zjhm': '证件号码',
  'auth.yyzz': '营业执照',
  'auth.gsmc': '公司名称',
  'auth.shz': '审核中',
  'auth.ytg': '已通过',
  'auth.ybh': '已驳回',
  'auth.zt': '状态',
  'auth.gr': '个人',
  'auth.gs': '企业',
  'auth.ljtj': '申请认证',
  'auth.wrz': '未认证',

  'credit.qts': '查看说明',
  'store.spss': '商品上限',
  'store.dpfh': '店铺分红',
  'store.qydj': '权益等级',
  'store.level': '级',
  'store.jian': '件',

  'order.notify.email': '由于邮箱风控,平台订单提醒邮件发送频繁会被拦截,为了及时收到,建议将{e}添加到登录邮箱白名单',

  'setting.sjxx': '商家信息',
  'setting.smrz': '实名认证',
  'setting.aqzx': '安全中心',
  'setting.tzxx': '通知信息',
  'setting.dpzx': '装修首页',
  'setting.sjqy': '商家权益',
  'setting.sjdjqy': '商家等级权益说明',

  'setting.fhbky': '当前分红不可用',
  'setting.spgzyzy': '商铺规则与指引',

  'desc.dqqy': '当前权益',
  'desc.xjqy': '下级权益',
  'desc.sjqy_0': '店铺信用额度',
  'desc.sjqy_1': '店铺分红',
  'desc.sjqy_2': '可发布商品数量',

  'share.yqlb': '邀请列表',
  'share.zmj': '总卖家',
  'share.zdd': '总订单',
  'share.zsy': '经纪总收益',
  'share.wcl': '未处理',
  'share.ycl': '已处理',
  'share.ywc': '已完成',
  'share.dpmc': '店铺名称',
  'share.ddl': '订单量',
  'share.jjsy': '经纪收益',
  'share.zts': '总条数',

  'chat.state': '状态',
  'chat.zx': '在线',
  'chat.ljz': '连接中',

  'bind.wallet.title': '提币地址',
  'bind.address.title': '添加地址',
  'bind.bj': '编辑',
  'bind.sc': '删除',
  'bind.qrsc': '确定要删除该地址?',
  'bind.qbdz': '钱包地址',
  'bind.tjsj': '添加时间',
  'bind.cz': '操作',
  'withdrawal.address.choice': '请选择提现地址!',

  'order.sxsj': '生效时间',
  'order.ljsj': '立即生效',
  'order.dssj': '定时生效',

  'store.st.zt': '店铺状态',
  'store.st.zc': '正常',
  'store.st.zc.desc': '您的商铺一切正常，请继续保持',
  'store.st.yc': '异常',
  'store.st.yc.desc': '您的商铺存在部分的订单异常或收到客诉',
  'store.st.dj': '冻结',
  'store.st.dj.desc': '您的商铺存在一定的经营风险造成资金冻结',
  'store.st.jy': '禁用',
  'store.st.jy.desc': '您的商铺违返了平台相关协议与规则已禁用',

  'task.sy': '首页',
  'task.rwlbo': '任务列表',
  'task.rwxqo': '任务详情',
  'task.ljcy': '立即参与',
  'task.gdrw': '更多任务',
  'task.rwlb': '抢单任务列表',
  'task.qbrw': '全部任务',
  'task.wcyd': '我参与的',
  'task.qdz': '抢单中',
  'task.ywc': '已完成',
  'task.djy': '待交易',
  'task.ddje': '订单金额',
  'task.cyrs': '参与人数',
  'task.ewsy': '额外收益',
  'task.jzsj': '截止时间',
  'task.ckxq': '查看详情',
  'task.rwxq': '任务详情',
  'task.cylb': '参与列表',
  'task.qdcyrw': '确定要参与此订单任务吗?',
  'task.sd': '是的',
  'task.bue': '不',

  'task.wdsy': '收益',
  'task.wdfh': '分红',
  'task.wdqd': '抢单',
  'task.dd.leixin': '订单类型',
  'task.dd.qdrw': '抢单任务',
  'task.dd.ptdd': '普通订单',

  'flow.lltg': '流量推广',
  'flow.gmll': '购买流量套餐',
  'flow.dqtc': '当前套餐',
  'flow.tc1': '套餐',
  'flow.sy2': '剩余',
  'flow.tian3': '天',
  'flow.jlzs': '记录总数',
  'flow.lltc': '流量套餐',
  'flow.ll': '流量',
  'flow.gmsj': '购买时间',
  'flow.tcjg': '套餐价格',
  'flow.sjzf': '实际支付',
  'flow.tcsm': '套餐说明',
  'flow.tcsm.sm': '购买此套餐，将增加店铺的曝光率，获得更大的客户访问流量。以此来获得更多订单，提高店铺收益。',

  'flow.tcmc': '套餐名称',
  'flow.gmsc': '购买时长',
  'flow.zje': '总金额',
  'flow.jzsj': '截止时间',
  'flow.gmrq': '购买日期',
  'flow.tian1': '天',
  'flow.zhou1': '周',
  'flow.yue1': '月',

  'share.dj': '等级',
  'share.hz': '划转',
  'share.hzd': '划转到',
  'share.qbye': '钱包余额',
  'share.tgje': '推广金额',
  'share.hzje': '划转金额',
  'share.qsrhzje': '请输入划转金额',

  '_inv._title': '邀请说明',
  '_inv._t1': '1.邀请与被邀请关系',
  '_inv._desc1': 'A邀请B、B邀请C、C邀请D。 最高4级,若D再邀请E, 则E与A无关。',
  '_inv._t2': '2.邀请人获得奖励',
  '_inv._desc2': 'A获得B订单完成总金额的4%，A获得C订单完成的总金额2%，A获得D订单完成的总金额1%',

  '_index._gm': '购买',
  '_index._sm': '说明：增加店铺曝光率，吸引客流量',

  '_shop._update': '{n}次/月，当月已修改{m}次',

  '_chat._hc': '撤回',

  '_footer._suom': '政策说明',

  '_footer._platform._qualification': '公司资质',
  '_store._ptzz': 'Sears Global 平台相关资质',

  '_abt._gywm': '关于我们',
  '_abt._syms': '商业模式',
  '_abt._qyzr': '企业责任',
  '_abt._lxwm': '联系我们',

  '_wsj._wsjms': '在我们诡异的万圣节商店里以时尚的方式庆祝万圣节。无论您想在这个诡异的季节进行恶作剧还是款待，我们的万圣节服装、装饰和配件系列都能为全家人留下一些东西。用我们的女装和男装系列打造令人脊背发凉的宣言，或是用化装服装释放孩子的想像。从令人难忘的可爱家居用品和玩具，到迷人的美容和化妆品，找到完美的单品来庆祝一年中最令人兴奋的节日。 ',

  '_wsj._b1': '秋季家居装饰',
  '_wsj._b2': '南瓜',
  '_wsj._b3': '家',
  '_wsj._b4': '儿童的',
  '_wsj._b5': '宝贝的',
  '_wsj._b6': '女式',
  '_wsj._b7': '化装舞会',
  '_wsj._b8': '个人化',

  '_wsj._cj': '参加',
  '_wsj._gz': '活动规则',
  '_wsj._flss': '分类搜索',
  '_wsj._wsnz': '万圣节女装',
  '_wsj._wsn': '万圣节男装',
  '_wsj._wst': '万圣节童装',
  '_wsj._wszs': '万圣节装饰',
  '_wsj._wslp': '万圣节礼品',

  '_wsj._desc1': '“万圣节特惠，不给糖就捣蛋！” 这个十月，我们为你准备了一场狂欢派对！快来抢购各种恐怖艳惊的商品，大打折扣等你来抢！',
  '_wsj._lkm': '去购物',
  '_wsj._wsj': '万圣节',

  '_wsj._wsjjz': '万圣节即将到来',
  '_wsj._mzbt': '美容降临日历',
  '_wsj._mznr': '一年中最美好的时光的倒计时刚刚到来，降临节日历适合每个人。',

  '_chr._tt1': '圣诞节期间购物',
  '_chr._tt2': '圣诞装饰品',
  '_chr._tt3': '购买所有礼物',
  '_chr._tt4': '购买所有圣诞睡衣',

  '_chr._dt1': '圣诞节前夕和重要的日子一样神奇。 所以，整理好你的礼物，装饰好你的大厅，并准备好相配的睡衣，迎接圣诞节的魔力。',

  '_chr._dtt1': '圣诞服装',
  '_chr._dtt2': '匹配家庭圣诞节',
  '_chr._dtt3': '降临日历',
  '_chr._dtt4': '在家过圣诞节',
  '_chr._dtt5': '舒适的商店',
  '_chr._dtt6': '圣诞节的家具',

  '_chr._tre1': '现在流行',
  '_chr._tre2': '用每个人都喜欢的趋势为您的家增添一点节日乐趣。',
  '_chr._tre3': '姜饼',
  '_chr._tre4': '圣诞布丁',
  '_chr._tre5': '圣诞节贡克斯',

  '_chr._ttre1': '卡片和包装纸',
  '_chr._ttre2': '玩具和游戏',
  '_chr._ttre3': '食品和饮料礼物',
  '_chr._ttre4': '袜子填充物',
  '_chr._ttre5': '秘密圣诞老人',
  '_chr._ttre6': '宝宝的第一个圣诞节',
  '_chr._ttre7': '美丽礼物',
  '_chr._ttre8': '高级礼物',
  '_chr._ttre9': '个性化礼物',

  '_sdj._sdnz': '女装',
  '_sdj._sdn': '男装',
  '_sdj._sdetlw': '礼物',
  '_sdj._sdzs': '装饰',
  '_sdj._sdjj': '玩具',

  '_sdj._title1': '圣诞节即将来临',
  '_sdj._desc1': '「圣诞节：给予与感恩的季节。」 今年十二月，我们为你准备了一场亮丽装饰，温馨家庭的派对！来抢购各种亮丽温馨的商品，大打折扣等你来抢！ ',

  '_ssd._lhpp': '灵活支付',
  '_ssd._lzhq': '来源环球',
  '_ssd._pwyx': '评为优秀',
  '_ssd._qsth': '轻松退货',
  '_ssd._jgppcn': '价格匹配与承诺',
  '_ssd._ztbt': '打造全球一站式购物',
  '_ssd._yzspl': '直接从 8,000 多个独立品牌采购库存',
  '_ssd._zcgm': '注册购买',
  '_ssd._zccs': '注册出售',

  '_ssd._dlwz': '登录注册',

  '_ssd._qjrgwc': '全加入购物车',

  '_zddy._gzlb': '关注列表',
  '_zddy._zsersxs': '在Searsglobal销售',
  '_zddy._zccwsj': '注册成为商家',
  '_zddy._dlyh': '登录',
  '_zddy._zcyh': '注册',
  '_zddy._xyh': '新用户',
  '_zddy._gwc': '购物车',
  '_zddy._znxx': '消息',
  '_zddy._zshy': '最受欢迎',
  '_zddy._ddl': '订单',
  '_zddy._mysj': '没有数据',
  '_zddy._ckgd': '查看更多',
  '_zddy._tjsp': '推荐商品',
  '_zddy._rmsp': '热门产品、新趋势、更多采购机会',
  '_zddy._xsp': '新商品',
  '_zddy._jlzzr': '近两周最热商品',
  '_zddy._ljgm': '立即购买',
  '_zddy._gdsp': '更多热门商品',
  '_zddy._ssbt': '搜索',
  '_zddy._ssgjz': '我在找..',

  '_zddy._bdhb': '本地货币',
  '_zddy._xzbdhb': '选择本地货币',
  '_zddy._zje': '总金额',
  '_zddy._xzzffs': '请选择适合您的支付方式',
  '_zddy._jmhb': '加密货币',

  '_zddy._cp': '产品',
  '_zddy._sj': '商家',
  '_zddy._mj': '卖家',
  '_zddy._fhsy': '返回首页',

  '_sjsy._ddpl': '订单列表',
  '_yhqd._czbz': '充值之前请联系专人客服确认充值信息',
  '_yhqd._lxkf': '联系客服',
  '_yhqd._qsrje': '请输入充值金额',
  '_yhqd._wrhyhtd': '无任何银行通道',
  '_yhqd._yhzz': '银行转账',
  '_yhbt._yhlx': '银行类型',
  '_zcyz._sjdxyz': '手机短信验证',
  '_zcyz._sjhm': '手机号码',
  '_zcyz._srsjhm': '输入手机号码',
  '_zzsd._qsrzzje': '请输入转账金额',
  '_zzsd._zzje': '转账金额',

  '_txcd._qsrtxje': '请输入提现金额',
  '_txcd._skr': '收款人',
  '_txcd._skr._ms': '请输入持卡人名称',
  '_txcd._skzh': '收款账号',
  '_txcd._skzh._ms': '请输入收款账号',
  '_txcd._tjyhk': '添加银行卡',
  '_txcd._txje': '提现金额',
  '_txcd._wdyhdz': '银行地址',
  '_txcd._yhdm': '代码',
  '_txcd._yhdm._ms': '请输入银行代码',
  '_txcd._yhdz': '地址',
  '_txcd._yhdz._ms': '请输入银行地址',
  '_txcd._yhlx': '类型',
  '_txcd._yhlx._ms': '请输入银行卡类型',
  '_txcd._yhly': '路由',
  '_txcd._yhly._ms': '请输入银行路由',
  '_txcd._yhmc': '银行',
  '_txcd._yhmc._ms': '请输入银行名称',
  '_txcd._yhtx': '银行提现',

  '_jys._gmjmhb': '购买加密货币',
  '_jys._gmzy': '您可以通过前往以下交易所，根据您当前国家的政策，注册账号并购买加密货币。然后充值到平台账户！',

  '_kdydj._bt': '开店赢大奖',
  '_kdydj._xyzbt': '幸运转不停',
  '_kdydj._yx': '抽{n}次',
  '_kdydj._xh': '消耗{n}积分',

  '_kdydj._jf': '积分:',
  '_kdydj._zjjl': '中奖记录',

  '_kdydj._hdgz': '活动规则',
  '_kdydj._qx': '请',
  '_kdydj._dl': '登录',

  '_kdydj._mjdl': '卖家登录',
  '_kdydj._yxh': '邮箱',
  '_kdydj._yxhsm': '请输入邮箱',
  '_kdydj._yxhmm': '请输入密码',
  '_kdydj._rgmyzh': '如果您没有账号',
  '_kdydj._djzc': '点击注册',
  '_kdydj._wjmm': '忘记密码',

  '_kdydj._wdjp': '我的奖品',
  '_kdydj._zjts': '中奖提示',
  '_kdydj._jx': '继续',
  '_kdydj._czl': '抽中了',

  '_kdydj._ljjl': '累计奖励',
  '_kdydj._ycy': '已参与: {n}次',
  '_kdydj._cy': '参与{n}次',
  '_kdydj._lq': '领取',
  '_kdydj._ylq': '已领取',
  '_kdydj._wdd': '未达到',

  '_dk._dkbt1': '创业贷款 解决借钱的烦恼',
  '_dk._dkbt2': '提供创业贷款 资金周转服务',
  '_dk._dkbt3': '为你解决资金紧张，无处借钱，不好意思向朋友开口借钱等贷款难题，全程专业人员 服务，随时解答你的疑惑不解，我们的目标是让每一笔贷款都透明化，让客户快捷放心的使用。',
  '_dk._zxsq': '在线申请',
  '_dk._wddk': '我的贷款',
  '_dk._aqwy': '安全无忧',
  '_dk._lhed': '灵活额度',
  '_dk._lhedb': '额度: 5,00至500,000，利息低 还款方式灵活，无任何前期费用，线上放款，不打审核电话，信息保密， 当天放款。',
  '_dk._jsfk': '急速放款',
  '_dk._jsfkb': '最快一小时通过审核，审核通过立即下款。',
  '_dk._aqww1': '安全无忧',
  '_dk._aqww1b': '无抵押贷款，无需押金，不打回访电话， 这本就是一件私密的事。',

  '_dk._gywm': '关于我们',
  '_dk._gywm1': '我们致力于贷款金融服务，基于“客户至上”的经营理念，以解决客户资金周转问题为使命，公司从成立至今，已帮助无数留客户解决资金周转问题。',
  '_dk._gywm2': '额度: 500至500,000，利息低 还款方式灵活，无任何前期费用，线上放款，不打审核电话，信息保密， 当天放款。',
  '_dk._gywm3': '不想向同学朋友开口借钱，请找我们。',
  '_dk._gywm4': '致力于创业者贷款服务',
  '_dk._gywm5': '解决您的资金困扰',
  '_dk._hzhb': '合作伙伴 - 在线借贷平台',
  '_dk._smwda': 'SearsGlobal Business全球站的用户来自全球112个国家，使用加密货币USDT/ETH/BTC/TRX进行结算。加密货币是一种无国界的交易方式，可以在全球范围内实现即时低成本交易，无需等待，没有国际费用。',

  '_dk._yjwa': 'SearsGlobal Business©有限公司2022-2024 版权所有',
  '_dk._yjwab': 'Sears Holdings Global Sourcing Limited (0008945)作为信贷经纪人,获得金融行为监管局的授权和监管，并专门与贷方 Home Retail Group CardServices Limited 合作，以 SearsGlobal  Business Financial Services的身份进行交易，SearsGlobal Business Financial Services获得金融行为监管局的授权和监管以提供信贷。Home Retail GroupCard Services Limited 注册于英国 (04007072)，地址为 Avebury, 489-499Avebury Boulevard, Milton Keynes, United Kingdom。MK9 2NW。',

  '_dk._cyzjsq': '创业贷款申请填写',
  '_dk._dkll': '贷款利率',
  '_dk._dkll2': '贷款利率说明 (点击显示)',
  '_dk._gs1': '公式',
  '_dk._gs2': '利息',
  '_dk._gs3': '贷款金额',
  '_dk._gs4': '年利率',
  '_dk._gs5': '贷款天数',
  '_dk._gs6': '例如',
  '_dk._gs7': '贷款金额',
  '_dk._gs8': '贷款周期',
  '_dk._gs9': '天',
  '_dk._fkfs': '放款方式',
  '_dk._zhye': '账户余额',
  '_dk._dkqx': '贷款期限',
  '_dk._dkqx1': '选择贷款期限',
  '_dk._sqje': '申请金额',
  '_dk._qsrdk': '请输入贷款',
  '_dk._dkje': '贷款金额',
  '_dk._gj': '国籍',
  '_dk._qxzgj': '请选择国籍',
  '_dk._sqr': '申请人',
  '_dk._qsrsqsm': '请输入申请人姓名',
  '_dk._zjhm': '证件号码',
  '_dk._qsrzjhm': '请输入证件号码',
  '_dk._zjzp': '证件照',
  '_dk._zjzp1': '请上传证件正面照片',
  '_dk._zjfm1': '请上传证件反面照片',
  '_dk._zjsc1': '请上传证件手持照片',
  '_dk._zjzp2': '选择图片',
  '_dk._pzslt': '拍照示例',
  '_dk._wytyb': '我已阅读并同意',
  '_dk._jkxy': '借款协议',
  '_dk._tjsq': '提交申请',

  '_dk._wddk2': '我的贷款',
  '_dk._ydk': '已贷款',
  '_dk._cslx': '产生利息',
  '_dk._yjhk': '预计还款',
  '_dk._dkjl': '贷款记录',
  '_dk._hkjl': '还款记录',
  '_dk._sqrq': '申请日期',
  '_dk._hkzq': '还款周期',
  '_dk._gdll': '固定利率',
  '_dk._hkz': '还款中',
  '_dk._yhk': '已还款',
  '_dk._sqz': '申请中',
  '_dk._ytg': '已通过',
  '_dk._ybh': '已驳回',
  '_dk._tqhk': '提前还款',

  '_dk._ljhk': '立即还款',
  '_dk._qrytqhk': '确认要提前申请还款吗',
  '_dk._qrytqhk1': '说明:申请成功后，通过存币的金额将会直接优先归还贷款',
  '_dk._dkxq': '贷款详情',
  '_dk._dkje1': '贷款金额',
  '_dk._yhlx': '应还利息',
  '_dk._hkze': '还款总额',
  '_dk._sqhkz': '申请还款中',
  '_dk._zxydbty': '请仔细阅读并勾选同意“借款协议”',
  '_dk._zzsqz': '申请中',
  '_dk._dksq': '信贷业务',

  '_dk._cs': '参数',
  '_dk._nllsm': '以百分比表示，例如：6.25%',
  '_dk._dktssm': '从贷款通过开始到结束的总天数',
  '_dk._yndts': '一年的天数： 通常取365天',
  '_dk._zlj': '滞纳金'
}
