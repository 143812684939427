<template>
  <div class="upload-box" @click="onOpenFile">
    <img v-if="img" :src="img + '?r=' + Math.random() * 1000" alt="avatar" width="126" />
    <div v-else class="upload-file">
      <loading-outlined v-if="loading"></loading-outlined>
      <div v-else>
        <img
          data-v-644b5cb6=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAoCAMAAACLrFl+AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAIFQTFRFAAAAqqqqqampqqqqqqqqqKioqampqampq6urqampqKioqKioqqqqp6enn5+fqqqqp6enqampqqqqp6enqqqqqampqqqqqqqqqqqq////qqqqqKior6+vqqqqtLS0qKioq6urr6+vqqqqqqqqqampqKioqampq6urq6urq6urqampXF4TtQAAACt0Uk5TAH/v/95Q/l9AgKCQbyAQv0Cen2AwvyHfYAGQcCCgEWFPEK/P396PMV+Avm+/muMAAAFYSURBVHicxdXhT4JAFADw98ASXKC1crIW2fyQ//9f09radGHRZoXCXKRJcAjcwXl3rjbvi2/jfrx3d48T4eCBxyOIWARrNYJaGeoYqxBKcE2TMIJnGqQmOKYiBgk5tYOxJTKsERuj5lw22zphSYfz9rrpBAzRFM7U/mSIzjxMeoixvjE/lImhL/Ogj3M1cuWXoYNvKmQwyx7bAF5mWi9y0iULML/TXcxOwo3nUkImVgRuPRnJk1DE9VBCbiZwly7/NCKNcjEDx5xKiBWAtSqrxA21aftIWg1LSI2ywg7Nkp2KBbD9StvwByAEZxVJyNmC/KTV6G2yY/13kJBs/TRxFrGUDJ9IsgGgHzJJBD02eqxippcFnTzyl0V4H1DdL/peLs8f8jnXdELxVwnJ8BW0Vu0GERPu+Dvpyq6x6tosSC/cP3c30AwZAifuRAiS8fPurUf84/t38gunRnQplBr8NwAAAABJRU5ErkJggg=="
          alt="camera"
        />
      </div>
      <!-- <plus-outlined v-else></plus-outlined> -->
      <!-- <div class="ant-upload-text" style="white-space: nowrap">{{ $t('bank.upload') }}</div> -->
    </div>
  </div>
  <input ref="files" type="file" accept="image/*" @change="onFile" hidden />
</template>

<script>
import { LoadingOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    // PlusOutlined,
    LoadingOutlined
  },
  emits: ['onUpload'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      img: ''
    }
  },
  created() {
    this.$watch('imageUrl', () => {
      this.img = this.imageUrl
      // console.log(this.img, this.imageUrl)
    })
  },
  methods: {
    onOpenFile() {
      if (this.disabled) {
        return
      }
      this.$refs.files.click()
    },
    onFile(file) {
      const files = file.target.files[0]
      console.log(files)

      if (files.size > 5 * 1024 * 1000) {
        this.$message.warning('Please upload images smaller than 5MB')
        return
      }

      let uploadFile = new FormData()
      uploadFile.append('file', files)

      this.loading = true
      this.$api.tools.file(uploadFile).then((res) => {
        this.loading = false
        this.img = res.data.url
        this.$emit('onUpload', res.data.url)
      })
    }
  }
}
</script>

<style>
.upload-box {
  width: auto;
  max-width: 300px;
  height: 100px;
  border: 1px #ccc dashed;
  border-radius: 5px;
}

.upload-box img {
  /* width: 100%; */
  height: 100%;
}

.upload-file {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
