<template>
  <div class="container">
    <a-card style="margin: 20px 0">
      <div class="docs-title">{{ docs.title }}</div>
      <div v-html="docs.content"></div>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'docsVue',
  data() {
    return {
      docs: {},
      key: ''
    }
  },
  watch: {
    $route(newRoute) {
      console.log(newRoute)
      this.$router.go(0)
    }
  },
  created() {
    this.key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    languageNotify(e) {
      console.log(e, 'docs lang')
      this.getDocs()
    },
    getDocs() {
      //获取文章
      let that = this
      this.$api.docs.getDocs({ key: this.key }).then((res) => {
        that.docs = res.data
      })
    }
  }
}
</script>

<style>
.docs-title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}
</style>
