function checkEmail(email) {
  const data = /^([a-zA-Z0-9]+[-_\\.]?)+@([a-zA-Z0-9]+[-_\\.]?)+[a-z]+$/;
  if (email && !data.test(email)) {
    return false;
  }
  return true;
}

function isLogin() {
  let token = localStorage.getItem("sp_token");
  if (!token) {
    return false;
  }
  return true;
}

function getInfo() {
  let sp_info = localStorage.getItem("sp_info");
  if (!sp_info) {
    return {
      nickname: "",
      type: 0,
    };
  }
  return JSON.parse(sp_info);
}

function setInfo(nickname = "", avatar = "") {
  let sp_info = getInfo();

  if (nickname) {
    sp_info.nickname = nickname;
  }
  if (avatar) {
    sp_info.avatar = avatar;
  }
  localStorage.setItem("sp_info", JSON.stringify(sp_info));
}

function setService(res) {
  let sp_info = getInfo();

  sp_info.whatsapp = res.whatsapp;
  sp_info.telegram = res.telegram;
  sp_info.line = res.line;
  sp_info.line_qrcode = res.line_qrcode;
  localStorage.setItem("sp_info", JSON.stringify(sp_info));
}

function getLocal(key) {
  let val = localStorage.getItem(key);
  return val;
}

function cutPrice(price) {
  return price.substring(price.indexOf(".") + 1, price.length);
}

export default {
  checkEmail,
  isLogin,
  getLocal,
  cutPrice,
  getInfo,
  setInfo,
  setService,
};
