<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreReceive" style="width:100%">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.key === 'img'">
        <div style="display:flex;align-items: center;">
          <img :src="record.img" width="40" style="margin-right:5px" />
          <div style="font-size:13px">{{record.name}}</div>
        </div>
      </template>

      <template v-if="record && column.key === 'status'">
        <a-tag :color="statusType(record.status).color">{{statusType(record.status).text}}</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
export default {
  name: "ReceiveList",
  data () {
    return {
      //存币记录表格
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          // sorter: true,
          width: "50px",
        },
        {
          title: this.$t('bank.list.number'),
          dataIndex: "number",
        },
        {
          title: this.$t('bank.list.amount'),
          dataIndex: "amount",
        },
        {
          title: this.$t('bank.list.title'),
          dataIndex: "bank_name",
        },
        {
          title: this.$t('bank.list.code'),
          dataIndex: "code",
        },
        {
          title: this.$t('bank.list.img'),
          key: "img"
        },
        {
          title: this.$t('bank.list.status'),
          key: "status"
        },
        {
          title: this.$t('bank.list.time'),
          dataIndex: "create_time",
        },
      ],
      status: {
        1: {
          text: this.$t('bank.list.status1'),
          color: 'orange'
        },
        2: {
          text: this.$t('bank.list.status2'),
          color: 'green'
        },
        3: {
          text: this.$t('bank.list.status3'),
          color: 'red'
        }
      },
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: "",
      },
    };
  },
  created () {
    this.getStoreReceive(this.pagination);
  },
  methods: {
    statusType (status) {
      return this.status[status]
    },
    getStoreReceive (parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize,
      };
      const requestParameters = Object.assign({}, pagination, this.queryParam);

      this.loading = true;

      let that = this;
      this.$api.store.getStoreBankReceive(requestParameters).then((res) => {
        that.loading = false;
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize,
        };
        that.dataSource = res.data.data;
      });
    },
  },
};
</script>

<style>
</style>