<template>
  <SvgVue></SvgVue>
  <div class="body-wrapper">
    <header class="header-box">
      <!-- 提示消息 -->
      <!-- <div class="top-wrapper-box on">
        <div class="container-box">
          <div class="top-box">
            <span>
              We use cookies to give you the best possible experience on our website. For more details including how to change your cookie settings, please read our
              <a href="https://www.globalsources.com/STM/help-faq/en/cookie-policy/" rel="nofollow" target="_blank"> Cookie Policy </a>.
            </span>
            <i class="iconfont ic_noticlose"></i>
          </div>
        </div>
      </div> -->

      <div class="container-box">
        <div data-fetch-key="data-v-97f6e7c8:0" class="nav-wrapper">
          <div class="nav-box">
            <ul class="nav-list">
              <!-- 客服列表 -->
              <li class="item" v-if="isLogin">
                <span class="tit">{{ $t('base.server') }} </span> <i class="iconfont ic_arrow_down_14"></i>
                <div class="nav-show">
                  <ul class="nav-show-list">
                    <li class="item">
                      <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''">
                        <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                          <IconWhatsApp />
                          WhatsApp
                        </div>
                      </a>
                    </li>
                    <li class="item">
                      <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''">
                        <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                          <IconTelegram />
                          Telegram
                        </div>
                      </a>
                    </li>
                    <li class="item">
                      <a v-if="sp_info.line" :href="sp_info.line" :target="sp_info.line ? '_blank' : ''">
                        <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                          <IconLine />
                          line
                        </div>
                      </a>
                      <div class="line-qrcode" v-else-if="sp_info.line_qrcode">
                        <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                          <IconLine />
                          Line
                          <div class="line-box">
                            <img :src="sp_info.line_qrcode + '?r=' + Math.random() * 1000" alt="line" />
                          </div>
                        </div>
                      </div>
                      <a v-else href="javascript:">
                        <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                          <IconLine />
                          Line
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- 语言列表 -->
              <li class="item">
                <div class="langs-box">
                  <div :class="'langs-img langs-' + language.lang"></div>
                  <span class="tit">{{ language.text }}</span>
                </div>
                <i class="iconfont ic_arrow_down_14"></i>
                <!-- <span class="news">New</span> -->
                <div class="nav-show">
                  <ul class="nav-show-list">
                    <li class="item" v-for="(vo, i) in list" :key="i" @click="onLang(vo)" style="padding: 0 20px">
                      <div class="langs-box">
                        <div :class="'langs-img langs-' + vo.lang"></div>
                        <span class="lang-item" style="padding: 0">
                          {{ vo.text }}
                          <!-- <span style="color: #e72528">●</span> -->
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="item">
                <span class="tit">App</span> <i class="iconfont ic_arrow_down_14"></i>
                <div class="nav-show" style="width: 300px">
                  <ul class="nav-show-list" style="height: auto">
                    <li class="item">
                      <div style="display: flex; gap: 10px">
                        <div>
                          <img src="@/assets/app_android.png" alt="" />
                        </div>
                        <div>
                          <img src="@/assets/app_ios.png" alt="" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="nav-right">
            <ul class="tools-list-box">
              <!-- <li class="item">
                <span class="sourcing-club"></span>
              </li> -->
              <li class="item" v-if="isLogin">
                <router-link :to="{ name: 'loan' }" class="gs-pp-link aItem ASDFEWQ">
                  <DollarCircleOutlined />
                  <span class="tit1">{{ $t('_dk._dksq') }}</span>
                </router-link>
              </li>
              <li class="item">
                <router-link :to="{ name: 'follow' }" class="aItem">
                  <i class="iconfont ic_favor_nor_24"></i>
                  <span class="tit">{{ $t('_zddy._gzlb') }}</span>
                </router-link>
              </li>
              <li class="item">
                <span class="tit">{{ $t('_zddy._zsersxs') }}</span>
                <i class="iconfont ic_arrow_down_14"></i>
                <div class="nav-show">
                  <ul class="nav-show-list">
                    <li class="item">
                      <router-link :to="{ name: 'beseller' }">
                        {{ $t('_zddy._zccwsj') }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="item">
                <div class="login-pop">
                  <div class="navbox" style="top: 62px">
                    <div class="nav-title toast-btn">
                      <a-badge :count="msgCenter.msg" :dot="true" v-if="isLogin">
                        <div class="sign">
                          <div class="signIn">{{ sp_info.nickname }}</div>
                          <i class="iconfont ic_arrow_down_14"></i>
                        </div>
                      </a-badge>
                      <template v-else>
                        <a class="base-a">
                          <div class="sign">
                            <!-- <i class="iconfont ic_account_nor"></i> -->
                            <user-outlined />&nbsp;
                            <div class="signIn">{{ $t('_zddy._dlyh') }}</div>
                          </div>
                        </a>
                        <span class="line">&nbsp;|&nbsp;</span>
                        <a class="base-a">
                          <span class="register">{{ $t('_zddy._zcyh') }}</span>
                        </a>
                      </template>

                      <!-- 弹出 -->
                      <div class="toast-box">
                        <div>
                          <div class="ant-popover nav-base-pop ant-popover-placement-bottomRight">
                            <div class="ant-popover-content">
                              <div class="ant-popover-arrow"></div>
                              <div role="tooltip" class="ant-popover-inner">
                                <div>
                                  <div class="ant-popover-inner-content">
                                    <div class="padding-box" v-if="!isLogin">
                                      <div class="sign-in-btn flex-center" @click="onSignin()">
                                        <a class="" rel="nofollow">{{ $t('_zddy._dlyh') }}</a>
                                      </div>
                                      <div class="register-tip flex-center">
                                        {{ $t('_zddy._xyh') }}?
                                        <a class="" rel="nofollow" @click="onSignup()">&nbsp;{{ $t('_zddy._zcyh') }}</a>
                                      </div>
                                    </div>
                                    <div class="pop-menu-item">
                                      <div class="pop-line" v-if="!isLogin"></div>
                                      <template v-if="sp_info.type == 1 || sp_info.type == 0">
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'overview' }" class="item">
                                            {{ $t('member.overview') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'orderAll' }" class="item">
                                            {{ $t('member.orders') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'payment' }" class="item">
                                            {{ $t('member.payment') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'address' }" class="item">
                                            {{ $t('member.address') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'wishlist' }" class="item">
                                            {{ $t('member.wishlist') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'follow' }" class="item">
                                            {{ $t('member.followlist') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'message' }" class="item">
                                            {{ $t('member.message.center') }}
                                            <span style="color: var(--main-color2)">({{ msgCenter.msg }})</span>
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'setting' }" class="item">
                                            {{ $t('member.setting') }}
                                          </router-link>
                                        </div>
                                      </template>
                                      <template v-if="sp_info.type == 2">
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeDashboard' }" class="item">
                                            {{ $t('store.dashboard') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeProductsList' }" class="item">
                                            {{ $t('store.products.list') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeOrderList' }" class="item">
                                            {{ $t('store.orders') }}
                                            <span style="color: var(--main-color2)">({{ msgCenter.order }})</span>
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeWallet' }" class="item">
                                            {{ $t('store.wallet') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeAdvertiseList' }" class="item">
                                            {{ $t('advertise.title') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeMessage' }" class="item">
                                            {{ $t('store.message') }}
                                            <span style="color: var(--main-color2)">({{ msgCenter.msg }})</span>
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeShare' }" class="item">
                                            {{ $t('setting.invitation') }}
                                          </router-link>
                                        </div>
                                        <div class="menu-item-box">
                                          <router-link :to="{ name: 'storeStore' }" class="item">
                                            {{ $t('store.setting') }}
                                          </router-link>
                                        </div>
                                      </template>
                                      <div class="menu-item-box">
                                        <a class="item" @click="onSignout()">
                                          {{ $t('signup.sign.out') }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="search-con">
          <div class="search-wrapper-box">
            <div class="container-box">
              <!-- LOGO -->
              <a href="/" aria-current="page" aria-label="globalsources.com" title="global sourcing" class="logo nuxt-link-exact-active nuxt-link-active">
                <img src="@/static/logo10.png" alt="globalsources logo" style="width: 100%" />
              </a>
              <!-- 搜索框 -->
              <div class="search-con">
                <div class="search-box-box">
                  <div class="search">
                    <div id="search-menu" class="search-menu">
                      <div ref="searchType" :class="['mod-dropdown', searchShow ? 'open' : '']">
                        <div class="tit-box" @click="onSearchShow()">
                          <span class="tit">{{ searchCurrent.title }}</span>
                          <i class="iconfont ic_arrow_down_14 ico-drop"></i>
                        </div>
                        <ul class="dropdown-list">
                          <li class="dItem" @click="onSearchType(i)" v-for="(item, i) in searchTypeList" :key="i">{{ item.title }}</li>
                        </ul>
                      </div>
                    </div>
                    <div id="search-inp" class="search-inp" style="display: flex; align-items: center; justify-content: space-between">
                      <input v-model="searchVal" @input="onInput()" autocomplete="on" type="search" maxlength="100" :placeholder="$t('_zddy._ssgjz')" class="inp" />
                      <div class="d-loading" v-if="searching">
                        <loading-outlined />
                      </div>
                      <div :class="['show-keyword', searchShop.length > 0 ? 'on' : '']">
                        <ul id="autocomplete-keyword-list" class="keyword-list" v-if="searchShop.length > 0">
                          <li class="item" v-for="(item, i) in searchShop" :key="i" @click="onShop(item)">
                            {{ item.name }} <strong>({{ item.sid }})</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="search-btn" class="search-btn" @click="onSearch()">{{ $t('_zddy._ssbt') }}</div>
                </div>
              </div>
              <!-- 右侧图标 -->
              <div class="search-right">
                <ul class="tools-box">
                  <li class="item">
                    <router-link :to="{ name: 'shopcart' }" class="aItem">
                      <i class="iconfont ic_cart_24 shopping-cart-icon"></i>
                      <span class="tit">{{ $t('_zddy._gwc') }}</span>
                    </router-link>
                  </li>
                  <li class="item order">
                    <a-badge :count="msgCenter.msg">
                      <router-link :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage' }">
                        <i class="iconfont ic_message_nor"></i>
                        <span class="tit">{{ $t('_zddy._znxx') }}</span>
                      </router-link>
                    </a-badge>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- 注册 -->
    <Signup ref="signup" v-show="isShowSignin" @onCloseSignin="onCloseSignin" @tokenStatus="tokenStatus"></Signup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
import { LoadingOutlined, UserOutlined, DollarCircleOutlined } from '@ant-design/icons-vue'
// import LoginState from './LoginState.vue'
import SvgVue from '../SvgVue.vue'
import Signup from '@/components/common/Signup.vue'
export default {
  name: 'indexVue',
  components: {
    SvgVue,
    Signup,
    LoadingOutlined,
    UserOutlined,
    DollarCircleOutlined
    // LoginState
  },
  // emits: ['tokenStatus', 'onSignout', 'notify'],
  setup() {
    const { proxy } = getCurrentInstance()
    function change(language) {
      proxy.$i18n.locale = language.lang
      setLangauge(language.lang)
      console.log('切换语言', language)
    }
    return { change }
  },
  data() {
    return {
      childList: [],
      childTop: [],
      currentMenu: -1,

      isShowSignin: false,

      //消息
      ajaxMsg: null,
      ajaxTime: 5000,
      // msgCenter: {
      //   msg: 0,
      //   order: 0,
      //   cart: 0
      // },

      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },
      list: this.$Const.LANGUAGE,

      //搜索
      searchShow: false,
      searchType: 0,
      searchTypeList: [
        {
          id: 1,
          title: this.$t('_zddy._cp')
        },
        {
          id: 2,
          title: this.$t('_zddy._sj')
        }
      ],
      searchCurrent: {
        id: 1,
        title: this.$t('_zddy._cp')
      },

      //搜索
      searchVal: '',
      searchShop: [],
      searching: false,

      sp_info: {},
      isLogin: false
    }
  },
  created() {
    if (this.$route.query.token) {
      let token = this.$route.query.token
      this.onAutoLogin(token)
    } else {
      this.tokenStatus()
    }

    let config = localStorage.getItem('config')
    try {
      if (config) {
        this.language = JSON.parse(config)
      }
    } catch (error) {
      //console.log(error);
    }

    //获取基本信息
    this.$store.dispatch('base/getBase', {})
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  mounted() {
    document.addEventListener('click', this.bodyCloseMenus)

    // 定时获取消息通知
    // this.runAjaxMsg()
  },
  beforeUnmount() {
    document.removeEventListener('click', this.bodyCloseMenus)
  },
  methods: {
    onAutoLogin(token) {
      let that = this
      localStorage.setItem(that.$Const.KEY_TOKEN, token)
      that.$api.auth.autoLogin({ token: token }).then((res) => {
        //存入缓存
        let info = res.data
        delete info.token
        localStorage.setItem(that.$Const.KEY_INFO, JSON.stringify(info))
        that.tokenStatus()
        setTimeout(function () {
          that.$router.push({ name: 'index' })
        }, 1000)
      })
    },
    /**
     * 异步循环获取消息
     */
    // clearAjax() {
    //   clearInterval(this.ajaxMsg)
    // },
    // async runAjaxMsg() {
    //   await this.$api.home.getBase().then((res) => {
    //     this.msgCenter = res.data
    //   })

    //   // let that = this
    //   // if (this.$socket.getReadyState() === 1) {
    //   //   this.$socket.send({ type: 'getBase', data: { method: 'headGetBase' } })

    //   //   this.$socket.on('headGetBase', function (data) {
    //   //     that.msgCenter = data
    //   //   })
    //   // }

    //   this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
    // },
    /**
     * 点击空白处
     */
    bodyCloseMenus(e) {
      //点击空白处
      console.log(!this.$refs.searchType.contains(e.target))
      if (this.searchShow && !this.$refs.searchType.contains(e.target)) {
        //任意点击都取消
        this.searchShow = false
      }
    },
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
      this.$emit('tokenStatus', [])
    },
    onLang(vo) {
      console.log(vo)
      this.language = vo
      this.change(this.language)
      localStorage.setItem(this.$Const.KEY_LANG, JSON.stringify(this.language))
      this.$router.go(0)
    },
    /**
     * 去登陆
     */
    onSignin() {
      this.$refs.signup.onInit(2)
      this.isShowSignin = true
    },
    /**
     * 去注册
     */
    onSignup() {
      this.$refs.signup.onInit(1)
      this.isShowSignin = true
    },
    /**
     * 关闭
     */
    onCloseSignin() {
      this.isShowSignin = false
    },

    /**
     * 搜索选项
     */
    onSearchShow() {
      this.searchShow = !this.searchShow
    },
    onSearchType(i) {
      this.searchType = i
      this.searchCurrent = this.searchTypeList[i]
      this.onSearchShow()
    },

    signinEvent() {
      //转调用HeadNav
      this.$refs.loginState.onSignup()
    },
    onSearch() {
      this.searchShop = []
      this.$router.push({ name: 'category', query: { id: 0, level: 0, keyword: this.searchVal } })
    },
    onInput() {
      //通知上级
      console.log(this.searchVal)
      // 搜索符合的店铺名称
      this.searching = true

      let that = this
      this.$api.home
        .searchShop({ search: this.searchVal })
        .then((res) => {
          that.searching = false
          that.searchShop = res.data
        })
        .catch(() => {
          console.log('request error')
        })
    },
    onShop(val) {
      this.$router.push({ name: 'storeHome', query: { id: val.id } })
    },

    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem('sp_token')
      localStorage.removeItem('sp_info')
      try {
        this.$socket.logout()
      } catch (err) {
        console.log(err)
      }

      this.tokenStatus()
      // this.$socket.close()
      // this.$emit('onSignout', [])
    }
  }
}
</script>
<style scoped src="@/static/css3/iconfont.css"></style>
<style scoped src="@/static/css3/1.css"></style>
<style scoped>
.toast-box {
  top: 100%;
  position: absolute;
  left: calc(100% - 180px);
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: all 0.15s ease-in-out;
  z-index: 10;
}

.toast-btn {
  position: relative;
}

.toast-btn:hover .toast-box {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.ASDFEWQ {
  background-color: #ff423424;
  border-radius: 10px;
  padding: 2px 10px;
  color: #ff1100;
}

.tools-list-box .tit1 {
  font-size: 12px;
  margin-left: 4px;
}

.ASDFEWQ:hover {
  color: #fd655a !important;
}
</style>
