<template>
  <HeadNav></HeadNav>
  <!-- 搜索框 -->
  <!-- <SearchBox ref="SearchBox"></SearchBox> -->

  <!-- 内容 -->
  <div class="content" style="-margin-top: 121px">
    <!-- 店铺栏目 -->
    <div class="cont-bg">
      <div class="cont-main">
        <div class="store-name">
          <a>{{ store.name }}</a>
        </div>
        <!-- 等级 -->
        <div style="margin: 0px 10px">
          <!-- <div class="lev-bg" v-if="store.level > 10">
            <div v-for="(vo, k) in store.level - 10" :key="k">
              <img src="@/static/level/c.png" />
            </div>
          </div> -->
          <div class="lev-bg" v-if="store.level > 5">
            <div v-for="(vo, k) in store.level - 5" :key="k">
              <img src="@/static/level/c.png" />
            </div>
          </div>
          <div class="lev-bg" v-else>
            <div v-for="(vo, k) in store.level" :key="k">
              <img src="@/static/level/b.png" />
            </div>
          </div>
        </div>
        <div class="store-year">
          {{ $t('store.info.open', { y: week }) }}
        </div>
        <!-- <div class="store-gold">
          <img src="@/static/img/gold.png" alt="" />
          <div class="rate">95.8%</div>
          <div class="font">Positive feedback</div>
        </div> -->
      </div>
    </div>

    <!-- 一张封面图 -->
    <!-- <div class="cont-bg2" :style="'background-image: url(' + content.banner + '?r=' + Math.floor(Math.random() * 1000) + ');'"></div> -->

    <div class="sn-box">
      <slot name="banner" :template="store.template || 0" :banner="content.banner || ''"> </slot>
      <div class="sn-main">
        <div class="s-name">
          <div class="sname-f">{{ store.name }}</div>
          <!-- <div class="sname-c">store name</div> -->
        </div>
      </div>
    </div>

    <!-- 导航栏 -->
    <div class="cont-nav">
      <div class="cont-nav-main">
        <div :class="['nav-span', checked == MENU.storeHome ? 'active' : '']">
          <router-link :to="{ name: 'storeHome', query: { id: store_id } }">{{ $t('detail.store.home') }}</router-link>
        </div>
        <div :class="['nav-span', checked == MENU.storeSales ? 'active' : '']">
          <router-link :to="{ name: 'storeSales', query: { id: store_id } }">{{ $t('detail.sale.items') }}</router-link>
        </div>
        <div :class="['nav-span', checked == MENU.storeComment ? 'active' : '']">
          <router-link :to="{ name: 'storeComment', query: { id: store_id } }">{{ $t('_sjsy._ddpl') }}</router-link>
        </div>
        <div :class="['nav-span', checked == MENU.storeHalloween ? 'active' : '']">
          <router-link :to="{ name: 'storeActivity', query: { type: 'halloween', id: store_id } }">{{ $t('_wsj._wsj') }}</router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- 路由 -->
  <slot name="content" :template="store.template || 0" :imgs="content.imgs || []"></slot>
  <!-- <router-view></router-view> -->

  <!-- 页脚 -->
  <Footer></Footer>
</template>

<script>
import HeadNav from '@/components/common/HeadNav.vue'
// import SearchBox from '@/components/category/SearchBox.vue'
import Footer from '@/components/Footer.vue'

const MENU = {
  storeHome: 'storeHome',
  storeSales: 'storeSales',
  storeComment: 'storeComment',
  storeHalloween: 'halloween'
}
export default {
  name: 'storeVue',
  components: {
    HeadNav,
    // SearchBox,
    Footer
  },
  data() {
    return {
      //当前路由
      checked: '',
      MENU,

      store: {},
      content: {},
      week: 0
    }
  },
  watch: {
    $route(newRoute) {
      this.setCurrent(newRoute)
    }
  },
  created() {
    this.store_id = this.$route.query.id
    this.getStoreInfo()

    this.setCurrent(this.$route)
  },
  methods: {
    setCurrent(route) {
      console.log(route)
      if (route.name == 'storeActivity') {
        this.checked = route.query.type
      } else {
        this.checked = route.name
      }
    },
    getStoreInfo() {
      this.$api.storeInfo.getStoreInfo({ id: this.store_id }).then((res) => {
        this.store = res.data.store
        this.content = res.data.content
        this.week = res.data.week
      })
    }
  }
}
</script>

<style scoped src="../static/css/default.css"></style>
<style scoped>
#bd {
  padding: 0;
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-weight: 700;
  float: left;
}
.cont-bg {
  width: 100%;
  height: 40px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
}

.cont-main {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.store-name a:hover {
  color: #f90;
}

.store-year {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(233, 233, 233);
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(102, 102, 102);
  border-radius: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 20px;
  background-color: rgb(250, 250, 250);
  float: left;
  margin-left: 20px;
}

.store-gold {
  display: flex;
  align-items: center;
}
.store-gold img {
  height: 16px;
}

.store-gold .rate {
  margin-left: 10px;
  font-weight: 700;
}
.store-gold .font {
  margin-left: 5px;
}

/* bg2 */
.cont-bg2 {
  width: 100%;
  /* height: 250px; */
  overflow: hidden;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.cont-bg2 img {
  width: 100%;
}

.cont-nav {
  display: flex;
  background: #000;
}

.cont-nav-main {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  height: 40px;
}

.nav-span {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.nav-span:hover {
  background-color: rgb(255, 255, 255, 0.2);
}

.nav-span.active {
  background-color: rgb(255, 255, 255, 0.2);
}

.cont-nav a {
  color: #fff;
}

.sn-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.sn-main {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
}

.s-name {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sname-f {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.sname-c {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}
</style>
