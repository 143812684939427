<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.wallet') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <a-row :gutter="24">
    <!-- 钱包 -->
    <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">
              {{ $t('member.wallet.balance') }}
            </div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>dollar</template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number">
          <div>${{ wallet[0] && wallet[0].balance }}</div>
        </div>
        <div class="chart-footer">
          <div>
            <a-button type="primary" @click="onRecharge">{{ $t('member.crypto.recharge') }}</a-button>
            <!-- <a-button v-if="isBank == 2" type="primary" :style="{ marginLeft: '10px' }" @click="onBankCard">{{ $t('member.crypto.bank') }}</a-button> -->
            <!-- <a-button type="" :style="{marginLeft: '10px'}" @click="onWithdrawal">{{$t('member.crypto.withdrawal')}}</a-button> -->
          </div>
        </div>
      </a-card>
    </a-col>
    <!-- 冻结资金 -->
    <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">
              {{ $t('member.wallet.freeze') }}
            </div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>dollar</template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number">
          <div>${{ wallet[0] && (parseFloat(wallet[0].freeze) + parseFloat(wallet[0].freeze_profit)).toFixed(2) }}</div>
        </div>
        <div class="chart-footer">
          <div>
            <!-- <a-button type="primary" @click="onRecharge">{{$t('member.crypto.recharge')}}</a-button> -->
            <!-- <a-button type="primary" :style="{ marginLeft: '10px' }" @click="onBankCard" v-if="isBank == 2">{{ $t('member.crypto.bank') }}</a-button> -->
            <a-button type="" :style="{ marginLeft: '10px' }" @click="onWithdrawal">{{ $t('member.crypto.withdrawal') }}</a-button>
          </div>
        </div>
      </a-card>
    </a-col>
    <!-- 预计收益 -->
    <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">
              {{ $t('member.wallet.profit') }}
            </div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>dollar</template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number">
          <div>${{ wallet[0] && wallet[0].profit }}</div>
        </div>
        <div class="chart-footer">
          <div>
            <div style="height: 32px"></div>
            <!-- <a-button type="primary" @click="onRecharge">{{$t('member.crypto.recharge')}}</a-button> -->
            <!-- <a-button type="primary" :style="{marginLeft: '10px'}" @click="onBankCard">{{$t('member.crypto.bank')}}</a-button> -->
            <!-- <a-button type="" :style="{marginLeft: '10px'}" @click="onWithdrawal">{{$t('member.crypto.withdrawal')}}</a-button> -->
          </div>
        </div>
      </a-card>
    </a-col>
    <!-- 经纪收益 -->
    <a-col :sm="24" :md="12" :xl="5" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">{{ $t('share.jjsy') }}</div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>
                <div>dollar</div>
              </template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number">
          <div>${{ wallet[0] && wallet[0].award }}</div>
        </div>
        <div class="chart-footer">
          <div style="width: 100%; display: flex; gap: 10px; align-items: center">
            <!-- <div class="icon" style="display: flex; align-items: center">
              <a-tooltip>
                <template #title>
                  <div>Lv1:300</div>
                  <div>Lv2:500</div>
                </template>
                <question-circle-outlined :style="{ fontSize: '18px', color: '#8bc34a' }" />
              </a-tooltip>
            </div> -->
            <!-- <div>等级：Lv{{ credit.level }}</div> -->
            <!-- <div>{{ $t('credit.ed') }}: ${{ credit.max }}</div> -->
            <!-- <a-button type="primary" @click="onRecharge">{{$t('member.crypto.recharge')}}</a-button> -->
            <!-- <a-button type="primary" :style="{marginLeft: '10px'}" @click="onBankCard">{{$t('member.crypto.bank')}}</a-button> -->
            <!-- <a-button type="" :style="{marginLeft: '10px'}" @click="onWithdrawal">{{$t('member.crypto.withdrawal')}}</a-button> -->
          </div>
          <a-button type="" :style="{ marginLeft: '10px' }" @click="onAward">{{ $t('share.hz') }}</a-button>
        </div>
      </a-card>
    </a-col>
    <!-- 信用额度 -->
    <a-col :sm="24" :md="12" :xl="4" :style="{ marginBottom: '24px' }" hidden>
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">{{ $t('credit.title') }}</div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>
                <div>dollar</div>
              </template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number">
          <div>${{ credit.balance }}</div>
        </div>
        <div class="chart-footer">
          <div style="width: 100%; display: flex; gap: 10px; align-items: center">
            <div class="icon" style="display: flex; align-items: center">
              <a-tooltip>
                <template #title>
                  <div>Lv1:300</div>
                  <div>Lv2:500</div>
                </template>
                <question-circle-outlined :style="{ fontSize: '18px', color: '#8bc34a' }" />
              </a-tooltip>
            </div>
            <!-- <div>等级：Lv{{ credit.level }}</div> -->
            <div>{{ $t('credit.ed') }}: ${{ credit.max }}</div>
            <!-- <a-button type="primary" @click="onRecharge">{{$t('member.crypto.recharge')}}</a-button> -->
            <!-- <a-button type="primary" :style="{marginLeft: '10px'}" @click="onBankCard">{{$t('member.crypto.bank')}}</a-button> -->
            <!-- <a-button type="" :style="{marginLeft: '10px'}" @click="onWithdrawal">{{$t('member.crypto.withdrawal')}}</a-button> -->
          </div>
          <a-button type="" :style="{ marginLeft: '10px' }" @click="onRepayment">{{ $t('credit.hk') }}</a-button>
        </div>
      </a-card>
    </a-col>
  </a-row>
  <!-- 钱包记录 -->
  <div>
    <a-card>
      <a-tabs v-model:activeKey="activeKey" @change="onChange($event)">
        <a-tab-pane :key="1" :tab="$t('member.wallet.record')">
          <ReceiveList ref="receive"></ReceiveList>
        </a-tab-pane>
        <a-tab-pane :key="3" :tab="$t('_yhqd._yhzz')" v-if="isBank == 2">
          <BankCardList ref="banklist"></BankCardList>
        </a-tab-pane>
        <a-tab-pane :key="4" :tab="$t('member.withdrawal.record')">
          <TransferList ref="transfer"></TransferList>
        </a-tab-pane>
        <a-tab-pane :key="5" :tab="$t('_txcd._yhtx')" v-if="isBank == 2">
          <BankTransferList ref="bankTransfer"></BankTransferList>
        </a-tab-pane>
        <a-tab-pane :key="6" :tab="$t('member.income.record')">
          <IncomeLog ref="incomeLog"></IncomeLog>
        </a-tab-pane>
        <a-tab-pane :key="7" :tab="$t('member.transaction.record')">
          <Walletlog ref="walletlog"></Walletlog>
        </a-tab-pane>
        <a-tab-pane :key="8" :tab="$t('bind.wallet.title')">
          <BindAddressList ref="addresslist"></BindAddressList>
        </a-tab-pane>
        <a-tab-pane :key="9" :tab="$t('_txcd._wdyhdz')" v-if="isBank == 2">
          <WithdrawalBindAddressList ref="withdrawaladdresslist"></WithdrawalBindAddressList>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
  <!-- 存币弹框 -->
  <Recharge ref="recharge" @RechargeSuccess="RechargeSuccess"></Recharge>

  <!-- 提现弹框 -->
  <Withdrawal ref="withdrawal" @WithdrawalSuccess="WithdrawalSuccess" :wallet="wallet[0]"></Withdrawal>

  <!-- 提现弹框 -->
  <BankCard ref="bankcard" @BankCardSuccess="onBankCardSuccess" v-if="isBank == 2"></BankCard>

  <!-- 还款弹框 -->
  <Repayment ref="repayment" @RepaymentSuccess="RepaymentSuccess"></Repayment>

  <!-- 划转奖励弹框 -->
  <StoreAward ref="storeaward" @AwardSuccess="AwardSuccess"></StoreAward>
</template>

<script>
import { DollarOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import ReceiveList from '@/components/store/ReceiveList.vue'
import BankCardList from '@/components/store/BankCardList.vue'
import TransferList from '@/components/store/TransferList.vue'
import Walletlog from '@/components/store/Walletlog.vue'
import IncomeLog from '@/components/store/IncomeLog.vue'
import Recharge from '@/components/store/Recharge.vue'
import BankCard from '@/components/store/BankCard.vue'
import Withdrawal from '@/components/store/Withdrawal.vue'
import Repayment from '@/components/store/Repayment.vue'
import StoreAward from '@/components/store/StoreAward.vue'

import BindAddressList from '@/components/store/BindAddressList.vue'
import WithdrawalBindAddressList from '@/components/store/WithdrawalBindAddressList.vue'
import BankTransferList from '@/components/store/BankTransferList.vue'
export default {
  name: 'walletVue',
  components: {
    DollarOutlined,
    QuestionCircleOutlined,
    ReceiveList,
    TransferList,
    IncomeLog,
    Walletlog,
    Recharge,
    Repayment,
    BankCardList,
    BankCard,
    Withdrawal,
    BindAddressList,
    StoreAward,
    WithdrawalBindAddressList,
    BankTransferList
  },
  data() {
    return {
      //tabs
      activeKey: 1,

      // 钱包
      wallet: [],

      //显示银行卡
      isBank: 1,

      //信用额度
      credit: {
        balance: 0,
        level: 0,
        max: 0
      }
    }
  },
  created() {
    this.getWallet()
  },
  methods: {
    onChange(e) {
      console.log(e)
      const list = ['', 'receive', '', 'transfer', 'bankTransfer', 'incomeLog', 'walletlog', 'addresslist', 'withdrawaladdresslist']
      this.$refs[list[e]]?.refresh()
    },
    getWallet() {
      let that = this
      this.$api.store.getShopWallet().then((res) => {
        that.wallet = res.data.wallet
        that.credit = res.data.credit
        that.isBank = res.data.is_bank
      })
    },
    onRepayment() {
      this.$refs.repayment.show()
    },
    onAward() {
      this.$refs.storeaward.show()
    },
    RepaymentSuccess() {
      this.getWallet()
    },
    AwardSuccess() {
      this.getWallet()
    },
    onRecharge() {
      this.$refs.recharge.show(this.isBank)
    },
    RechargeSuccess() {
      this.getWallet()
      this.$refs.receive.refresh()
    },
    /**
     * 提现
     */
    onWithdrawal() {
      this.$refs.withdrawal.show(this.isBank)
    },
    WithdrawalSuccess() {
      this.getWallet()
      this.$refs.transfer?.refresh()
      this.$refs.bankTransfer?.refresh()
    },
    onBankCard() {
      this.$refs.bankcard.show()
    },
    onBankCardSuccess() {
      this.getWallet()
      this.$refs.transfer.refresh()
    }
  }
}
</script>

<style scoped src="@/static/css/store-css.css"></style>

<style lang="less">
.chart-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  .meta {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
}

.chart-number {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 38px;
  height: 38px;
}

.chart-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.xy_span {
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.xy_span.active {
  border: 1px solid #009688;
  color: #009688;
}

.token_css {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  border: 1px solid #b7eb8f;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
