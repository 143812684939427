<template>
  <!-- 加载更多 -->
  <div class="loadmore" v-show="loading">
    <img class="_2O2Fm" src="@/static/img/loading.gif" />
  </div>
  <div class="order-content">
    <div class="comet-checkbox-group">
      <!-- 订单信息 -->
      <div v-for="(item, i) in orderList.data" :key="i" class="order-item">
        <!-- 订单信息 -->
        <div class="order-item-header" style="border-width: 1px">
          <div class="order-item-header-status">
            <span class="order-item-header-status-text">{{ STATUS[item.status] }}</span>
          </div>
          <div class="order-item-header-right">
            <div class="order-item-header-right-info">
              <div>{{ $t('member.order.date') }}: {{ item.create_time }}</div>
              <div style="display: flex; align-items: center">
                {{ $t('member.order.id') }} ID: {{ item.order_sn }}
                <span class="order-item-header-right-copy" @click="onCopy(item.order_sn)">{{ $t('member.order.cpoy') }}</span>
              </div>
            </div>
            <span class="order-item-header-line"></span>
            <router-link :to="{ name: 'order-detail', query: { order_sn: item.order_sn } }" style="font-weight: bold">
              <span>{{ $t('member.order.detail') }}</span>
              <span class="comet-icon comet-icon-arrowright">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                  <path d="M318.698667 97.6a35.925333 35.925333 0 0 1 49.813333-4.128l1.632 1.408 371.914667 355.232a85.333333 85.333333 0 0 1 0.032 123.381333L370.133333 929.12a35.936 35.936 0 0 1-51.445333-2.730667 37.674667 37.674667 0 0 1 1.130667-50.944l1.546666-1.504L685.653333 527.253333a21.333333 21.333333 0 0 0 0-30.922666L321.365333 150.058667a37.653333 37.653333 0 0 1-2.666666-52.448z"></path>
                </svg>
              </span>
            </router-link>
          </div>
        </div>
        <!-- 店铺信息 -->
        <div class="order-item-store">
          <div>
            <img class="order-item-store-icon" src="@/static/img/store.png" alt="" />
          </div>
          <div class="order-item-store-name">
            <router-link :to="{ name: 'storeHome', query: { id: item.store.id } }">
              <div style="height: 18px">{{ item.store && item.store.name }}</div>
            </router-link>
            <div class="order-item-store-name">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M318.698667 97.6a35.925333 35.925333 0 0 1 49.813333-4.128l1.632 1.408 371.914667 355.232a85.333333 85.333333 0 0 1 0.032 123.381333L370.133333 929.12a35.936 35.936 0 0 1-51.445333-2.730667 37.674667 37.674667 0 0 1 1.130667-50.944l1.546666-1.504L685.653333 527.253333a21.333333 21.333333 0 0 0 0-30.922666L321.365333 150.058667a37.653333 37.653333 0 0 1-2.666666-52.448z"></path>
              </svg>
            </div>
          </div>
          <router-link :to="{ name: 'message', query: { code: item.store && item.store.sid } }" class="order-item-store-message">
            <span class="comet-icon comet-icon-message" style="font-size: 18px; display: flex; align-items: center">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path
                  d="M602.453333 128c90.581333 0 175.498667 37.173333 238.122667 101.984C907.072 298.805333 938.666667 372.405333 938.666667 474.88c0 106.496-35.52 200.917333-98.389334 265.994667-62.624 64.821333-147.541333 101.994667-238.122666 101.994666H475.381333A538.133333 538.133333 0 0 0 326.005333 864l-109.664 31.68a53.12 53.12 0 0 1-66.752-61.792l21.909334-105.770667-2.08-2.432c-52.949333-62.677333-82.848-148.48-84.053334-244.778666L85.333333 474.869333c0-102.464 31.594667-176.064 98.090667-244.885333C246.048 165.173333 330.965333 128 421.546667 128z m0 64H421.546667c-72.96 0-141.354667 29.941333-192.096 82.453333C174.165333 331.68 149.333333 389.514667 149.333333 474.88c0 90.805333 29.152 169.077333 79.818667 221.514667a32 32 0 0 1 8.32 28.736l-21.568 104.053333 92.341333-26.666667a602.133333 602.133333 0 0 1 157.717334-23.584l9.418666-0.074666h126.773334c72.96 0 141.354667-29.941333 192.096-82.453334C845.184 643.669333 874.666667 565.28 874.666667 474.858667c0-85.354667-24.832-143.189333-80.117334-200.416-50.741333-52.512-119.146667-82.453333-192.106666-82.453334zM704 448a42.666667 42.666667 0 1 1 0 85.333333 42.666667 42.666667 0 0 1 0-85.333333z m-192 0a42.666667 42.666667 0 1 1 0 85.333333 42.666667 42.666667 0 0 1 0-85.333333z m-192 0a42.666667 42.666667 0 1 1 0 85.333333 42.666667 42.666667 0 0 1 0-85.333333z"
                ></path>
              </svg>
            </span>
          </router-link>
        </div>
        <!-- 商品信息 -->
        <div class="order-item-content">
          <!-- 多个商品 -->
          <div v-if="item.detail.length > 1" class="order-item-content-body">
            <div class="order-item-content-img-list">
              <a v-for="(vo, k) in item.detail" :key="k" href="javascript:" target="_blank">
                <div class="order-item-content-img" :style="'background-image: url(' + vo.img + ');'" v-if="k < 6"></div>
              </a>
            </div>
          </div>
          <!-- 单个商品 -->
          <div v-else class="order-item-content-body">
            <router-link :to="{ name: 'detail', query: { id: item.detail[0].goods_id } }">
              <div class="order-item-content-img" :style="'background-image: url(' + item.detail[0].img + ');'"></div>
            </router-link>
            <div class="order-item-content-info">
              <div class="order-item-content-info-name">
                <router-link :to="{ name: 'detail', query: { id: item.detail[0].goods_id } }">
                  <span>{{ item.detail[0].name }}</span>
                </router-link>
              </div>
              <div class="order-item-content-info-sku">
                <span v-for="(svo, sk) in item.detail[0].sku" :key="sk" style="margin-right: 5px">{{ sk }}:{{ svo }}</span>
              </div>
              <div class="order-item-content-info-number">
                ${{ item.detail[0].price }}<span>x{{ item.detail[0].num }}</span>
              </div>
              <div class="order-item-content-info-tag">
                <span class="order-item-tag" style="cursor: pointer">
                  <img src="@/static/img/dun.png" />
                </span>
              </div>
            </div>
          </div>
          <div class="order-item-content-opt">
            <div class="order-item-content-opt-price">
              <span class="order-item-content-opt-price-total">Total: ${{ item.total }}</span>
            </div>
            <div class="order-item-btns-wrap" v-if="item.status == 1">
              <div class="order-item-btns">
                <button @click="onPay(item)" type="button" class="comet-btn comet-btn-primary comet-btn-block order-item-btn">
                  <span>Pay now</span>
                </button>
              </div>
            </div>
            <div class="order-item-btns-wrap" v-if="item.status == 3">
              <div class="order-item-btns">
                <button @click="onRefund(item)" type="button" class="comet-btn comet-btn-primary comet-btn-block order-item-btn">
                  <span>{{ $t('detail.sqtk') }}</span>
                </button>
              </div>
            </div>
            <div class="order-item-btns-wrap" v-if="item.status == 5">
              <div class="order-item-btns">
                <button @click="onReceipt(item)" type="button" class="comet-btn comet-btn-primary comet-btn-block order-item-btn">
                  <span>{{ $t('detail.receipt') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 无订单时 -->
      <div class="empty-info empty-info-mt16" v-if="isEmpty">
        <span class="comet-icon comet-icon-orders">
          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
            <path
              d="M640 85.333333a96.042667 96.042667 0 0 1 90.538667 64H778.666667c63.776 0 115.658667 50.88 117.290666 114.261334L896 266.666667v554.666666c0 63.776-50.88 115.658667-114.261333 117.290667L778.666667 938.666667H245.333333c-63.776 0-115.658667-50.88-117.290666-114.261334L128 821.333333V266.666667c0-63.776 50.88-115.658667 114.261333-117.290667L245.333333 149.333333h48.128A96.042667 96.042667 0 0 1 384 85.333333z m90.538667 128A96.042667 96.042667 0 0 1 640 277.333333H384a96.042667 96.042667 0 0 1-90.538667-64H245.333333a53.333333 53.333333 0 0 0-53.28 51.018667L192 266.666667v554.666666a53.333333 53.333333 0 0 0 51.018667 53.28L245.333333 874.666667h533.333334a53.333333 53.333333 0 0 0 53.28-51.018667L832 821.333333V266.666667a53.333333 53.333333 0 0 0-51.018667-53.28L778.666667 213.333333zM560 682.666667a32 32 0 0 1 0 64H330.666667a32 32 0 0 1 0-64h229.333333zM693.333333 533.333333a32 32 0 0 1 0 64H330.666667a32 32 0 0 1 0-64h362.666666z m0-149.333333a32 32 0 0 1 0 64H330.666667a32 32 0 0 1 0-64h362.666666z m-53.333333-234.666667H384a32 32 0 0 0 0 64h256a32 32 0 0 0 0-64z"
            ></path>
          </svg>
        </span>
        <div class="empty-info-text">
          {{ $t('member.order.empty') }}
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination--paginationList--2qhuJId" v-if="orderList.totalPage > 1">
      <div class="pagination--left--3ZLy8Mu">
        <ul class="pagination--paginationWrapper--1eniWgI">
          <li @click="onPrevious()" :class="['pagination--paginationLink--2ucXUo6', orderList.pageNo == 1 ? 'pagination--notAllowed--13IxSCI' : '', 'next-prev']">
            <span class="comet-icon comet-icon-arrowleft">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M705.301333 97.6a35.925333 35.925333 0 0 0-49.813333-4.128l-1.632 1.408-371.914667 355.232a85.333333 85.333333 0 0 0-0.032 123.381333l371.946667 355.626667a35.936 35.936 0 0 0 51.445333-2.730667 37.674667 37.674667 0 0 0-1.130666-50.944l-1.546667-1.504L338.346667 527.253333a21.333333 21.333333 0 0 1 0-30.922666l364.277333-346.272a37.653333 37.653333 0 0 0 2.666667-52.448z"></path>
              </svg>
            </span>
            {{ $t('base.previous') }}
          </li>
          <li @click="onPage(i)" v-for="i in orderList.totalPage" :key="i" :class="['pagination--paginationLink--2ucXUo6', orderList.pageNo == i ? 'pagination--isActive--58C6XTV' : '']">
            {{ i }}
          </li>
          <li @click="onNext()" :class="['pagination--paginationLink--2ucXUo6', orderList.pageNo == orderList.totalPage ? 'pagination--notAllowed--13IxSCI' : '', 'next-next']">
            {{ $t('base.next') }}
            <span class="comet-icon comet-icon-arrowright">
              <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M318.698667 97.6a35.925333 35.925333 0 0 1 49.813333-4.128l1.632 1.408 371.914667 355.232a85.333333 85.333333 0 0 1 0.032 123.381333L370.133333 929.12a35.936 35.936 0 0 1-51.445333-2.730667 37.674667 37.674667 0 0 1 1.130667-50.944l1.546666-1.504L685.653333 527.253333a21.333333 21.333333 0 0 0 0-30.922666L321.365333 150.058667a37.653333 37.653333 0 0 1-2.666666-52.448z"></path>
              </svg>
            </span>
          </li>
        </ul>
      </div>
      <div class="pagination--right--gUH5L-E">
        <span class="pagination--total--DZpfoqm"></span>
        <span class="pagination--goto--2Z8hq98">{{ $t('base.go.to.page') }}</span>
        <input v-model="ipage" type="text" class="comet-input" style="width: 74px" autocomplete="off" />
        <span class="pagination--jumpBtn--3e69BYK" @click="GoPage()">{{ $t('base.go') }}</span>
      </div>
    </div>
  </div>

  <Confirm ref="confirm" :title="msgTitle" :content="msgContent" @onOk="onReceiptOk"></Confirm>

  <Confirm ref="refund" :title="msgTitle" :content="msgContent" @onOk="onRefundOk"></Confirm>
  <!-- 支付方式 -->
  <payment ref="payment" @setWallet="onPayOk"></payment>
</template>

<script>
import Confirm from '@/components/member/Confirm'
import Payment from '@/components/member/Payment.vue'
export default {
  name: 'OrderAll',
  components: {
    Confirm,
    Payment
  },
  props: {
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      //列表
      STATUS: {
        0: 'Unkonw',
        '-1': this.$t('member.order.refund'),
        '-2': this.$t('member.order.refund'),
        '-3': this.$t('store.order.purchase.no'),
        1: this.$t('member.order.unpaid'),
        2: this.$t('member.order.completed'),
        3: this.$t('member.order.procurement'),
        4: this.$t('member.order.processing'),
        5: this.$t('member.order.shipped')
      },

      isEmpty: false,

      loading: true,

      //消息内容
      msgTitle: '',
      msgContent: '',
      msgRecord: {},

      //列表
      orderList: [],
      ipage: '',

      //是否正在收货中
      isReceiptOk: false
    }
  },
  created() {
    this.getOrderList(1)
  },
  methods: {
    /**
     * 复制地址
     */
    onCopy(order_sn) {
      console.log(order_sn)
      // copyData想要复制的内容
      this.$copyText(order_sn)
        .then(() => {
          this.$message.success('copy success! ' + order_sn)
        })
        .catch(() => {
          this.$message.error('copy fail')
        })
    },
    onPage(i) {
      this.getOrderList(i)
    },
    GoPage() {
      if (parseInt(this.ipage) > this.orderList.totalPage) {
        this.ipage = this.orderList.totalPage
      }

      this.getOrderList(this.ipage)
    },
    onPrevious() {
      if (this.orderList.pageNo - 1 < 1) {
        return
      }
      this.getOrderList(this.orderList.pageNo - 1)
    },
    onNext() {
      if (this.orderList.pageNo + 1 > this.orderList.totalPage) {
        return
      }
      this.getOrderList(this.orderList.pageNo + 1)
    },
    /**
     * 确认收货
     */
    onReceipt(record) {
      this.msgRecord = record
      this.msgTitle = this.$t('detail.receipt.title')
      this.msgContent = this.$t('detail.receipt.content')
      this.$refs.confirm.open()
    },
    onReceiptOk() {
      let that = this
      if (this.isReceiptOk) {
        return
      }
      this.isReceiptOk = true
      this.$message.loading({ content: 'Receipt order...', key: 'msg' })
      this.$api.order
        .receipt({ id: this.msgRecord.id })
        .then((res) => {
          this.isReceiptOk = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          that.$refs.confirm.hide()
          that.getOrderList()
        })
        .catch(() => {
          that.$refs.confirm.hide()
          this.isReceiptOk = false
          that.getOrderList()
        })
    },
    /**
     * 确认收货
     */
    onRefund(record) {
      this.msgRecord = record
      this.msgTitle = this.$t('detail.refund.title')
      this.msgContent = this.$t('detail.refund.content')
      this.$refs.refund.open()
    },
    onRefundOk() {
      let that = this
      if (this.isReceiptOk) {
        return
      }
      this.isReceiptOk = true
      this.$message.loading({ content: 'refund order...', key: 'msg' })
      this.$api.order
        .refund({ id: this.msgRecord.id })
        .then((res) => {
          this.isReceiptOk = false
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })
          that.$refs.refund.hide()
          that.getOrderList()
        })
        .catch(() => {
          that.$refs.refund.hide()
          this.isReceiptOk = false
          that.getOrderList()
        })
    },

    /**
     * 再次支付
     * @param {*} record
     */
    onPay(record) {
      this.msgRecord = record
      this.$refs.payment.open()
    },
    onPayOk(wallet) {
      console.log(wallet, '选择钱包')
      let that = this
      this.$message.loading({ content: 'Receipt order...', key: 'msg' })
      this.$api.order.payOrder({ order_sn: [this.msgRecord.order_sn], wallet_id: wallet.id }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.getOrderList(1)
      })
    },
    getOrderList(page = 1) {
      let that = this
      this.loading = true
      this.$message.loading({ content: 'loading...', key: 'msg' })
      this.$api.user.getOrderList({ page: page, size: 10, status: this.status }).then((res) => {
        that.loading = false
        setTimeout(function () {
          that.$message.destroy()
        }, 500)
        that.orderList = res.data
        if (that.orderList.length == 0) {
          that.isEmpty = true
        }
      })
    }
  }
}
</script>
<style scoped src="@/static/css/order.css"></style>
<style>
.pagination--paginationList--2qhuJId,
.pagination--paginationList--2qhuJId .pagination--left--3ZLy8Mu,
.pagination--paginationList--2qhuJId .pagination--right--gUH5L-E {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination--paginationList--2qhuJId {
  padding: 16px;
  background: #fff;
  border-radius: 8px;
}

.pagination--paginationList--2qhuJId,
.pagination--paginationList--2qhuJId .pagination--left--3ZLy8Mu,
.pagination--paginationList--2qhuJId .pagination--right--gUH5L-E {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.list--list--1gFqMKL,
.pagination--paginationWrapper--1eniWgI {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

ul {
  list-style: none;
}

.pagination--paginationWrapper--1eniWgI .pagination--notAllowed--13IxSCI {
  cursor: not-allowed;
  color: #ccc;
}

.pagination--paginationWrapper--1eniWgI .pagination--paginationLink--2ucXUo6 {
  border: 1px solid #ccc;
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 14px;
  color: #000;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  margin-right: 4px;
}

.pagination--paginationWrapper--1eniWgI .pagination--notAllowed--13IxSCI {
  cursor: not-allowed;
  color: #ccc;
}

.pagination--paginationWrapper--1eniWgI .pagination--isActive--58C6XTV {
  background: #ffb500;
  color: #fff;
}

.comet-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination--paginationList--2qhuJId .pagination--right--gUH5L-E .pagination--total--DZpfoqm {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 10px;
}

.pagination--paginationList--2qhuJId .pagination--right--gUH5L-E .pagination--goto--2Z8hq98 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0 10px;
}

.pagination--paginationList--2qhuJId .pagination--right--gUH5L-E .pagination--jumpBtn--3e69BYK {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 7px 23px;
  min-width: 64px;
  height: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}
</style>
