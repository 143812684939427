export default [
  '阿富汗',
  '阿爾巴尼亞',
  '阿爾及利亞',
  '美屬薩摩亞',
  '安道爾',
  '安哥拉',
  '安圭拉',
  '南極洲',
  '安地卡及巴布達',
  '阿根廷',
  '亞美尼亞',
  '阿魯巴',
  '澳大利亞',
  '奧地利',
  '亞塞拜然',
  '巴哈馬',
  '巴林',
  '孟加拉',
  '巴貝多',
  '白俄羅斯',
  '比利時',
  '貝里斯',
  '貝南',
  '百慕達',
  '不丹',
  '玻利維亞',
  '波士尼亞與赫塞哥維納',
  '波札那',
  '巴西',
  '英屬印度洋領地',
  '英屬維京群島',
  '汶萊',
  '保加利亞',
  '布吉納法索',
  '緬甸',
  '蒲隆地',
  '柬埔寨',
  '喀麥隆',
  '加拿大',
  '維德角',
  '開曼群島',
  '中非共和國',
  '查德',
  '智利',
  '中國',
  '聖誕島',
  '克利珀頓島',
  '科科斯（基林）群島',
  '哥倫比亞',
  '葛摩',
  '剛果民主共和國',
  '剛果共和國',
  '庫克群島',
  '珊瑚海群島',
  '哥斯大黎加',
  '科特迪瓦',
  '克羅埃西亞',
  '古巴',
  '賽普勒斯',
  '捷克共和國',
  '丹麥',
  '吉布地',
  '多明尼加',
  '多明尼加共和國',
  '厄瓜多',
  '埃及',
  '薩爾瓦多',
  '赤道幾內亞',
  '厄利垂亞',
  '愛沙尼亞',
  '衣索比亞',
  '歐羅巴島',
  '福克蘭群島',
  '法羅群島',
  '斐濟',
  '芬蘭',
  '法國',
  '法屬圭亞那',
  '法屬玻里尼西亞',
  '加彭',
  '甘比亞',
  '喬治亞州',
  '德國',
  '迦納',
  '直布羅陀',
  '格羅里索群島',
  '希臘',
  '格陵蘭',
  '格瑞那達',
  '瓜德羅普島',
  '關島',
  '瓜地馬拉',
  '根西島',
  '幾內亞',
  '幾內亞比紹',
  '蓋亞那',
  '海地',
  '羅馬教廷（梵蒂岡城）',
  '宏都拉斯',
  '匈牙利',
  '冰島',
  '印度',
  '印尼',
  '伊朗',
  '伊拉克',
  '愛爾蘭',
  '馬恩島',
  '以色列',
  '義大利',
  '牙買加',
  '揚馬延',
  '日本',
  '球衣',
  '約旦',
  '胡安·德·諾瓦島',
  '哈薩克',
  '肯亞',
  '吉里巴斯',
  '科威特',
  '吉爾吉斯斯坦',
  '寮國',
  '拉脫維亞',
  '黎巴嫩',
  '賴索托',
  '賴比瑞亞',
  '利比亞',
  '列支敦斯登',
  '立陶宛',
  '盧森堡',
  '馬其頓',
  '馬達加斯加',
  '馬拉威',
  '馬來西亞',
  '馬爾地夫',
  '馬裡',
  '馬爾他',
  '馬紹爾群島',
  '馬提尼克島',
  '茅利塔尼亞',
  '模里西斯',
  '馬約特島',
  '墨西哥',
  '密克羅尼西亞聯邦',
  '摩爾多瓦',
  '摩納哥',
  '蒙古',
  '蒙特塞拉特',
  '摩洛哥',
  '莫三比克',
  '納米比亞',
  '諾魯',
  '納瓦薩島',
  '尼泊爾',
  '荷蘭',
  '荷屬安的列斯',
  '新喀裡多尼亞',
  '紐西蘭',
  '尼加拉瓜',
  '尼日',
  '奈及利亞',
  '紐埃',
  '諾福克島',
  '北韓',
  '北馬裡亞納群島',
  '挪威',
  '阿曼',
  '巴基斯坦',
  '帛琉',
  '巴拿馬',
  '巴布亞紐幾內亞',
  '西沙群島',
  '巴拉圭',
  '秘魯',
  '菲律賓',
  '皮特凱恩群島',
  '波蘭',
  '葡萄牙',
  '波多黎各',
  '卡達',
  '團圓',
  '羅馬尼亞',
  '俄羅斯',
  '安達',
  '聖赫勒拿島',
  '聖克里斯多福及尼維斯',
  '聖露西亞',
  '聖皮埃爾和密克隆',
  '聖文森及格瑞那丁',
  '薩摩亞',
  '聖馬利諾',
  '聖多美和普林西比',
  '沙烏地阿拉伯',
  '塞內加爾',
  '塞爾維亞與蒙特內哥羅',
  '塞席爾',
  '獅子山',
  '新加坡',
  '斯洛伐克',
  '斯洛維尼亞',
  '索羅門群島',
  '索馬利亞',
  '南非',
  '韓國',
  '西班牙',
  '南沙群島',
  '斯里蘭卡',
  '蘇丹',
  '蘇利南',
  '斯瓦爾巴群島',
  '史瓦濟蘭',
  '瑞典',
  '瑞士',
  '敘利亞',
  '塔吉克斯坦',
  '坦尚尼亞',
  '泰國',
  '東帝汶',
  '多哥',
  '托克勞',
  '東加',
  '千里達及托巴哥',
  '特羅姆林島',
  '突尼西亞',
  '火雞',
  '土庫曼',
  '特克斯和凱科斯群島',
  '吐瓦魯',
  '烏幹達',
  '烏克蘭',
  '阿拉伯联合大公國',
  '英國',
  '美國',
  '烏拉圭',
  '烏茲別克',
  '萬那杜',
  '委內瑞拉',
  '越南',
  '維京群島',
  '威克島',
  '瓦利斯和富圖納',
  '撒哈拉沙漠西部',
  '葉門',
  '尚比亞',
  '辛巴威'
]
