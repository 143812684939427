<template>
  <div>
    <!-- 活动轮播图 -->
    <ActivityBanner></ActivityBanner>
    <a-layout :style="{ height: 'auto', minHeight: '1000px' }">
      <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible theme="light" :width="250">
        <div class="logo">
          <shop-outlined v-show="collapsed" />
          <router-link :to="{ name: 'storeHome', query: { id: store.id } }" class="s-box shop-box">
            <div v-show="!collapsed" class="shop-box">
              <div style="display: flex; align-items: center">
                <div class="shop-name">{{ store.name }}</div>
              </div>
              <!-- SID -->
              <div class="shop-sid" v-if="store.sid">UID:{{ store.sid }}</div>
              <div>
                <div class="lev-bg" v-if="store.level > 5">
                  <div v-for="(vo, k) in store.level - 5" :key="k">
                    <img src="@/static/level/c.png" />
                  </div>
                </div>
                <div class="lev-bg" v-else>
                  <div v-for="(vo, k) in store.level" :key="k">
                    <img src="@/static/level/b.png" />
                  </div>
                </div>
              </div>
              <!-- </a-tooltip> -->
            </div>
          </router-link>
          <div v-show="!collapsed" class="shop-box" style="align-items: center">
            <router-link :to="{ name: 'index' }" class="s-box fanh" style="">
              <rollback-outlined style="font-size: 25px" />
              <div style="width: 90px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ $t('_zddy._fhsy') }}</div>
            </router-link>
          </div>
        </div>
        <a-menu theme="light" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys" mode="inline" @click="onMenu">
          <!-- 仪表盘 -->
          <a-menu-item :key="MENU.dashboard">
            <dashboard-outlined />
            <span>{{ $t('store.dashboard') }}</span>
          </a-menu-item>
          <!-- 产品 -->
          <a-sub-menu :key="MENU.products">
            <template #title>
              <shop-outlined />
              <a-badge :dot="msgCenter.comment == 2">
                <span>{{ $t('store.products') }}</span>
              </a-badge>
            </template>
            <a-menu-item :key="MENU.productsList">{{ $t('store.products.list') }}</a-menu-item>
            <a-menu-item :key="MENU.productsReviews">
              <a-badge :dot="msgCenter.comment == 2">
                {{ $t('reviews.title') }}
              </a-badge>
            </a-menu-item>
          </a-sub-menu>
          <!-- 订单 -->
          <a-menu-item :key="MENU.order">
            <upload-outlined />
            <a-badge :dot="msgCenter.order > 0">
              <span>{{ $t('store.orders') }}</span>
            </a-badge>
          </a-menu-item>
          <!-- 钱包 -->
          <a-menu-item :key="MENU.wallet">
            <transaction-outlined />
            <span>{{ $t('store.wallet') }}</span>
          </a-menu-item>
          <!-- 广告推广 -->
          <a-sub-menu :key="MENU.advertise">
            <template #title>
              <fund-projection-screen-outlined />
              <span>{{ $t('advertise.title') }}</span>
            </template>
            <a-menu-item :key="MENU.advertiseList">{{ $t('adv.list.title') }}</a-menu-item>
            <a-menu-item :key="MENU.advertiseFlow">{{ $t('flow.lltg') }}</a-menu-item>
          </a-sub-menu>
          <!-- 站内通知 -->
          <!-- <a-menu-item :key="MENU.notice">
          <alert-outlined />
          <a-badge :count="msgCenter.notice">
            <span>{{ $t('_xxtz._zntz') }}</span>
          </a-badge>
        </a-menu-item> -->
          <!-- 消息中心 -->
          <a-menu-item :key="MENU.message">
            <message-outlined />
            <a-badge :count="msgCenter.msg">
              <span>{{ $t('store.message') }}</span>
            </a-badge>
          </a-menu-item>
          <!-- 分享 -->
          <a-sub-menu :key="MENU.share">
            <template #title>
              <share-alt-outlined />
              <span>{{ $t('setting.invitation') }}</span>
            </template>
            <a-menu-item :key="MENU.storeShare">{{ $t('setting.invitation') }}</a-menu-item>
            <a-menu-item :key="MENU.storeShareList">{{ $t('share.yqlb') }}</a-menu-item>
          </a-sub-menu>
          <!-- 设置 -->
          <a-sub-menu :key="MENU.setting">
            <template #title>
              <setting-outlined />
              <span>{{ $t('store.setting') }}</span>
            </template>
            <a-menu-item :key="MENU.storeStore">{{ $t('setting.sjxx') }}</a-menu-item>
            <a-menu-item :key="MENU.storeAuth">{{ $t('setting.smrz') }}</a-menu-item>
            <a-menu-item :key="MENU.storeSafety">{{ $t('setting.aqzx') }}</a-menu-item>
            <a-menu-item :key="MENU.storeNotify">{{ $t('setting.tzxx') }}</a-menu-item>
            <a-menu-item :key="MENU.storeMyHome">{{ $t('setting.dpzx') }}</a-menu-item>
            <a-menu-item :key="MENU.storeBenefit">{{ $t('setting.sjqy') }}</a-menu-item>
            <a-menu-item :key="MENU.storeRule">{{ $t('setting.spgzyzy') }}</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header class="al-header">
          <div>
            <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
            <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
          </div>
          <div class="header-right">
            <div class="css-setting">
              <a-badge :count="msgCenter.msg">
                <router-link :to="{ name: 'storeMessage' }">
                  <div class="d-box">
                    <message-outlined :style="{ fontSize: '18px', color: '#08c' }" />
                    <div class="d-lang">
                      {{ $t('text.messages') }}
                    </div>
                  </div>
                </router-link>
              </a-badge>
            </div>
            <div class="css-setting">
              <!-- <router-link :to="{ name: 'storeSetting' }"> -->
              <a-dropdown>
                <a class="ant-dropdown-link d-box">
                  <!-- <setting-outlined :style="{ fontSize: '18px', color: '#08c' }" /> -->
                  <div class="langs-main" style="display: flex">
                    <div class="langs-box">
                      <div :class="'langs-img langs-' + language.lang"></div>
                    </div>
                    <div class="d-lang">{{ language.text }}</div>
                  </div>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item v-for="(vo, i) in list" :key="i" @click="onLanguage(vo)">
                      <div class="langs-box">
                        <div :class="'langs-img langs-' + vo.lang"></div>
                        <span class="lang-item" style="padding: 0">
                          {{ vo.text }}
                          <!-- <span style="color: #e72528">●</span> -->
                        </span>
                      </div>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>

              <!-- </router-link> -->
            </div>
            <div>
              <a-dropdown>
                <div class="css-avatar">
                  <div class="c-avatar">
                    <a-avatar :size="45">
                      <template #icon>
                        <img v-if="store.avatar" :src="store.avatar" alt="" />
                        <UserOutlined v-else />
                      </template>
                    </a-avatar>
                  </div>
                  <div>
                    <div class="cname" style="width: 70px; overflow: hidden; text-overflow: ellipsis">{{ store.name }}</div>
                    <div class="cdesc">{{ $t('_zddy._mj') }}</div>
                  </div>
                  <a class="ant-dropdown-link d-box">
                    <caret-down-outlined :style="{ fontSize: '18px', color: '#08c' }" />
                  </a>
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="onSignout()">
                      <a class="switcher-item" href="javascript:;">{{ $t('signup.sign.out') }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </a-layout-header>
        <a-layout-content :style="{ margin: '24px 16px', padding: '24px' }">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
  <!-- 页脚 -->
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { RollbackOutlined, CaretDownOutlined, ShopOutlined, DashboardOutlined, UserOutlined, UploadOutlined, MenuUnfoldOutlined, MenuFoldOutlined, SettingOutlined, MessageOutlined, FundProjectionScreenOutlined, TransactionOutlined, ShareAltOutlined } from '@ant-design/icons-vue'
const MENU = {
  dashboard: 'storeDashboard',
  products: 'storeProducts',
  productsList: 'storeProductsList',
  productsReviews: 'storeProductsReviews',
  order: 'storeOrderList',
  wallet: 'storeWallet',
  advertise: 'storeAdvertise',
  advertiseList: 'storeAdvertiseList',
  advertiseFlow: 'storeAdvertiseFlow',
  advertiseAdd: 'advertiseAdd',
  setting: 'storeSetting',
  storeStore: 'storeStore',
  storeAuth: 'storeAuth',
  storeSafety: 'storeSafety',
  storeMyHome: 'storeMyHome',
  storeNotify: 'storeNotify',
  storeBenefit: 'storeBenefit',
  message: 'storeMessage',
  share: 'share',
  storeShare: 'storeShare',
  storeShareList: 'storeShareList',
  other: 'other',
  storeRule: 'storeRule'
}
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
import Const from '@/utils/const'
import ActivityBanner from '@/components/ActivityBanner.vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'layoutVue',
  components: {
    ShopOutlined,
    DashboardOutlined,
    UserOutlined,
    UploadOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    MessageOutlined,
    FundProjectionScreenOutlined,
    TransactionOutlined,
    ShareAltOutlined,
    CaretDownOutlined,
    RollbackOutlined,
    ActivityBanner
  },
  setup() {
    const state = reactive({
      selectedKeys: [MENU.dashboard],
      openKeys: [],
      collapsed: false
    })

    const { proxy } = getCurrentInstance()
    function change(language) {
      proxy.$i18n.locale = language.lang
      setLangauge(language.lang)
      console.log('切换语言', language)
    }

    return {
      ...toRefs(state),
      change
    }
  },
  data() {
    return {
      //菜单
      MENU,
      screenHeight: document.body.clientHeight,

      // 店铺信息
      store: {},

      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },
      list: this.$Const.LANGUAGE,

      //循环
      ajaxMsg: null,
      ajaxTime: 5000

      // msgCenter: {
      //   msg: 0,
      //   order: 0,
      //   comment: 1
      // }
    }
  },
  watch: {
    $route(newRoute) {
      this.setCurrent(newRoute)
    }
  },
  created() {
    this.setCurrent(this.$route)

    // 获取config
    this.language = JSON.parse(localStorage.getItem(Const.KEY_LANG))

    //获取店铺基本信息
    this.$api.store.getStoreInfo().then((res) => {
      this.store = res.data
    })

    // this.onSocket()
    this.$api.home.getBase().then((res) => {
      this.msgCenter = res.data
    })

    //获取基本信息
    this.$store.dispatch('base/getBase', {})
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          this.screenHeight = document.body.clientHeight
        })
      })()
    }
    // 定时获取消息通知
    // this.runAjaxMsg()
  },
  beforeUnmount() {
    // clearInterval(this.ajaxMsg)
  },
  methods: {
    /**
     * 异步循环获取消息
     */
    // async runAjaxMsg() {
    //   await this.$api.home.getBase().then((res) => {
    //     this.msgCenter = res.data
    //   })

    //   // let that = this
    //   // if (this.$socket.getReadyState() === 1) {
    //   //   this.$socket.send({ type: 'getBase', data: { method: 'storeGetBase' } })

    //   //   this.$socket.on('storeGetBase', function (data) {
    //   //     that.msgCenter = data
    //   //   })
    //   // }

    //   this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
    // },
    // onSocket() {
    //   //连接
    //   const that = this
    //   this.$socket.on('base', function (data) {
    //     that.msgCenter = data
    //   })
    // },
    /**
     * 切换语言
     */
    onLanguage(language) {
      this.language = language
      localStorage.setItem(Const.KEY_LANG, JSON.stringify(this.language))
      this.change(this.language)
      this.$router.go(0)
    },

    onMenu(vo) {
      this.$router.push({ name: vo.key })
    },
    setCurrent(route) {
      // console.log(route);
      this.selectedKeys = [route.meta.key, route.name]
      this.openKeys = [route.meta.name]
      console.log(this.selectedKeys, this.openKeys)
    },
    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem(Const.KEY_TOKEN)
      localStorage.removeItem(Const.KEY_INFO)
      this.isLogin = false
      // this.$socket.close()
      this.$emit('onSignout', [])
      this.$router.push({ name: 'index' })
    }
  }
})
</script>
<style scoped src="@/static/css/store-css.css"></style>

<style>
.al-header {
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.shop-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.shop-name {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shop-sid {
  font-size: 13px;
  font-weight: 400;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.d-box {
  display: flex;
  align-items: center;
  height: 25px;
}

.d-lang {
  margin-left: 5px;
  color: #9e9e9e;
}
.s-box {
  width: 100%;
  display: flex;
  align-items: center;
}

.fanh {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  width: auto;
  padding: 5px;
}
.fanh:hover {
  background-color: #f9f9f9;
}

.fanh1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  width: auto;
  padding: 5px;
}
.fanh1:hover {
  background-color: #f9f9f9;
}
</style>
