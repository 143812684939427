<template>
  <div class="nav-header">
    <span class="nv-title">{{$t('reviews.title')}}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px', minHeight: '800px' }">
    <div class="nav-content">
      <!-- <div>
        <a-space>
          <a-select size="large" v-model:value="queryParam.status" style="width: 200px" :options="statusList" :field-names="{ label: 'name', value: 'id' }" :placeholder="$t('store.order.status')"></a-select>
        </a-space>
      </div>
      <div :style="{ width: '400px' }">
        <a-input-search v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
      </div> -->
    </div>
    <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreOrders">
      <template #bodyCell="{ column, record }">
        <template v-if="record && column.key === 'name'">
          <div class="name">{{record.name}}</div>
        </template>
        <template v-if="record && column.key === 'comment'">
          <div class="comment">{{record.comment}}</div>
        </template>
        <template v-if="record && column.key === 'imgs'">
          <div v-for="(vo,i) in record.imgs" :key="i" class="img">
            <img :src="vo" @click="handlePreview(vo)" />
          </div>
        </template>
        <template v-if="record && column.key === 'attr'">
          <div style="display:flex;">
            <div v-for="(svo,sk) in record.attr" :key="sk" style="margin-right: 5px;">{{sk}}:{{ svo }}</div>
          </div>
        </template>
        <template v-else-if="column.key === 'action'">
          <div style="display: flex;">
            <div>
              <!--  <router-link :to="{name:'storeOrderDetail',query:{order_sn:record.order_sn}}">Detail</router-link>
              <a-divider type="vertical" /> -->
            </div>
          </div>
        </template>
      </template>
    </a-table>

    <!-- 预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
// import { computed } from "@vue/runtime-core";
export default {
  name: "orderVue",
  data () {
    return {
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          width: "5%",
        },
        {
          title: this.$t('reviews.product.name'),
          key: "name",
          dataIndex: "name",
          width: "10%",
        },
        {
          title: this.$t('reviews.user.name'),
          key: "nickname",
          dataIndex: "nickname",
          width: "10%",
        },
        {
          title: this.$t('reviews.star'),
          key: "star",
          dataIndex: "star",
          width: "10%",
        },
        {
          title: this.$t('reviews.comment'),
          key: "comment",
          dataIndex: "comment",
          width: "10%",
        },
        {
          title: this.$t('reviews.sku'),
          key: "attr",
          dataIndex: "attr",
          width: "10%",
        },
        {
          title: this.$t('reviews.imgs'),
          key: "imgs",
          dataIndex: "imgs",
          width: "12%",
        },
        {
          title: this.$t('reviews.created'),
          key: "create_time",
          dataIndex: "create_time",
          width: "10%",
        },
        // {
        //   title: this.$t('store.order.action'),
        //   key: "action",
        //   width: "15%",
        // },
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: "",
      },

      // 预览图片
      previewVisible: false,
      previewImage: '',
    };
  },
  created () {
    this.getStoreProductsReviews(this.pagination);
  },
  methods: {
    /**
    * 获取图片列表
    * @param {*} imgs
    */
    getfileList (imgs) {
      const list = []
      imgs.forEach((item, k) => {
        list.push({ uid: k, status: 'done', name: k + '.png', url: item })
      })
      return list
    },
    /**
     * 预览图片
     */
    previewCancel () {
      this.previewVisible = false
    },
    async handlePreview (url) {
      this.previewImage = url
      this.previewVisible = true
    },
    /**
     * 取消删除
     * @param {*} e
     */
    cancel (e) {
      console.log(e)
      this.$message.error('Click on No')
    },
    onSearch () {
      this.getStoreOrders(this.pagination);
    },
    /**
     * 获取产品评论
     * @param {*} parameter 
     */
    getStoreProductsReviews (parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize,
      };
      const requestParameters = Object.assign({}, pagination, this.queryParam);

      this.loading = true;

      let that = this;
      this.$api.store.getStoreProductsReviews(requestParameters).then((res) => {
        that.loading = false;
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize,
        };
        that.dataSource = res.data.data;
      });
    },
  },
};
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style scoped>
.name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
}
.comment {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
}

.img {
  float: left;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-right: 8px;
  border: 1px solid #ccc;
}

.img img {
  width: 100%;
}
</style>
