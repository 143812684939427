import request from '@/utils/request'

const api = {
  apply: 'loan/apply',
  getApplyLoanList: 'loan/getApplyLoanList',
  getRepayLoanList: 'loan/getRepayLoanList',
  getMyLoan: 'loan/getMyLoan',
  applyRepay: 'loan/applyRepay'
}
export default {
  applyRepay(parameter) {
    return request({
      url: api.applyRepay,
      method: 'post',
      data: parameter
    })
  },
  apply(parameter) {
    return request({
      url: api.apply,
      method: 'post',
      data: parameter
    })
  },
  getMyLoan(parameter) {
    return request({
      url: api.getMyLoan,
      method: 'post',
      data: parameter
    })
  },
  getApplyLoanList(parameter) {
    return request({
      url: api.getApplyLoanList,
      method: 'post',
      data: parameter
    })
  },
  getRepayLoanList(parameter) {
    return request({
      url: api.getRepayLoanList,
      method: 'post',
      data: parameter
    })
  }
}
