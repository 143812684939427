import request from "@/utils/request";

const api = {
  getMyAgent: "user/getMyAgent",
  getUserInfo: "user/getUserInfo",
  saveAddress: "user/saveAddress",
  rmAddress: "user/rmAddress",
  getAddress: "user/getAddress",
  getWallet: "user/getWallet",
  getOrderList: "user/getOrderList",
  getOrderDetail: "user/getOrderDetail",
  saveWishlist: "user/saveWishlist",
  saveFollow: "user/saveFollow",
  getWishList: "user/getWishList",
  getFollowList: "user/getFollowList",
  saveInfo: "user/saveInfo",
  savePasswd: "user/savePasswd",
};

export default {
  getMyAgent(parameter) {
    return request({
      url: api.getMyAgent,
      method: "post",
      data: parameter,
    });
  },
  getUserInfo(parameter) {
    return request({
      url: api.getUserInfo,
      method: "post",
      data: parameter,
    });
  },
  saveAddress(parameter) {
    return request({
      url: api.saveAddress,
      method: "post",
      data: parameter,
    });
  },
  rmAddress(parameter) {
    return request({
      url: api.rmAddress,
      method: "post",
      data: parameter,
    });
  },
  getAddress(parameter) {
    return request({
      url: api.getAddress,
      method: "post",
      data: parameter,
    });
  },
  getWallet(parameter) {
    return request({
      url: api.getWallet,
      method: "post",
      data: parameter,
    });
  },
  getOrderList(parameter) {
    return request({
      url: api.getOrderList,
      method: "post",
      data: parameter,
    });
  },
  getOrderDetail(parameter) {
    return request({
      url: api.getOrderDetail,
      method: "post",
      data: parameter,
    });
  },
  saveWishlist(parameter) {
    return request({
      url: api.saveWishlist,
      method: "post",
      data: parameter,
    });
  },
  saveFollow(parameter) {
    return request({
      url: api.saveFollow,
      method: "post",
      data: parameter,
    });
  },
  getWishList(parameter) {
    return request({
      url: api.getWishList,
      method: "post",
      data: parameter,
    });
  },
  getFollowList(parameter) {
    return request({
      url: api.getFollowList,
      method: "post",
      data: parameter,
    });
  },
  saveInfo(parameter) {
    return request({
      url: api.saveInfo,
      method: "post",
      data: parameter,
    });
  },
  savePasswd(parameter) {
    return request({
      url: api.savePasswd,
      method: "post",
      data: parameter,
    });
  },
};
