export default {
  'nav.account': 'Centro personale',
  'nav.language': 'Cinese semplificato',
  'signup.success': 'Iscrizione riuscita',

  //errore
  'contact.empty': 'Le informazioni di contatto non possono essere vuote',
  'phone.country.empty': 'Il prefisso telefonico non può essere vuoto',
  'phone.empty': 'Il telefono non può essere vuoto',
  'email.valid': "Inserisci l'email corretta",

  //base
  'base.success': 'successo',
  'base.save': 'Salva',
  'base.slide': 'Trascina il dispositivo di scorrimento per verificare',
  'base.send.code': 'Invia codice di verifica',
  'base.send.time': '({time}) reinvia codice di verifica',
  'base.send.success': 'Invio riuscito',
  'base.view.more': 'Visualizza altro',
  'base.Language': 'Lingua',
  'base.previous': 'Pagina precedente',
  'base.next': 'Pagina successiva',
  'base.total.page': 'Totale {page} pagine',
  'base.go.to.page': 'Vai alla pagina',
  'base.go': 'vai a',
  'base.followers': 'Follower',
  'base.follow': 'segui',
  'base.following': 'Seguito',
  'base.visit.store': 'Sfoglia negozio',
  'base.contact': 'Contattaci',
  'base.delivery': 'Tempo di consegna stimato: 1-7 giorni',
  'base.search': 'Cerca',
  'base.search.text': 'Inserisci ricerca parola chiave',
  'base.server': 'Servizio clienti online',

  //indice
  'text.categories.title': 'Categoria di prodotto',
  'text.welcome': 'Benvenuto allo shopping!',
  'text.wishlist': 'Lista Mi piace',
  'text.account': 'Centro personale',
  'text.orders': 'Ordini',
  'text.messages': 'Messaggi',
  'text.suggest.title': 'Offerta esclusiva',
  'text.suggest.content': 'Solo per i nuovi membri premium globali!',
  'text.super.deals': 'Ottimi prodotti, prezzi incredibili.',

  //iscrizione
  'signup.register': 'Registrati',
  'signup.signin': 'Accedi',
  'signup.store.signin': 'Accesso aziendale',
  'signup.sign.out': 'Esci',
  'signup.email': 'Indirizzo email',
  'signup.password': 'Password',
  'signup.qr.password': 'Conferma password',
  'signup.confirm.password': 'Conferma password',
  'signup.forgot.password': 'Password dimenticata',
  'signup.invitation': 'Codice di invito',
  'signup.char': 'Carattere',
  'signup.contains': 'Contiene numeri, lettere o simboli',
  'signup.qr.contains': 'Le due password non sono coerenti',
  'signup.create.account': 'Crea account',
  'signup.agree': 'Crea un account, accetti',
  'signup.member.agreement': 'Accordo di adesione',
  'signup.and': 'e',
  'signup.privacy.policy': 'Informativa sulla privacy',
  'signup.email.code': 'Verifica email',
  'signup.last.step': 'Ultimo passaggio',
  'signup.send.email': 'Inserisci il codice a 4 cifre inviato a {email}',
  'signup.modify.email': 'Modifica email',
  'signup.verify.email': 'Verifica email',
  'signup.have.store': 'Disponi di un account commerciante',
  'signup.goto.signin': 'Accesso aziendale',
  'signup.no.store': 'Nessun account commerciante',
  'signup.goto.store': 'Iscriviti commerciante',
  'signup.next': 'Avanti',
  'signup.your.email': 'la tua email',
  'signup.code.text': 'Codice di verifica',
  'signup.submit.signup': 'Registrati ora',
  'signup.smrz': 'Autenticazione con nome reale',
  'signup.derb': 'Secondo passaggio',
  'signup.qsrxm': 'Inserisci il nome del documento',
  'signup.qsrhm': 'Inserisci il numero ID',

  //dimenticato
  'forgot.title': 'Reimposta password',
  'forgot.btn.check': 'Verifica email',
  'forgot.reset.now': 'Ripristina ora',

  //negozio
  'store.info.open': 'Aperto {y} settimane',

  // piè di pagina
  'footer.great.value': 'Ottimo valore',
  'footer.great.value.desc': 'Offriamo prezzi competitivi per oltre 100 milioni di articoli.',
  'footer.shopping': 'Acquisti globali',
  'footer.shopping.desc': 'Spediamo in più di 200 paesi e regioni e il nostro sito Web è disponibile in 7 lingue.',
  'footer.safe.payment': 'Pagamento sicuro',
  'footer.safe.payment.desc': 'Paga con i metodi di pagamento più diffusi e sicuri al mondo.',
  'footer.shop.with.confidence': 'Acquista con fiducia',
  'footer.shop.with.confidence.desc': "La nostra politica di protezione dell'acquirente copre l'intero processo di acquisto.",
  'footer.help.center': 'Centro assistenza',
  'footer.help.center.desc': "Assistenza in qualsiasi condizione atmosferica per creare un'esperienza di acquisto fluida.",
  'footer.terms.conditions': 'Termini e condizioni',
  'footer.return.policy': 'Politica di reso',
  'footer.support.policy': 'Politica di supporto',
  'footer.privacy.policy': 'Informativa sulla privacy',
  'footer.be.seller': 'Diventa un venditore',
  'footer.apply.now': 'Applica ora',
  'footer.stay.connected': 'Resta connesso',

  'footer.about.us': 'Chi siamo',
  'footer.about.company': 'Profilo azienda',
  'footer.about.video': 'Video introduttivo',
  'footer.contact': 'Informazioni di contatto',

  'footer.my.account': 'Il mio account',
  'footer.my.logout': 'Esci',
  'footer.my.order': 'Cronologia ordini',
  'footer.my.wish': 'La mia lista dei desideri',
  'footer.my.join': 'Diventa un membro partner',
  'footer.email': 'Email',
  'footer.gfemail': 'Email ufficiale',
  'footer.fwemail': 'Email di servizio',
  'footer.address': 'Indirizzo',

  'apply.success': 'Applicazione riuscita',
  'apply.success.desc': "Applicazione eseguita correttamente, vai all'account di accesso",

  // categorie
  'category.title': 'Categorie correlate',
  'category.all': 'Tutte le categorie',

  //dettaglio
  'detail.store.home': 'Casa negozio',
  'detail.sale.items': 'Elenco prodotti',
  'detail.recommend': 'Consiglia',
  'detail.orders': 'Vendite',
  'detail.quantity': 'Quantità',
  'detail.pieces.available': 'Disponibile',
  'detail.delivery': 'Consegna',
  'detail.free.shipping': 'Spedizione gratuita',
  'detail.valuated.delivery': 'Arrivo previsto',
  'detail.days': 'giorni',
  'detail.buy.now': 'Acquista',
  'detail.add.to.cart': 'Aggiungi al carrello',
  'detail.buyer.protection': "Protezione dell'acquirente",
  'detail.money.guarantee': 'Garanzia di rimborso',
  'detail.refund.desc': "Ottieni un rimborso completo se l'articolo non è come descritto o non è stato consegnato",
  'detail.description': 'Descrizione del prodotto',
  'detail.customer.reviews': 'Recensioni dei clienti',
  'detail.specifications': 'Specifiche del prodotto',
  'detail.top.selling.products': 'Prodotti più venduti',
  'detail.recommended.for.you': 'Consigliato per te',
  'detail.sold': 'Vendite',
  'detail.receipt': 'Conferma ricevuta',
  'detail.receipt.title': "Confermi la ricezione dell'ordine? ",
  'detail.receipt.content': "Dopo la conferma, l'ordine di transazione è completato",
  'detail.comment': 'Commento',
  'detail.refund.title': 'Conferma la richiesta di rimborso',
  'detail.refund.content': "Dopo la conferma, l'ordine richiederà un rimborso",
  'detail.sqtk': 'Richiedi rimborso',

  //Confermare
  'confirm.shipping.address': 'Indirizzo del destinatario',
  'confirm.change': 'Modifica',
  'confirm.payment.methods': 'Metodi di pagamento',
  'confirm.summary': 'Saldo',
  'confirm.total.item.costs': 'Totale articoli',
  'confirm.total': 'Totale',
  'confirm.checkout': 'Vai alla cassa',
  'confirm.place.order': 'Effettua un ordine ora',
  'confirm.pay.now': 'Paga adesso',
  'confirm.order.desc': "Dopo aver fatto clic su 'Effettua ordine', confermo di aver letto e confermo",
  'confirm.order.policy': 'Tutti i termini e le politiche',
  'confirm.payment': 'Global Shopping garantisce la sicurezza delle tue informazioni e dei pagamenti',

  // indirizzo
  'address.title': 'Indirizzo di ricezione',
  'indirizzo.default': 'predefinito',
  'indirizzo.edit': 'Modifica',
  'indirizzo.delete': 'Elimina',
  'address.new': 'Crea un nuovo indirizzo',
  'indirizzo.contatto': 'Contatto',
  'indirizzo.indirizzo': 'Indirizzo',
  'indirizzo.telefono': 'Numero di telefono',
  'address.set.default': 'Imposta predefinito',
  'address.confirm': 'Conferma',
  'address.cancel': 'Annulla',
  'address.del.title': "Confermi di eliminare l'indirizzo?",
  'address.del.content': 'Sei sicuro di voler eliminare questo indirizzo di spedizione?',

  'payment.method': 'Metodo di pagamento',

  // Carrello della spesa
  'shopping.title': 'Carrello',
  'shopping.back.buy': 'Torna allo shopping speciale',
  'shopping.start.shopping': 'Inizia a fare acquisti',

  // membro
  'member.account': 'Centro personale',
  'member.overview': 'Panoramica',
  'member.orders': 'Ordini',
  'member.payment': 'Il mio portafoglio',
  'member.address': 'Indirizzo di ricezione',
  'member.wishlist': 'Lista Mi piace',
  'member.followlist': 'Elenco da seguire',
  'member.message.center': 'Centro messaggi',
  'member.setting': 'Impostazioni personali',
  'member.shop.info': 'Informazioni sul negozio',
  'member.shop.setting': 'Impostazioni negozio',
  'member.order.notify': 'Notifica nuovo ordine',
  'member.order.tips': "Inviando email all'email dell'account commerciante",

  // membro.ordine
  'member.order.title': 'Il mio ordine',
  'member.order.view.all': 'Visualizza tutto',
  'member.order.all': 'Tutti',
  'member.order.unpaid': 'non pagato',
  'member.order.paid': 'Pagato',
  'member.order.procurement': 'da acquistare',
  'member.order.seller.paid': 'Acquistato',
  'member.order.processing': 'da elaborare',
  'member.order.shipped': 'spedito',
  'member.order.return': 'Restituzione',
  'member.order.completed': 'Completato',
  'member.order.refund': 'Rimborsato',
  'member.order.all.time': 'tutto',
  'member.order.empty': 'Ancora nessun ordine',
  'member.order.date': 'data',
  'member.order.purchase.date': 'Acquisto',
  'member.order.cpoy': 'Copia',
  'member.order.id': 'Ordine',
  'member.order.detail': 'Dettagli ordine',
  'member.order.logistics': 'Informazioni logistiche',

  // membro.pagamento
  'member.payment.title': 'Il mio portafoglio',
  'member.wallet.balance': 'saldo del portafoglio',
  'member.crypto.recharge': 'Ricarica',
  'member.crypto.withdrawal': 'Prelievo',
  'member.crypto.bank': 'Carta bancaria',
  'member.wallet.record': 'registrazione del deposito di monete',
  'member.bankcard.record': 'Registri della carta bancaria',
  'member.withdrawal.record': 'Record di ritiro',
  'member.income.record': 'record di reddito',
  'member.transaction.record': 'Record della transazione',
  'member.wallet.freeze': 'Blocca fondi',
  'member.wallet.profit': 'Entrate stimate',

  //ricaricare
  'ricarica.valuta': 'Valuta',
  'recharge.protocol': 'Protocollo',
  'ricarica.qrcode': 'Codice QR',
  'recharge.address': 'Indirizzo portafoglio',
  'recharge.copy': 'Copia indirizzo',
  'recharge.ok.text': 'Conferma',
  'recharge.cancel.text': 'Annulla',

  //banca
  'bank.recharge.title': 'Ricarica carta di credito',
  'bank.title': 'Nome banca',
  'bank.name': 'nome',
  'bank.account': 'Conto',
  'bank.routing': 'Routing',
  'bank.code': 'codice',
  'bank.bankname': 'Nome della banca',
  'bank.bankaddress': 'Indirizzo della banca',
  'bank.ok.text': 'Avanti',
  'bank.ok.prev': 'Passaggio precedente',
  'bank.submit': 'Invia ora',
  'bank.amount': 'Importo di ricarica',
  'bank.amount.desc': "Inserisci l'importo della ricarica",
  'bank.type': 'tipo',
  'banca.usd': 'USD',
  'banca.eur': 'Euro',
  'bank.receipt.number': 'Numero ricevuta',
  'bank.receipt.number.desc': 'Inserisci il numero della ricevuta della transazione',
  'bank.credential.picture': 'Immagine delle credenziali',
  'bank.credential.picture.desc': "Carica l'immagine della credenziale",
  'bank.remark': 'Osservazione',
  'bank.upload': 'Carica immagini',
  'bank.text.title': 'Note',
  'bank.text.t1': 'Non sono necessarie osservazioni per il trasferimento, lascia vuoto',
  'bank.text.t2': 'Seleziona Hong Kong come paese della famiglia di Hong Kong, non aggiungere la Cina',
  'bank.text.t3': "Rimesse negli Stati Uniti, nei giorni feriali, rimesse verso l'Europa prima delle 15:00, le rimesse sono disponibili durante l'orario di lavoro della banca!",
  'bank.text.t4': "Il tempo di pagamento è T+1 e l'ultimo pagamento è T+3 in Europa",
  'bank.text.t5': 'Informare la piattaforma prima di trasferire denaro per confermare se il conto è disponibile. Se il conto viene chiuso, non verrà corrisposto alcun compenso.',
  'bank.text.t6': 'Deposito tramite carta bancaria. Prevalgono le informazioni del servizio clienti.',

  //lista banca
  'bank.list.title': 'Nome banca',
  'bank.list.code': 'Codice banca',
  'bank.list.amount': 'Importo del trasferimento',
  'bank.list.number': 'Numero ricevuta',
  'bank.list.img': 'Immagine del buono',
  'bank.list.status': 'Stato',
  'bank.list.time': 'ora',
  'bank.list.status1': 'In corso di revisione',
  'bank.list.status2': 'Riuscito',
  'bank.list.status3': 'Rifiutato',

  // Ritiro
  'withdrawal.address.desc': "Inserisci l'indirizzo del portafoglio!",
  'numero.prelievo': 'Numero',
  'withdrawal.real.number': 'arrivo effettivo',
  'withdrawal.number.desc': "Inserisci l'importo del prelievo!",
  'withdrawal.btn.all': 'tutto',
  'prelievo.saldo': 'Saldo',
  'withdrawal.commission': 'commissione',
  'prelievo.importo.effettivo': 'Accounted',
  'withdrawal.notice': 'Richiesta',
  'withdrawal.notice.text': "Prima del trasferimento, conferma che le informazioni sull'indirizzo di destinazione sono corrette. Una volta che le risorse sono state trasferite, non possono essere restituite.",
  'withdrawal.notice.content': '{name}({cp_name}) commissione: valore di mercato corrente {fee_rate}%/penna, standard minimo: {fee_min} {name}/penna',
  'withdrawal.submit': 'Invia',
  'withdrawal.choice': 'Scegli una criptovaluta',
  'withdrawal.yzm': 'Codice di verifica',
  'withdrawal.fs': 'Invia',
  'withdrawal.qsryzm': 'Inserisci il codice di verifica email',

  // ricevo
  'recive.method': 'Protocollo',
  'recive.amount': 'Importo',
  'recive.address': 'indirizzo',
  'recive.date': 'ora',
  'recive.status': 'Stato',
  'recive.create.at': 'Tempo di transazione',
  'recive.type': 'Tipo',
  'recive.befor': 'prima della transazione',
  'recive.balance': 'Saldo',
  'recive.freeze': 'Blocca',
  'recive.review': 'Revisione',
  'recive.success': 'Riuscito',
  'recive.reject': 'Rifiutato',

  // pubblicizza
  'advertise.title': 'Centro promozioni',
  'advertise.shop.title': 'Promozione negozio',
  'advertise.shop.status': 'Stato',
  'advertise.shop.expired': 'Scaduto',
  'advertise.shop.promotion': 'Promozione',
  'advertise.shop.expire.date': 'Data di scadenza',
  'advertise.shop.renew': 'Rinnovo annuncio',
  'advertise.shop.payable': 'Importo da pagare',
  'advertise.shop.explanation': "Spiegazione dell'annuncio",
  'advertise.shop.text': "Partecipa alla promozione pubblicitaria della piattaforma, aumenta l'esposizione del negozio e promuovi gli ordini di transazione",
  'advertise.shop.paynow': 'Rinnova ora',
  'advertise.shop.modal.title': 'Conferma pagamento',
  'advertise.shop.modal.desc': 'Confermato pagamento della quota di promozione',
  'advertise.shop.modal.btn': 'Conferma pagamento',

  //Lista dei desideri
  'lista dei desideri.title': 'Lista dei desideri',
  'wishlist.delete': 'Elimina',
  'wishlist.orders': 'Vendite',

  // Segui elenco
  'followlist.title': 'Follow List',
  'followlist.delete': 'Elimina',
  'followlist.follow': 'Segui',

  // negozio
  'store.dashboard': 'Dashboard',
  'store.products': 'Prodotti',
  'store.products.list': 'Elenco prodotti',
  'store.products.reviews': 'Recensioni prodotti',
  'store.orders': 'Ordini',
  'store.wallet': 'Portafoglio',
  'store.message': 'Centro messaggi',
  'store.setting': 'impostazione',
  'store.order.total.profit': 'Reddito operativo totale stimato',

  //pannello di controllo
  'dashboard.store.hour.views': 'Visite in tempo reale',
  'dashboard.store.today.views': 'Visite di oggi',
  'dashboard.product.total': 'Prodotto totale',
  'dashboard.product.today': 'Nuovo oggi',
  'dashboard.order.total': 'Ordine totale',
  'dashboard.sales.total': 'Fatturato stimato',
  'dashboard.sales.real': 'Vendite reali',
  'dashboard.sales.pay': 'Pagamento totale',
  'dashboard.sales.profit': 'Profitto totale',
  'dashboard.commission.total': 'Entrate totali',
  'dashboard.commission.today': 'Guadagni di oggi',
  'dashboard.order.sales': 'Vendite di prodotti',
  'dashboard.sales.list': 'Classifica vendite',
  'dashboard.goods.cate.rate': 'Rapporto di classificazione del prodotto',
  'dashboard.goods.wish': 'Mi piace la classifica dei prodotti',
  'cruscotto.gennaio': 'Gennaio',
  'dashboard.february': 'Febbraio',
  'dashboard.march': 'Marzo',
  'dashboard.april': 'Aprile',
  'dashboard.may': 'Maggio',
  'cruscotto.giugno': 'giugno',
  'dashboard.july': 'Luglio',
  'cruscotto.agosto': 'Agosto',
  'dashboard.september': 'Settembre',
  'dashboard.october': 'Ottobre',
  'dashboard.november': 'Novembre',
  'dashboard.december': 'Dicembre',

  // prodotti
  'products.add.new': 'Aggiungi nuovo prodotto',
  'products.add.from.warehouse': 'Aggiungi prodotti dal magazzino',
  'prodotti.delete': 'Elimina',
  'prodotti.add': 'Aggiungi',
  'products.table.img': 'immagine',
  'products.table.name': 'nome prodotto',
  'products.table.category': 'Categoria',
  'products.table.wish': 'mi piace',
  'prodotti.table.stock': 'stock',
  'products.table.price': 'Prezzo',
  'products.table.profit': 'Profitto',
  'products.table.action': 'Azione',
  'products.search.category': 'Ricerca per categoria',
  'products.back.product': "Torna all'elenco dei prodotti",
  'products.total': 'Totale merci',
  'prodotti.yes': 'Sì',
  'products.no': 'Annulla',
  'products.batch.add': 'Aumento batch',
  'products.ask.add': 'Sei sicuro di voler aggiungere prodotti?',
  'products.batch.delete': 'Eliminazione batch',
  'products.ask.delete': 'Sei sicuro di voler eliminare il prodotto?',
  'prodotti.top': 'In alto',
  'products.syzd': 'Home Page Top',
  'products.zdwz': 'Prima posizione',
  'prodotti.t1': 'Promozione',
  'prodotti.t2': 'Preferito',
  'prodotti.t3': 'Boutique',
  'prodotti.t4': 'nessuno',

  // recensioni
  'reviews.title': 'Recensioni del prodotto',
  'reviews.product.name': 'Prodotto',
  'reviews.user.name': 'Utente',
  'recensioni.star': 'Valutazione',
  'reviews.comment': 'Commenta il contenuto',
  'reviews.sku': 'Specifiche del prodotto',
  'reviews.imgs': 'Atlante',
  'reviews.created': 'Data',

  // store.order
  'store.order.purchase': 'Acquista ora',
  'store.order.purchase.desc': 'Sei sicuro di voler pagare per questo prodotto?',
  'store.order.purchase.yes': 'Paga adesso',
  'store.order.purchase.no': 'Annulla',
  'store.order.desc': "L'acquisto di questo ordine richiede il pagamento e otterrai vantaggi una volta completato l'ordine. ",
  'store.order.no': 'Numero ordine',
  'store.order.number': 'Quantità',
  'store.order.buyer': 'Acquirente',
  'store.order.total': 'Importo totale',
  'store.order.will.earning': 'Reddito',
  'store.order.profit': 'Profitto',
  'store.order.dividends': 'Dividendi',
  'store.order.payment.status': 'Stato pagamento',
  'store.order.seller.buy.status': 'Stato acquisto',
  'store.order.status': 'Stato ordine',
  'store.order.date': 'data ordine',
  'store.order.purchase.date': 'Data di acquisto',
  'store.order.action': 'Operazione',
  'store.order.purchase.amount': 'Importo di acquisto',

  //reddito
  'income.create.at': 'Tempo record',
  'income.order.sn': 'Numero ordine',
  'income.realpay': 'Importo ordine',
  'reddito.profitto': 'reddito',

  //Collocamento
  'setting.avatar': 'Avatar aziendale',
  'setting.upload': 'Carica',
  'setting.shop.name': 'Nome negozio',
  'setting.shop.phone': 'Contatto telefonico',
  'setting.shop.address': 'Indirizzo negozio',
  'setting.shop.save': 'Salva',
  'setting.upload.pic': 'Carica immagine',
  'setting.send.pic': 'Invia immagine',

  //venditore
  'beseller.title': 'Fai domanda per diventare un commerciante',
  'beseller.account': "Informazioni sull'account",
  'beseller.store': 'Informazioni negozio',
  'beseller.store.name': 'Nome negozio',
  'beseller.store.address': 'Indirizzo negozio',

  //salvacasa
  'store.home.title': 'Impostazioni home page',
  'store.home.topimg': 'immagine superiore',
  'store.home.banner': 'Immagine carosello',
  'store.home.up3': 'Osservazioni: almeno 3 caricamenti',
  'store.upload.more': 'Carica altre foto',
  'store.home.columns': 'Colonne',
  'store.home.bgimg': 'Immagine di sfondo',
  'store.goods.remark': 'Nota: 5 articoli per riga, 10 articoli per piatto al massimo',
  'store.home.select': 'Seleziona prodotto',
  'store.home.add': 'Aggiungi colonna',

  //informazione personale
  'setting.update.user': 'Informazioni personali',
  'setting.update.info': 'Modifica informazioni',
  'setting.user.avatar': 'Avatar utente',
  'setting.user.nickname': 'Soprannome utente',
  'setting.user.nickname.desc': "Inserisci il nickname dell'utente",

  'setting.safe.title': 'Informazioni sulla sicurezza',
  'setting.user.passwd': 'Cambia password',
  'setting.passwd.title1': 'Vecchia password',
  'setting.passwd.desc1': 'Inserisci la vecchia password',
  'setting.passwd.title2': 'Nuova password',
  'setting.passwd.desc2': 'Inserisci una nuova password',
  'setting.passwd.title3': 'Conferma password',
  'setting.passwd.desc3': 'Inserisci una password di conferma',
  'setting.invitation': 'Invita amici',
  'setting.copy': 'Copia',

  'adv.add.products': 'Aggiungi prodotti promozionali',
  'adv.list.title': 'Promozione del prodotto',
  'adv.type': 'Tipo di promozione',
  'adv.begin.time': 'Tempo di promozione',
  'adv.end.time': 'Ora di fine',
  'adv.status': 'Stato',
  'adv.status.s1': 'Promozione',
  'adv.status.s2': 'Fine',
  'adv.add.back': "Torna all'elenco promosso",
  'adv.recharge': 'Ricarica punti promozione',
  'adv.select.goods': 'Seleziona prodotti',
  'adv.select.btn': 'Prodotti in promozione',
  'adv.recharge.title': 'Ricarica punti promozione',
  'adv.recharge.balance': 'Saldo punti',
  'adv.point': 'punto',
  'adv.point.rate': 'Rapporto punti',
  'adv.recharge.amount': 'Importo di ricarica',
  'adv.recharge.input.amount': 'Inserisci importo di ricarica',
  'adv.select.mod': 'Seleziona pannello',
  'adv.mod1': 'Colonne consigliate',
  'adv.mod2': 'Colonna preferita',
  'adv.mod3': 'Colonna saldi',
  'adv.mod4': 'Lista eccellente',
  'adv.mod5': 'Elenco di classificazione',
  'adv.mod6': 'Promozione dettagliata',
  'adv.ge': 'Quantità',
  'adv.xs': 'ora',
  'adv.xzbk': 'Seleziona piatto',
  'adv.syme': 'Quota rimanente',
  'adv.tgsc': 'Durata della promozione',
  'adv.tgjg': 'Prezzo promozionale',
  'adv.syye': 'Saldo corrente',
  'adv.tgye': 'Saldo promozione',
  'adv.yj1': 'previsto',
  'adv.yj2': 'Avvia promozione',
  'adv.pay.point': 'Punti di consumo',

  'wallet.ykcsxf': 'La commissione di gestione è stata detratta',
  'wallet.sxf': 'Commissione di servizio',
  'wallet.zxje': 'Importo minimo',
  'wallet.24hxe': 'limite di 24 ore',
  'wallet.desc.text': "In nessun caso, il funzionario non ti chiederà di trasferire denaro su un conto, né ti chiederà un codice di verifica. Non partecipare ad attività illegali come l'acquisto per conto di altri, il riciclaggio di denaro e la raccolta fondi illegale e fai attenzione alle frodi online",
  'wallet.cunbi.text': 'Puoi depositare solo {name} a questo indirizzo, gli altri asset non verranno recuperati',

  'credit.title': 'Limite di credito',
  'credit.ed': 'Quota',
  'credit.hk': 'Rimborso',
  'credit.kyye': 'Saldo disponibile',
  'credit.dqqk': 'Attuali arretrati',
  'credit.hkje': 'Importo del rimborso',
  'credit.hkje.desc': "Inserisci l'importo del rimborso",
  'credit.cgje': 'Importo di acquisto',
  'credit.qrz': "Vai all'autenticazione",
  'credit.srxyj': "Inserisci l'importo del pagamento del credito",

  'store.gzl': 'Quantità di attenzione',
  'store.wxz': 'Illimitato',

  'auth.smrz': 'Autenticazione con nome reale',
  'auth.rzlx': 'Digita',
  'auth.sfzm': "carta d'identità anteriore",
  'auth.sffm': "Il retro della carta d'identità",
  'auth.zsxm': 'vero nome',
  'auth.zjhm': 'Numero documento',
  'auth.yyzz': 'Licenza commerciale',
  'auth.gsmc': 'Nome azienda',
  'auth.shz': 'Controllo',
  'auth.ytg': 'Riuscito',
  'auth.ybh': 'Rifiutato',
  'auth.zt': 'stato',
  'auth.gr': 'Personale',
  'auth.gs': 'Azienda',
  'auth.ljtj': 'Richiedi autenticazione',
  'auth.wrz': 'Non autenticato',

  'credit.qts': 'Visualizza descrizione',
  'store.spss': 'Limite massimo merce',
  'store.dpfh': 'Memorizza dividendi',
  'store.qydj': 'Livello di capitale',
  'store.level': 'livello',
  'store.jian': 'pezzi',

  'order.notify.email': "A causa del controllo del rischio e-mail, le e-mail di promemoria dell'ordine della piattaforma vengono inviate frequentemente e verranno intercettate. Per riceverle in tempo, si consiglia di aggiungere {e} al login lista bianca email",

  'setting.sjxx': 'Informazioni commerciali',
  'setting.smrz': 'Autenticazione con nome reale',
  'setting.aqzx': 'Centro sicurezza',
  'setting.tzxx': 'Informazioni di notifica',
  'setting.dpzx': 'Decorazione home page',
  'setting.sjqy': 'Interessi commerciali',
  'setting.sjdjqy': 'Descrizione dei vantaggi a livello aziendale',

  'setting.fhbky': 'Il dividendo attuale non è disponibile',
  'setting.spgzyzy': 'Regole e linee guida del negozio',

  'desc.dqqy': 'Patrimonio corrente',
  'desc.xjqy': 'Interessi subordinati',
  'desc.sjqy_0': 'Limite di credito negozio',
  'desc.sjqy_1': 'Memorizza dividendi',
  'desc.sjqy_2': 'Numero di prodotti che possono essere pubblicati',

  'share.yqlb': 'Lista di inviti',
  'share.zmj': 'Venditore totale',
  'share.zdd': 'Ordine totale',
  'share.zsy': 'Reddito totale di intermediazione',
  'share.wcl': 'non elaborato',
  'share.ycl': 'Elaborato',
  'share.ywc': 'Completato',
  'share.dpmc': 'Nome negozio',
  'share.ddl': 'Quantità ordine',
  'share.jjsy': 'Reddito di intermediazione',
  'share.zts': 'Numero totale',

  'chat.state': 'stato',
  'chat.zx': 'In linea',
  'chat.ljz': 'Connessione',

  'bind.wallet.title': 'Indirizzo di prelievo',
  'bind.address.title': 'Aggiungi indirizzo',
  'bind.bj': 'Modifica',
  'bind.sc': 'Elimina',
  'bind.qrsc': 'Sei sicuro di voler eliminare questo indirizzo?',
  'bind.qbdz': 'Indirizzo portafoglio',
  'bind.tjsj': 'Aggiungi ora',
  'bind.cz': 'Operazione',
  'withdrawal.address.choice': "Scegli l'indirizzo per il prelievo!",

  'order.sxsj': 'Tempo effettivo',
  'order.ljsj': 'immediatamente efficace',
  'order.dssj': 'Efficacia temporizzata',

  'store.st.zt': 'Stato negozio',
  'store.st.zc': 'normale',
  'store.st.zc.desc': 'Il tuo negozio è normale, continua così',
  'store.st.yc': 'Eccezione',
  'store.st.yc.desc': 'Il tuo negozio ha degli ordini anomali o ha ricevuto reclami da parte dei clienti',
  'store.st.dj': 'Blocca',
  'store.st.dj.desc': 'Il tuo negozio ha un certo rischio operativo e i fondi sono congelati',
  'store.st.jy': 'disabilitato',
  'store.st.jy.desc': 'Il tuo negozio ha violato gli accordi e le regole relative alla piattaforma ed è stato disabilitato',

  'task.sy': 'Pagina iniziale',
  'task.rwlbo': 'Elenco attività',
  'task.rwxqo': 'Dettagli attività',
  'task.ljcy': 'Partecipa ora',
  'task.gdrw': 'Altre attività',
  'task.rwlb': 'Elenco delle attività di cattura',
  'task.qbrw': 'Tutte le attività',
  'task.wcyd': 'Ho partecipato a',
  'task.qdz': 'Ricezione ordini',
  'task.ywc': 'Completato',
  'task.djy': 'da scambiare',
  'task.ddje': 'Importo ordine',
  'task.cyrs': 'Numero di partecipanti',
  'task.ewsy': 'Ulteriori vantaggi',
  'task.jzsj': 'Scadenza',
  'task.ckxq': 'Visualizza dettagli',
  'task.rwxq': 'Dettagli attività',
  'task.cylb': 'Lista di partecipazione',
  'task.qdcyrw': "Sei sicuro di voler partecipare a questa attività dell'ordine?",
  'attività.sd': 'Sì',
  'attività.bue': 'no',

  'task.wdsy': 'Entrate',
  'task.wdfh': 'Dividendi',
  'task.wdqd': 'Ottieni ordine',
  'task.dd.leixin': 'Tipo di ordine',
  'task.dd.qdrw': 'Attività ordine rapido',
  'task.dd.ptdd': 'Ordine ordinario',

  'flow.lltg': 'Promozione flusso',
  'flow.gmll': 'Acquista pacchetto dati',
  'flow.dqtc': 'Pacchetto corrente',
  'flow.tc1': 'Pacchetto',
  'flow.sy2': 'rimanente',
  'flow.tian3': 'giorno',
  'flow.jlzs': 'Numero totale di record',
  'flow.lltc': 'Pacchetto flusso',
  'flusso.ll': 'flusso',
  'flow.gmsj': 'Tempo di acquisto',
  'flow.tcjg': 'Prezzo pacchetto',
  'flow.sjzf': 'pagamento effettivo',
  'flow.tcsm': 'Descrizione pacchetto',
  'flow.tcsm.sm': "L'acquisto di questo pacchetto aumenterà l'esposizione del negozio e otterrà più traffico clienti. Al fine di ottenere più ordini e aumentare le entrate del negozio. ",

  'flow.tcmc': 'Nome pacchetto',
  'flow.gmsc': "Durata dell'acquisto",
  'flow.zje': 'Importo totale',
  'flow.jzsj': 'Scadenza',
  'flow.gmrq': 'Data di acquisto',
  'flow.tian1': 'giorno',
  'flow.zhou1': 'settimana',
  'flow.yue1': 'mese',

  'share.dj': 'livello',
  'share.hz': 'Trasferisci',
  'share.hzd': 'Trasferisci a',
  'share.qbye': 'saldo del portafoglio',
  'share.tgje': 'Importo della promozione',
  'share.hzje': 'Importo del trasferimento',
  'share.qsrhzje': "Inserisci l'importo del trasferimento",

  '_inv._title': "Descrizione dell'invito",
  '_inv._t1': '1. Invito e relazione invitata',
  '_inv._desc1': 'A invita B, B invita C, C invita D. Il livello più alto è 4, se D invita di nuovo E, allora E non ha nulla a che fare con A. ',
  '_inv._t2': "2. L'invitante riceve una ricompensa",
  '_inv._desc2': "A ottiene il 4% dell'importo totale completato dall'ordine B, A ottiene il 2% dell'importo totale completato dall'ordine C e A ottiene l'1% dell'importo totale completato dall'ordine D",

  '_index._gm': 'Acquista',
  '_index._sm': "Descrizione: aumenta l'esposizione del negozio e attira traffico",

  '_shop._update': '{n} volte/mese, modificato {m} volte nel mese corrente',

  '_chat._hc': 'Ritira',

  '_footer._suom': 'Descrizione della politica',

  '_footer._platform._qualification': 'Qualifica aziendale',
  '_store._ptzz': 'Qualifiche relative alla piattaforma Sears Global',

  '_abt._gywm': 'Chi siamo',
  '_abt._syms': 'Modello di business',
  '_abt._qyzr': 'Responsabilità aziendale',
  '_abt._lxwm': 'Contattaci',

  '_wsj._wsjms': "Festeggia Halloween con stile nel nostro spettrale negozio di Halloween. Che tu stia cercando un dolcetto o uno scherzetto durante questa stagione spettrale, la nostra collezione di costumi, decorazioni e accessori di Halloween ha qualcosa per tutta la famiglia. Crea una dichiarazione agghiacciante con le nostre collezioni da donna e da uomo, oppure libera l'immaginazione di tuo figlio con costumi in maschera. Da graziosi articoli per la casa e giocattoli indimenticabili, a prodotti di bellezza e cosmetici glamour, trova i pezzi perfetti per celebrare la festa più emozionante dell'anno. ",

  '_wsj._b1': 'Decorazioni per la casa autunnali',
  '_wsj._b2': 'Zucca',
  '_wsj._b3': 'Casa',
  '_wsj._b4': 'Per bambini',
  '_wsj._b5': 'Bambino',
  '_wsj._b6': 'Donne',
  '_wsj._b7': 'Costume',
  '_wsj._b8': 'Personalizzato',

  '_wsj._cj': 'Partecipa',
  '_wsj._gz': 'Regole di attività',
  '_wsj._flss': 'Ricerca per categoria',
  '_wsj._wsnz': 'Abbigliamento femminile per Halloween',
  '_wsj._wsn': 'Abbigliamento da uomo per Halloween',
  '_wsj._wst': 'Abbigliamento per bambini di Halloween',
  '_wsj._wszs': 'Decorazione di Halloween',
  '_wsj._wslp': 'Regalo di Halloween',

  '_wsj._desc1': '"Speciale Halloween, Dolcetto o Scherzetto!" Questo ottobre abbiamo preparato una festa di carnevale per te! Vieni ad accaparrarti tutti i tipi di prodotti spaventosi e scioccanti, grandi sconti ti aspettano! ',
  '_wsj._lkm': 'Vai a fare shopping',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween sta arrivando',
  '_wsj._mzbt': "Calendari dell'avvento bellezza",
  '_wsj._mznr': "Il conto alla rovescia per il periodo più bello dell'anno è appena arrivato con calendari dell'avvento adatti a tutti.",

  '_chr._tt1': 'Acquista tutto il Natale',
  '_chr._tt2': 'Decorazioni natalizie',
  '_chr._tt3': 'Acquista tutti i regali',
  '_chr._tt4': 'Acquista tutta la biancheria da notte di Natale',

  '_chr._dt1': 'Il periodo che precede il Natale è magico quanto il grande giorno. Quindi sistema i regali, decora le sale e prepara il pigiama abbinato per la magia del Natale.',

  '_chr._dtt1': 'Abbigliamento natalizio',
  '_chr._dtt2': 'Natale in famiglia abbinato',
  '_chr._dtt3': "Calendari dell'Avvento",
  '_chr._dtt4': 'Natale a casa',
  '_chr._dtt5': 'Negozio accogliente',
  '_chr._dtt6': 'Mobili in tempo per Natale',

  '_chr._tre1': 'Tendenza attuale',
  '_chr._tre2': "Aggiungi un po' di divertimento festivo alla tua casa con le tendenze che tutti amano.",
  '_chr._tre3': 'Pan di zenzero',
  '_chr._tre4': 'Budino di Natale',
  '_chr._tre5': 'Natale Gonks',

  '_chr._ttre1': 'Carte e carta da regalo',
  '_chr._ttre2': 'Giocattoli e giochi',
  '_chr._ttre3': 'Regali con cibo e bevande',
  '_chr._ttre4': 'Riempi calze',
  '_chr._ttre5': 'Babbo Natale segreto',
  '_chr._ttre6': 'Il primo Natale del bambino',
  '_chr._ttre7': 'Regali di bellezza',
  '_chr._ttre8': 'Regali premium',
  '_chr._ttre9': 'Regali personalizzati',

  '_sdj._sdnz': 'Abbigliamento da donna',
  '_sdj._sdn': 'Abbigliamento da uomo',
  '_sdj._sdetlw': 'regalo',
  '_sdj._sdzs': 'Decorazione',
  '_sdj._sdjj': 'giocattolo',

  '_sdj._title1': 'Natale sta arrivando',
  '_sdj._desc1': '"Natale: la stagione del dono e della gratitudine." Questo dicembre abbiamo preparato per te una calda e luminosa festa in famiglia! Vieni e prendi una varietà di prodotti luminosi e caldi con grandi sconti che ti aspettano! ',

  '_ssd._lhpp': 'Pagamento flessibile',
  '_ssd._lzhq': 'Origine globale',
  '_ssd._pwyx': 'valutato come eccellente',
  '_ssd._qsth': 'Reso facile',
  '_ssd._jgppcn': 'Corrispondenza prezzo e impegno',
  '_ssd._ztbt': 'Crea uno sportello unico globale',
  '_ssd._yzspl': 'Approvvigionamento di inventario direttamente da oltre 8.000 marchi indipendenti',
  '_ssd._zcgm': 'Registrati per acquistare',
  '_ssd._zccs': 'Registrati per la vendita',

  '_ssd._dlwz': 'Accedi e registrati',

  '_ssd._qjrgwc': 'Aggiungi tutto al carrello',

  '_zddy._gzlb': 'Lista di titoli',
  '_zddy._zsersxs': 'Venduto su Searsglobal',
  '_zddy._zccwsj': 'Registrati come commerciante',
  '_zddy._dlyh': 'Accedi',
  '_zddy._zcyh': 'Registrati',
  '_zddy._xyh': 'Nuovo utente',
  '_zddy._gwc': 'Carrello',
  '_zddy._znxx': 'Messaggio',
  '_zddy._zshy': 'I più popolari',
  '_zddy._ddl': 'Ordine',
  '_zddy._mysj': 'Nessun dato',
  '_zddy._ckgd': 'Vedi di più',
  '_zddy._tjsp': 'Prodotti consigliati',
  '_zddy._rmsp': 'Prodotti popolari, nuove tendenze, maggiori opportunità di acquisto',
  '_zddy._xsp': 'Nuovo prodotto',
  '_zddy._jlzzr': 'Gli articoli più interessanti nelle ultime due settimane',
  '_zddy._ljgm': 'Acquista ora',
  '_zddy._gdsp': 'Prodotti più popolari',
  '_zddy._ssbt': 'Cerca',
  '_zddy._ssgjz': 'Sto cercando..',

  '_zddy._bdhb': 'Valuta locale',
  '_zddy._xzbdhb': 'Seleziona valuta locale',
  '_zddy._zje': 'Importo totale',
  '_zddy._xzzffs': 'Scegli il metodo di pagamento più adatto a te',
  '_zddy._jmhb': 'Criptovaluta',

  '_zddy._cp': 'prodotto',
  '_zddy._sj': "Uomo d'affari",
  '_zddy._mj': 'Venditore',
  '_zddy._fhsy': 'Torna alla home page',

  '_sjsy._ddpl': 'Elenco ordini',
  '_yhqd._czbz': 'Si prega di contattare il servizio clienti dedicato per confermare le informazioni di ricarica prima di ricaricare.',
  '_yhqd._lxkf': 'Contattare il Servizio Clienti',
  '_yhqd._qsrje': "Inserisci l'importo della ricarica",
  '_yhqd._wrhyhtd': 'Nessun accesso alla banca',
  '_yhqd._yhzz': 'Trasferimento bancario',
  '_yhbt._yhlx': 'Tipo di banca',
  '_zcyz._sjdxyz': 'Verifica SMS mobile',
  '_zcyz._sjhm': 'numero di telefono',
  '_zcyz._srsjhm': 'Inserisci il numero di cellulare',
  '_zzsd._qsrzzje': "Inserisci l'importo del bonifico",
  '_zzsd._zzje': 'importo del trasferimento',
  '_txcd._qsrtxje': "Inserisci l'importo del prelievo",
  '_txcd._skr': 'Beneficiario',
  '_txcd._skr._ms': 'Inserisci il nome del titolare della carta',
  '_txcd._skzh': 'Numero del conto di pagamento',
  '_txcd._skzh._ms': 'Inserisci il numero del conto di pagamento',
  '_txcd._tjyhk': 'Aggiungi una nuova carta bancaria',
  '_txcd._txje': 'Importo del prelievo',
  '_txcd._wdyhdz': 'Indirizzo bancario',
  '_txcd._yhdm': 'Codice programma',
  '_txcd._yhdm._ms': 'Inserisci il codice bancario',
  '_txcd._yhdz': 'indirizzo',
  '_txcd._yhdz._ms': 'Inserisci il tuo indirizzo bancario',
  '_txcd._yhlx': 'tipo',
  '_txcd._yhlx._ms': 'Inserisci il tipo di carta bancaria',
  '_txcd._yhly': 'percorso',
  '_txcd._yhly._ms': 'Inserisci il percorso bancario',
  '_txcd._yhmc': 'Banca',
  '_txcd._yhmc._ms': 'Inserisci il nome della banca',
  '_txcd._yhtx': 'Prelievo bancario',

  '_jys._gmjmhb': 'Acquista criptovaluta',
  '_jys._gmzy': 'Puoi registrare un account e acquistare criptovaluta visitando le seguenti borse e in base alle politiche attuali del tuo Paese. Quindi ricarica sul conto della piattaforma! ',

  '_kdydj._bt': 'Apri un negozio e vinci grandi premi',
  '_kdydj._xyzbt': 'La fortuna continua a girare',
  '_kdydj._yx': 'Pompa {n} volte',
  '_kdydj._xh': 'Consumo {n} punti',

  '_kdydj._jf': 'Punti:',
  '_kdydj._zjjl': 'Record di vittoria',

  '_kdydj._hdgz': 'Regole di attività',
  '_kdydj._qx': 'Per favore',
  '_kdydj._dl': 'Accedi',

  '_kdydj._mjdl': 'Accesso venditore',
  '_kdydj._yxh': 'E-mail',
  '_kdydj._yxhsm': 'Inserisci la tua email',
  '_kdydj._yxhmm': 'Inserisci la password',
  '_kdydj._rgmyzh': 'Se non hai un account',
  '_kdydj._djzc': 'Fai clic per registrarti',
  '_kdydj._wjmm': 'Password dimenticata',

  '_kdydj._wdjp': 'Il mio premio',
  '_kdydj._zjts': 'Suggerimenti vincenti',
  '_kdydj._jx': 'Continua',
  '_kdydj._czl': 'Vinto',

  '_kdydj._ljjl': 'Ricompensa cumulativa',
  '_kdydj._ycy': 'Partecipato: {n} volte',
  '_kdydj._cy': 'Partecipa {n} volte',
  '_kdydj._lq': 'Ricevi',
  '_kdydj._ylq': 'Ricevuto',
  '_kdydj._wdd': 'Non raggiunto',

  '_dk._dkbt1': "Il prestito all'imprenditorialità risolve il problema di prendere in prestito denaro",
  '_dk._dkbt2': 'Fornire prestiti imprenditoriali e servizi di circolazione dei capitali',
  '_dk._dkbt3': "Ti aiuteremo a risolvere problemi di prestito come fondi limitati, nessun posto dove prendere in prestito denaro e imbarazzo nel chiedere denaro in prestito agli amici. Forniamo servizi professionali durante tutto il processo e rispondiamo alle tue domande in qualsiasi momento L'obiettivo è rendere ogni prestito trasparente, consentendo ai clienti di usufruirne in modo rapido e sicuro. ",
  '_dk._zxsq': 'Applicazione online',
  '_dk._wddk': 'Il mio prestito',
  '_dk._aqwy': 'Sicurezza e senza preoccupazioni',
  '_dk._lhed': 'Quota flessibile',
  '_dk._lhedb': 'Importo: da 5,00 a 500.000, interessi bassi, metodi di rimborso flessibili, nessuna commissione anticipata, prestito online, nessuna chiamata di revisione, riservatezza delle informazioni e prestito in giornata. ',
  '_dk._jsfk': 'Prestito veloce',
  '_dk._jsfkb': "Supera la revisione nell'ora più veloce e il pagamento verrà effettuato immediatamente dopo la revisione. ",
  '_dk._aqww1': 'Sicuro e senza preoccupazioni',
  '_dk._aqww1b': 'Nessun mutuo ipotecario, nessun deposito richiesto, nessuna richiesta di restituzione, questa è una questione privata. ',

  '_dk._gywm': 'Chi siamo',
  '_dk._gywm1': `Ci impegniamo a fornire servizi finanziari, basandoci sulla filosofia aziendale "il cliente prima di tutto" e con la missione di risolvere i problemi di rotazione del capitale dei clienti. Sin dalla sua fondazione, l'azienda ha aiutato innumerevoli clienti a risolvere il turnover del capitale i problemi.`,
  '_dk._gywm2': 'Importo: da 500 a 500.000, interessi bassi, metodi di rimborso flessibili, nessuna commissione anticipata, prestito online, nessuna chiamata di revisione, riservatezza delle informazioni e prestito in giornata. ',
  '_dk._gywm3': 'Se non vuoi prendere in prestito denaro da compagni di classe e amici, per favore vieni da noi. ',
  '_dk._gywm4': 'Impegnato nei servizi di prestito agli imprenditori',
  '_dk._gywm5': 'Risolvi i tuoi problemi finanziari',
  '_dk._hzhb': 'Partner - Piattaforma di prestito online',
  '_dk._smwda': 'Gli utenti della stazione globale SearsGlobal Business provengono da 112 paesi in tutto il mondo e utilizzano la criptovaluta USDT/ETH/BTC/TRX per il regolamento. La criptovaluta è un modo di fare trading senza confini, consentendo transazioni istantanee a basso costo in tutto il mondo senza attese e senza commissioni internazionali. ',

  '_dk._yjwa': 'SearsGlobal Business© Co., Ltd. 2022-2024 Tutti i diritti riservati',
  '_dk._yjwab': 'Sears Holdings Global Sourcing Limited (0008945) è autorizzata e regolamentata dalla Financial Conduct Authority come intermediario creditizio e lavora esclusivamente con il prestatore Home Retail Group CardServices Limited, operante come SearsGlobal Business Financial Services, SearsGlobal Business Financial I servizi sono autorizzati e regolamentati dalla Financial Conduct Authority per fornire credito. Home Retail GroupCard Services Limited è registrata in Inghilterra (04007072), Avebury, 489-499Avebury Boulevard, Milton Keynes, Regno Unito. MK9 2NW. ',

  '_dk._cyzjsq': 'Compila la richiesta di prestito aziendale',
  '_dk._dkll': 'Tasso di interesse del prestito',
  '_dk._dkll2': 'Descrizione del tasso di interesse del prestito (fare clic per visualizzare)',
  '_dk._gs1': 'Formula',
  '_dk._gs2': 'Interesse',
  '_dk._gs3': 'Importo del prestito',
  '_dk._gs4': 'Tasso di interesse annuo',
  '_dk._gs5': 'Numero di giorni di prestito',
  '_dk._gs6': 'per esempio',
  '_dk._gs7': 'Importo del prestito',
  '_dk._gs8': 'Ciclo del prestito',
  '_dk._gs9': 'giorno',
  '_dk._fkfs': 'Metodo di prestito',
  '_dk._zhye': 'Saldo del conto',
  '_dk._dkqx': 'Periodo di prestito',
  '_dk._dkqx1': 'Seleziona il periodo di prestito',
  '_dk._sqje': 'Importo della domanda',
  '_dk._qsrdk': 'Inserisci il prestito',
  '_dk._dkje': 'Importo del prestito',
  '_dk._gj': 'Nazionalità',
  '_dk._qxzgj': 'Seleziona la nazionalità',
  '_dk._sqr': 'Richiedente',
  '_dk._qsrsqsm': 'Inserisci il nome del richiedente',
  '_dk._zjhm': 'Numero ID',
  '_dk._qsrzjhm': 'Inserisci il numero ID',
  '_dk._zjzp': 'Foto tessera',
  '_dk._zjzp1': "Carica una foto della parte anteriore del tuo documento d'identità",
  '_dk._zjfm1': "Carica una foto del retro del tuo documento d'identità",
  '_dk._zjsc1': "Carica una foto della tua carta d'identità",
  '_dk._zjzp2': 'Seleziona immagine',
  '_dk._pzslt': 'Esempio di fotografia',
  '_dk._wytyb': 'Ho letto e accetto',
  '_dk._jkxy': 'Contratto di prestito',
  '_dk._tjsq': 'Invia domanda',

  '_dk._wddk2': 'Il mio prestito',
  '_dk._ydk': 'Prestato',
  '_dk._cslx': 'Genera interesse',
  '_dk._yjhk': 'Rimborso stimato',
  '_dk._dkjl': 'Registrazione del prestito',
  '_dk._hkjl': 'Record di rimborso',
  '_dk._sqrq': 'Data della domanda',
  '_dk._hkzq': 'Ciclo di rimborso',
  '_dk._gdll': 'Tasso di interesse fisso',
  '_dk._hkz': 'Rimborso in corso',
  '_dk._yhk': 'Rimborsato',
  '_dk._sqz': 'Applicazione',
  '_dk._ytg': 'Superato',
  '_dk._ybh': 'Rifiutato',
  '_dk._tqhk': 'Rimborso anticipato',

  '_dk._ljhk': 'Rimborsa immediatamente',
  '_dk._qrytqhk': 'Sei sicuro di voler richiedere il rimborso anticipato?',
  '_dk._qrytqhk1': "Nota: una volta accolta la richiesta, l'importo depositato verrà restituito direttamente al prestito in via prioritaria",
  '_dk._dkxq': 'Dettagli del prestito',
  '_dk._dkje1': 'Importo del prestito',
  '_dk._yhlx': 'Interessi dovuti',
  '_dk._hkze': 'Rimborso totale',
  '_dk._sqhkz': 'Richiesta di rimborso',
  '_dk._zxydbty': 'Leggi attentamente e seleziona la casella per accettare il "Contratto di prestito"',
  '_dk._zzsqz': 'Applicazione',

  '_dk._dksq': 'Credito',

  '_dk._cs': 'Parametri',
  '_dk._nllsm': 'Espresso in percentuale, ad esempio: 6,25%',
  '_dk._dktssm': "Il numero totale di giorni dall'inizio alla fine dell'approvazione del prestito",
  '_dk._yndts': 'Il numero di giorni in un anno: solitamente 365 giorni',
  '_dk._zlj': 'Penale per ritardo'
}
