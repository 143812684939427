<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-inner site-inner-wrapper clearfix">
      <div id="breadcrumb">
        <a class="first">Home</a>
        <span class="separator"></span>
        <a>{{ $t('_abt._gywm') }}</a>
        <span class="separator"></span>
        <span>{{ $t('_footer._platform._qualification') }}</span>
      </div>
    </div>
  </div>
  <div class="container">
    <a-card style="margin-bottom: 20px; padding: 50px 25px">
      <div>
        <div style="text-align: center; margin-bottom: 65px">
          <h3 style="font-weight: 700; font-size: 17px">{{ $t('_store._ptzz') }}</h3>
        </div>
        <div style="display: flex; gap: 0px">
          <!-- <div class="bbox">
            <a href="https://find-and-update.company-information.service.gov.uk/company/13322982">
              <div class="ltd-zs tu" :style="'background-image: url(' + require('@/static/next_store_ltd.png') + ');'"></div>
            </a>
          </div> -->
          <div class="bbox">
            <div class="ymx-zs tu" :style="'background-image: url(' + require('@/static/sqs.png') + ');'"></div>
          </div>
          <div class="bbox">
            <div class="trq-zs tu" :style="'background-image: url(' + require('@/static/gxx.png') + ');'"></div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
// vue-video-player
export default {
  name: 'docsVue',
  components: {},
  data() {
    return {
      docs: {},
      key: ''
    }
  },
  created() {},
  methods: {}
}
</script>

<style>
.docs-title {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
}

.tu {
  width: 330px;
  height: 460px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 4px #d9d9d9;
}

.tu:hover {
  box-shadow: 0px 0px 5px 4px #c7c7c7;
}

.trq-zs {
  background-image: url('@/static/gxx.png');
  background-size: 100% 100%;
}

.ymx-zs {
  background-image: url('@/static/sqs.png');
  background-size: 100% 100%;
}

.ltd-zs {
  background-size: 100% 100%;
}

.bbox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
