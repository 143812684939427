<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('flow.lltg') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px' }">
    <div class="nav-content">
      <div style="display: flex; align-items: center">
        <a-button size="large" @click="onCzShow()">
          <template #icon>
            <PlusCircleOutlined />
          </template>
          {{ $t('flow.gmll') }}
        </a-button>
        <div style="display: flex; align-items: center">
          <!-- 充值点数 -->
          <div style="margin-left: 15px; font-size: 16px">
            {{ $t('flow.dqtc') }}：<span style="font-weight: 700">{{ currentDay.combo }}{{ $t('flow.tc1') }} - {{ $t('flow.sy2') }}{{ currentDay.day }}{{ $t('flow.tian3') }}</span>
          </div>
        </div>
      </div>
      <!-- 右侧搜索框 -->
      <div>
        <a-space :size="20">
          <div>{{ $t('flow.jlzs') }}:{{ pagination.total }}</div>
          <div>
            <!-- <a-cascader size="large" :style="{ width: '400px' }" v-model:value="queryParam.category" :options="categorys" :placeholder="$t('products.search.category')" /> -->
          </div>
          <a-input-search :style="{ width: '400px' }" v-model:value="queryParam.search" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
        </a-space>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="display: flex">
        <!-- <a-popconfirm
          :title="$t('products.ask.delete')"
          :ok-text="$t('products.yes')"
          :cancel-text="$t('products.no')"
          @confirm="onDeleteGoods()"
          :disabled="selectedRowKeys.length == 0"
        >
          <a-button type="primary" :disabled="selectedRowKeys.length == 0">
            <template #icon>
              <close-circle-outlined />
            </template>
            {{ $t('products.batch.delete') }}
          </a-button>
        </a-popconfirm> -->
      </div>
      <a-table
        :rowKey="
          (record) => {
            return record.id
          }
        "
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="getMyStoreFlow"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="record && column.dataIndex === 'time'">
            <div>{{ record.number }}</div>
          </template>
          <template v-if="record && column.dataIndex === 'total'">
            <div>
              <a-tag color="green"> ≈ ${{ record.total }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'type'">
            <div>
              {{ typeStr[record.type].title }}
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'end_time'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px; height: 44px; overflow: hidden">{{ record.end_time }}</div>
            </div>
          </template>
          <template v-if="record && column.dataIndex === 'create_time'">
            <div style="display: flex; align-items: center">
              <div style="font-size: 13px; height: 44px; overflow: hidden">{{ record.create_time }}</div>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <!-- <a>Detail</a>
              <a-divider type="vertical" /> -->
            </span>
          </template>
        </template>
      </a-table>
    </div>

    <!-- 充值弹框 -->
    <a-modal v-model:visible="visible" style="width: 900px" :title="$t('flow.lltc')">
      <div>
        <a-form ref="form" :model="formState" name="basic" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <!-- 当前套餐 -->
          <a-form-item :label="$t('flow.dqtc')">
            <div>{{ currentDay.combo }}{{ $t('flow.tc1') }} - {{ $t('flow.sy2') }}{{ currentDay.day }}{{ $t('flow.tian3') }}</div>
          </a-form-item>
          <!-- 套餐 -->
          <a-form-item :label="$t('flow.lltc')">
            <a-row type="flex">
              <!-- <a-col :span="4"></a-col> -->
              <a-col :span="24">
                <div class="bcs1">
                  <div :class="['clas1', currentCombo === i ? 'active' : '']" @click="onCombo(i)" v-for="(item, i) in config.combo" :key="i">
                    <div>
                      <div class="title">{{ $t('flow.tc1') }}{{ item.name }}</div>
                      <div class="desc">{{ $t('flow.ll') }}:{{ item.min }} ~ {{ item.max }}</div>
                    </div>
                    <div class="usd">$ {{ item.price }} / {{ $t('flow.tian3') }}</div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-form-item>
          <!-- 选择板块 -->
          <a-form-item :label="$t('flow.gmsj')">
            <a-radio-group v-model:value="currentDiscount" name="radioGroup" @change="onDiscount">
              <a-radio :value="i" v-for="(item, i) in config.discount" :key="i">
                <div class="dis-box">
                  <div>{{ item.number }} {{ typeStr[item.type].title }}</div>
                  <div class="dis" v-if="doDiscount(item.discount) > 0">-{{ doDiscount(item.discount) }}%</div>
                </div>
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <!-- 套餐金额 -->
          <a-form-item :label="$t('flow.tcjg')">
            <div class="price">$ {{ original_price }}</div>
          </a-form-item>

          <!-- 实际支付 -->
          <a-form-item :label="$t('flow.sjzf')">
            <div class="price">$ {{ discounted_price }}</div>
          </a-form-item>

          <!-- 备注 -->
          <a-form-item :label="$t('flow.tcsm')">
            <div style="padding: 5px 0; line-height: 23px; color: #999">
              <div>{{ $t('flow.tcsm.sm') }}</div>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
        <a-button key="submit" type="primary" :loading="btnLoad" @click="onSave">{{ $t('advertise.shop.modal.btn') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { PlusCircleOutlined /*CloseCircleOutlined*/ } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'productsVue',
  components: {
    PlusCircleOutlined
    // CloseCircleOutlined
  },
  setup() {},
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: false,
          width: '5%'
        },
        {
          title: this.$t('flow.tcmc'),
          dataIndex: 'name',
          width: '5%'
        },
        {
          title: this.$t('flow.gmsc'),
          dataIndex: 'time',
          width: '10%'
        },
        {
          title: this.$t('flow.zje'),
          dataIndex: 'total',
          width: '10%'
        },
        {
          title: this.$t('flow.jzsj'),
          dataIndex: 'end_time',
          width: '10%'
        },

        {
          title: this.$t('flow.gmrq'),
          dataIndex: 'create_time',
          width: '10%'
        }
      ],
      //充值弹框
      visible: false,
      currentCombo: 0,
      currentDiscount: 0,
      original_price: 0,
      discounted_price: 0,

      //当前套餐
      currentDay: { combo: '', day: 0 },

      //表单
      formState: {},
      btnLoad: false,
      //流量套餐配置
      config: [],

      typeStr: {
        1: { title: this.$t('flow.tian1') },
        2: { title: this.$t('flow.zhou1') },
        3: { title: this.$t('flow.yue1') }
      },

      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: '',
        category: []
      },

      //筛选
      categorys: [],

      //批量选中
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created() {
    this.getMyStoreFlow(this.pagination)

    // 获取分类
    // const that = this
    // this.$api.category.getCategorys().then((res) => {
    //   that.categorys = res.data.options
    // })
  },
  methods: {
    doDiscount(discount) {
      return parseFloat(1 - discount).toFixed(2) * 100
    },
    getConfig() {
      const that = this
      this.$api.flow.getConfig().then((res) => {
        that.config = res.data
        this.Calculate()
      })
    },
    onCombo(i) {
      this.currentCombo = i
      this.Calculate()
    },
    onDiscount(e) {
      this.currentDiscount = e.target.value
      this.Calculate()
    },
    onCzShow() {
      this.getConfig()
      this.visible = true
    },
    Calculate() {
      //套餐
      var combo = this.config.combo[this.currentCombo]
      var discount = this.config.discount[this.currentDiscount]

      console.log(combo, discount, '计算')

      //计算金额
      var day = 0
      if (discount.type == 1) {
        day = discount.number
      } else if (discount.type == 2) {
        day = discount.number * 7
      } else if (discount.type == 3) {
        day = discount.number * 30
      }

      this.original_price = parseFloat(parseFloat(combo.price) * day).toFixed(2)
      this.discounted_price = parseFloat(this.original_price * discount.discount).toFixed(2)

      console.log('总金额', this.original_price, this.discounted_price)
    },
    /**
     * 选中框
     * @param {*} selectedRowKeys
     * @param {*} selectedRows
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      console.log(selectedRowKeys, selectedRows)
    },
    onSearch() {
      this.getMyStoreFlow(this.pagination)
    },
    getMyStoreFlow(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.flow.getMyStoreFlow(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
        that.currentDay = res.data.day
      })
    },
    onSave() {
      const that = this
      this.$refs.form.validateFields().then((values) => {
        values.combo = that.config.combo[that.currentCombo].id
        values.discount = that.config.discount[that.currentDiscount].id
        console.log(values)
        that.btnLoad = true
        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })
        that.$api.flow
          .payFlow(values)
          .then((res) => {
            that.btnLoad = false
            that.visible = false
            that.currentCombo = 0
            that.currentDiscount = 0
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.getMyStoreFlow(that.pagination)
          })
          .catch(() => {
            that.btnLoad = false
          })
      })
    }
  }
})
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style>
.bcs1 {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  cursor: pointer;
}

.clas1 {
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clas1.active {
  border: 2px #62c5c5 solid;
  background: #62c5c529;
}

.clas1 .title {
  font-size: 15px;
  font-weight: 700;
}

.clas1 .desc {
  font-size: 13px;
}

.clas1 .usd {
  color: #666666;
  font-weight: 700;
}

.clas1.active .usd {
  color: #f90;
}

.price {
  font-size: 18px;
  color: #4caf50;
}

.dis-box {
  display: flex;
}

.dis {
  top: 0;
  right: 0;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-image: linear-gradient(270deg, #f7c945, #f7c945);
  margin: 0 5px;
  padding: 0 6px;
  border-radius: 4px 0 4px 0;
}
</style>
