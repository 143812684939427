<template>
  <!-- <div class="live-nav" style="margin-top: 11px">
    <div class="main">
      <div class="logo"></div>
      <div class="title-box" style="text-align: center; color: #000">
        <div class="title">April 2024 Global Sources Hong Kong Shows</div>
        <div class="desc">World-class Sourcing from AI, technology, innovation to lifestyle</div>
      </div>
    </div>
  </div> -->
  <div class="content container-box home">
    <div class="banner-con mod-wrapper">
      <!-- 分类列表 -->
      <div class="sidebar-index">
        <div class="sidebar-wrap">
          <div class="title-box">
            <i class="iconfont ic_homecate_nor ico"></i>
            <span class="tit">{{ $t('text.categories.title') }}</span>
          </div>
          <ul class="sidebar-list home-sidebar home-scroll size18" style="position: initial; padding-top: 55px; height: 100%; overflow: hidden">
            <li class="item category-item" v-for="(item, i) in categoryList" :key="i">
              <div class="aItem">
                <div :class="[item.css, 'i-icon']"></div>
                <h2 class="tit">{{ item.name }}</h2>
                <i class="iconfont ic_arrow_right_14 tit-icon"></i>
              </div>
              <!-- 三级分类 -->
              <div class="sidebar-info home-sideinfo home-scroll vvo">
                <div class="nav-box">
                  <dl class="nav-list nav-cate" v-for="(vo, ii) in item.category" :key="ii">
                    <dt>
                      <a class="">{{ vo.name }}</a>
                    </dt>
                    <dd v-for="(voo, iii) in vo.category" :key="iii">
                      <router-link :to="{ name: 'category', query: { level: voo.level, id: voo.id } }">
                        {{ voo.name }}
                      </router-link>
                    </dd>
                    <!-- <dd>
                      <router-link :to="{ name: 'category', query: { level: item.level, id: item.id } }" class="more">
                        See More
                        <i class="iconfont ic_arrow_right_14"></i>
                      </router-link>
                    </dd> -->
                  </dl>
                </div>
                <div class="nav-1">
                  <div class="nav-2" v-for="(vvo, ii) in item.top" :key="ii">
                    <router-link :to="{ name: 'category', query: { level: vvo.level, id: vvo.id } }">
                      <img :src="vvo.url" />
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="fixed"></div>
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="banner-wrapper">
        <div class="banner">
          <div class="bannerSwip">
            <div class="ant-carousel">
              <div class="slick-slider slick-initialized">
                <div class="swiper-btn prev custom-slick-arrow slick-arrow slick-prev" style="display: flex; align-items: center; justify-content: center">
                  <i class="iconfont ic_arrow_left_24" style="transform: rotate(180deg)"></i>
                  <!-- <left-outlined style="font-size: 30px" /> -->
                </div>
                <div class="slick-list">
                  <div class="slick-track">
                    <swiper
                      :modules="modules"
                      :slidesPerView="1"
                      :spaceBetween="30"
                      :pagination="{
                        el: '.banner-slick-dots',
                        type: 'custom',
                        renderCustom: onRenderCustom
                      }"
                      :navigation="{
                        nextEl: '.slick-next',
                        prevEl: '.slick-prev'
                      }"
                      :loop="true"
                      :autoplay="{
                        delay: 5000,
                        disableOnInteraction: false
                      }"
                      style="height: auto"
                    >
                      <swiper-slide v-for="(item, i) in banner" :key="i" @click.stop="onUrl(item.url)">
                        <div class="slick-slide slick-cloned" style="width: 840px">
                          <div>
                            <img :src="item.banner" class="imgBox link" style="width: 100%; display: inline-block" />
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                </div>
                <div class="swiper-btn next custom-slick-arrow slick-arrow slick-next">
                  <i class="iconfont ic_arrow_right_24"></i>
                  <!-- <right-outlined /> -->
                </div>
                <ul class="slick-dots-box slick-dots-bottom" style="display: flex">
                  <li :class="[current == i && 'slick-active']" v-for="i in total" :key="i"><button class=""></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 推荐分类 -->
        <div class="banner-bot">
          <router-link :to="{ name: 'category', query: { level: item.level, id: item.id } }" class="aItem bg-1" v-for="(item, i) in categoryTop" :key="i">
            <div class="mod-img">
              <img :src="item.url" alt="Electronics" class="img" />
            </div>
            <div class="mod-info">
              <h2 class="name">{{ item.name }}</h2>
            </div>
          </router-link>
        </div>
      </div>

      <!-- 主流商品 -->
      <div class="banner-right">
        <h3>
          <a class="title">{{ $t('_zddy._zshy') }}</a>
        </h3>
        <div class="top-list">
          <ul class="product-list" v-if="topList.length > 0">
            <li class="item card-box invalid" v-for="(item, i) in topList" :key="i">
              <router-link :to="{ name: 'detail', query: { id: item.id } }">
                <div class="mod-prod-img">
                  <i :class="'symbol lev_' + (i + 1)"></i>
                  <img :src="item.goods.img" class="img" />
                </div>
                <div class="mod-prod-info">
                  <div class="tit">
                    <span v-html="item.goods.name"></span>
                  </div>
                  <div class="price-info">
                    <div class="price-box">
                      <span class="price"> US$ {{ item.goods.price }} </span>
                      <!-- <i class="unit">/ Piece</i> -->
                    </div>
                    <span class="txt"> {{ item.orders }} {{ $t('_zddy._ddl') }} </span>
                  </div>
                </div>
              </router-link>
              <!---->
              <div class="mod-prod-btn">
                <div class="GS-btn-group">
                  <button slotitem="[object Object]" class="GS-btn btn_rfi text-two-omitted"><p class="btn-text-overflow">Inquire Now</p></button>
                  <!---->
                  <!---->
                </div>
              </div>
            </li>
          </ul>
          <!-- 空状态 -->
          <div class="help-box flex-center" v-else>
            <div class="ant-empty">
              <div class="ant-empty-image">
                <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillrule="evenodd">
                    <g transform="translate(24 31.67)">
                      <ellipse fillopacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse>
                      <path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path>
                      <path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path>
                      <path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path>
                      <path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path>
                    </g>
                    <path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path>
                    <g transform="translate(149.65 15.383)" fill="#FFF">
                      <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse>
                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">
                <span class="description">{{ $t('_zddy._mysj') }}</span>
              </p>
            </div>
          </div>
        </div>
        <a rel="nofollow" class="" v-if="topList.length > 0">
          <button class="GS-btn btn-border">
            {{ $t('_zddy._ckgd') }}
            <i class="iconfont ic_arrow_right_14"></i>
          </button>
        </a>
      </div>
    </div>
    <!---->
    <!--热卖置顶-->
    <div class="hk-mod-box" style="background-color: #fff; -background: url('https://s.globalsources.com/IMAGES/website/image/tradeShow/GSHome/phase2/hks_virtual_bg.jpg') top left no-repeat">
      <div>
        <div class="hk-mod-title">
          <div class="mod-title-left">
            <div class="left-tit">{{ $t('_zddy._tjsp') }}</div>
            <div class="left-desc">{{ $t('_zddy._rmsp') }}</div>
          </div>
          <!-- <div class="mod-title-right">Register Now</div> -->
        </div>
      </div>
      <div class="card-box">
        <swiper
          :modules="modules"
          :slidesPerView="9"
          :slidesPerGroup="1"
          :spaceBetween="10"
          :loopFillGroupWithBlank="true"
          :navigation="{
            nextEl: '.s3-next',
            prevEl: '.s3-prev'
          }"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false
          }"
          :loop="false"
        >
          <swiper-slide v-for="(item, i) in superDeals" :key="i">
            <div class="live-card recommend-item maxHKShow">
              <router-link :to="{ name: 'detail', query: { id: item.sg_id } }" target="_blank" class="card-img">
                <img :src="item.goods.img" alt="" />
              </router-link>
              <div class="card-content">
                <router-link :to="{ name: 'detail', query: { id: item.sg_id } }" target="_blank" class="content-desc two-text-ellipsis">
                  <div class="nadmesfsd">{{ item.goods.name }}</div>
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="mod-wrapper">
      <div class="mod mod-new-pro">
        <div class="new-title">
          <a href="/product/list?source=GSOLHP_NewProd" class="new-content">
            <span class="tit">{{ $t('_zddy._xsp') }}</span>
            <!-- <span class="tit">Products</span> -->
            <span class="tips">{{ $t('_zddy._jlzzr') }}</span>
          </a>
          <!-- <a href="/product/list?source=GSOLHP_NewProd_SeeAll" class="more">See All</a> -->
        </div>
        <div class="">
          <ul class="product-list" style="">
            <swiper
              :modules="modules"
              :slidesPerView="6"
              :slidesPerGroup="1"
              :spaceBetween="10"
              :loopFillGroupWithBlank="true"
              :navigation="{
                nextEl: '.s2-next',
                prevEl: '.s2-prev'
              }"
              :autoplay="{
                delay: 3000,
                disableOnInteraction: false
              }"
              :loop="false"
            >
              <swiper-slide v-for="(item, i) in plusSale" :key="i">
                <li class="item card-box invalid">
                  <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                    <div class="mod-prod-img">
                      <img :src="item.goods.img" :alt="item.goods.name" width="100%" class="img" />
                    </div>
                    <div class="mod-prod-info">
                      <div class="tit">
                        <span title="Factory wholesale Bicycle Frame Suspension Fork mountain bike">
                          {{ item.goods.name }}
                        </span>
                      </div>
                      <div class="price-info">
                        <div class="price-box">
                          <span class="price"> US$ {{ item.goods.price }} </span>
                        </div>
                        <span class="txt"> {{ item.orders }} {{ $t('_zddy._ddl') }} </span>
                      </div>
                    </div>
                  </router-link>
                  <div class="mod-prod-btn">
                    <div class="GS-btn-group">
                      <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                        <button slotitem="[object Object]" class="GS-btn btn_rfi text-two-omitted">
                          <p class="btn-text-overflow">{{ $t('_zddy._ljgm') }}</p>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </li>
              </swiper-slide>
            </swiper>
          </ul>
          <div class="help-box flex-center" style="display: none">
            <div class="ant-empty">
              <div class="ant-empty-image">
                <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                      <ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse>
                      <path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path>
                      <path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path>
                      <path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path>
                      <path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path>
                    </g>
                    <path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path>
                    <g transform="translate(149.65 15.383)" fill="#FFF">
                      <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse>
                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">
                <span class="description">{{ $t('_zddy._mysj') }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  name: 'BannerVue',
  components: {
    // LeftOutlined,
    // RightOutlined
  },
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],

      categoryList: [],
      categoryTop: [],
      showCate: false,
      category: [],

      //轮播图
      banner: [],
      current: 0,
      total: 0,

      topList: [],
      superDeals: [],
      plusSale: []
    }
  },
  created() {
    this.getCategory()
    this.getBanner()
    this.getTopGoods()
  },
  methods: {
    onUrl(url) {
      console.log(url)
      window.open(url, '_blank')
    },
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    /**
     * 获取分类列表
     */
    getCategory() {
      const that = this
      this.$api.category.getCategoryList().then((res) => {
        that.categoryList = res.data.list
        that.categoryTop = res.data.top
      })
    },
    //获取轮播图
    getBanner() {
      let that = this
      this.$api.home.getBanner().then((res) => {
        that.banner = res.data
      })
    },
    getTopGoods() {
      // 获取推荐
      this.$api.home.getTopGoods({ type: 1, size: 4 }).then((res) => {
        this.topList = res.data
      })

      //获取super
      this.$api.home.getTopGoods({ type: 2 }).then((res) => {
        this.superDeals = res.data
      })

      //获取plus
      this.$api.home.getTopGoods({ type: 3 }).then((res) => {
        this.plusSale = res.data
      })
    }
  }
}
</script>
<style scoped src="@/static/css3/iconfont.css"></style>
<style scoped src="@/static/css3/1.css"></style>
<style scoped src="@/static/css3/2.css"></style>
<style scoped src="@/static/cate/cate.css"></style>
<style>
.category-item:hover .vvo {
  display: block;
}

.skdksfd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.nav-2 {
  width: calc(100% / 6);
  height: 135px;
  overflow: hidden;
  transition: all 0.25s;
}

.nav-2 img {
  width: 100%;
  height: auto;
}

.nav-2:hover {
  transform: scale(1.1);
}
</style>
