import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

//vuex
import store from './utils/store'
app.use(store)

//const
import Const from '@/utils/const'
app.config.globalProperties.$Const = Const

//路由
import router from './router'
app.use(router)

//api
import api from './api'
app.config.globalProperties.$api = api

//common
import common from './utils/common'
app.config.globalProperties.$common = common

//i18n
import i18n from '@/utils/vue-i18n'
app.use(i18n)

//md5
import md5 from 'js-md5'
app.config.globalProperties.$md5 = md5

//socket
import socket from '@/utils/socket'
app.config.globalProperties.$socket = socket

//swiper
import swiper from '@/utils/vue-awesome-swiper'
app.use(swiper)

//swiper
import scroll from '@/utils/vue3-infinite-scroll-better'
app.use(scroll)

// clipboard2
import VueClipboard from 'vue-clipboard2'
app.use(VueClipboard)

// base64
import { Base64 } from 'js-base64'
app.use(Base64)

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
app.use(VueLuckyCanvas)

// icon
import { whatsapp, line, telegram } from '@/utils/icon'
app.component('IconWhatsApp', whatsapp)
app.component('IconLine', line)
app.component('IconTelegram', telegram)

//antd组件
import { Collapse, Spin, Image, Pagination, Breadcrumb, Alert, notification, message, Menu, Table, Tag, Divider, Button, Input, Space, Select, PageHeader, Layout, Avatar, Badge, Card, Popconfirm, Dropdown, Form, Upload, Checkbox, Row, Col, Tooltip, Tabs, Modal, InputNumber, Rate, Timeline, Cascader, Switch, Radio, Popover, DatePicker, Empty } from 'ant-design-vue'
message.config({
  top: `100px`,
  duration: 2,
  maxCount: 3
})
app.config.globalProperties.$message = message
app.config.globalProperties.$notification = notification
app.config.globalProperties.$confirm = Modal.confirm

app.use(Collapse)
app.use(Spin)
app.use(Pagination)
app.use(notification)
app.use(Breadcrumb)
app.use(Alert)
app.use(Menu)
app.use(Table)
app.use(Tag)
app.use(Divider)
app.use(Button)
app.use(Input)
app.use(Space)
app.use(Select)
app.use(PageHeader)
app.use(Layout)
app.use(Avatar)
app.use(Badge)
app.use(Card)
app.use(Popconfirm)
app.use(Dropdown)
app.use(Form)
app.use(Upload)
app.use(Checkbox)
app.use(Row)
app.use(Col)
app.use(Tooltip)
app.use(Tabs)
app.use(Modal)
app.use(InputNumber)
app.use(Rate)
app.use(Timeline)
app.use(Cascader)
app.use(Switch)
app.use(Radio)
app.use(Popover)
app.use(DatePicker)
app.use(Empty)
app.use(Image)
app.mount('#app')
