<template>
  <!-- 第一板块 -->
  <div class="pi-layout-container b-base" id="bd" style="">
    <div class="b-base" style="">
      <div class="b-base" style="width: 100%; margin: 0px auto"></div>
    </div>
    <div class="b-base" style="">
      <div class="b-base" style="width: 100%; margin: 0px auto">
        <div class="b-base" style="">
          <div id="2004386166033" data-widgetid="2004386166033" data-modulename="smartNewArrivals" data-index="0" class="b-base" style="width: 100%; height: 0.01px"></div>
          <div dir="ltr" class="b-base" style="width: 100%; min-width: 1200px; margin: 0px auto; overflow: hidden; font-family: 'Open Sans'">
            <div class="b-base" style="">
              <h1 style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin: 32px 0px; z-index: 2; font-weight: 800; font-size: 32px; color: rgb(0, 0, 0); text-align: center">RECOMMEND</h1>
              <!-- <img src="../../static/temp/2/2.png" style="width: 1920px; height: 240px; z-index: 1; position: absolute; top: 0px; left: calc((100% - 1920px) / 2)" /> -->
            </div>
            <div style="position: relative; box-sizing: border-box; display: flex; flex-flow: row; place-content: flex-start space-between; flex-shrink: 0; width: 1200px; height: 350px; margin: 0px auto; z-index: 2">
              <!-- 4个商品 -->
              <div @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in novidades" :key="i" class="b-base" style="width: 220px; height: 326px; background: rgb(255, 255, 255); box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 24px 0px; border-radius: 8px; overflow: hidden">
                <div style="position: absolute; box-sizing: border-box; display: flex; flex-direction: column; align-content: flex-start; flex-shrink: 0; z-index: 999; height: 18px; padding: 1px 8px; background: rgb(255, 71, 71); border-radius: 2px; top: 8px; right: 8px"><span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-size: 12px; color: rgb(255, 255, 255); font-style: italic; line-height: 16px">New</span></div>
                <a style="text-decoration: none">
                  <div class="b-base" style="width: 220px; height: 220px">
                    <img :src="item.goods.img" style="width: 220px; height: 220px; object-fit: cover; cursor: pointer" />
                  </div>
                </a>
                <div class="b-base" style="width: 220px; height: 106px; padding: 8px 16px 16px">
                  <a style="text-decoration: none">
                    <span numberoflines="2" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 4px; width: 188px; font-size: 13px; color: rgb(51, 51, 51); cursor: pointer; height: 36px; line-height: 18px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; text-decoration: none">{{ item.goods.name }}</span>
                  </a>
                  <h3 numberoflines="1" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-family: 'Open Sans'; font-size: 18px; color: rgb(0, 0, 0); font-weight: 700; font-style: italic; padding-right: 2px; white-space: nowrap; overflow: hidden">US ${{ item.goods.price }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="b-base" style=""></div>
          <div class="ae-pc-smartNewArrivals-footer b-base" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 0px"></div>
        </div>
      </div>
    </div>
    <div class="b-base" style="">
      <div class="b-base" style="width: 100%; margin: 0px auto">
        <div class="b-base" style="">
          <div id="2004386166035" data-widgetid="2004386166035" data-modulename="smartJustForYou" data-index="0" class="b-base" style="width: 100%; height: 0.01px"></div>
          <div dir="ltr" class="b-base" style="width: 1200px; margin: 0px auto; font-family: 'Open Sans'">
            <div class="b-base" style="text-align: center; color: rgb(0, 0, 0); margin-top: 24px; margin-bottom: 24px"><span style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-size: 32px; line-height: 40px; font-weight: 700">CHOOSE FOR YOU</span></div>
            <div style="position: relative; box-sizing: border-box; display: block; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-left: -8px; margin-right: -8px">
              <!-- 12个商品 -->
              <div @click="$router.push({ name: 'detail', query: { id: item.id } })" v-for="(item, i) in recommend" :key="i" style="position: relative; box-sizing: border-box; display: inline-block; flex-direction: column; align-content: flex-start; flex-shrink: 0; width: 288px; height: 386px; margin-left: 8px; margin-right: 8px; margin-bottom: 16px; border-radius: 4px; float: left">
                <div class="b-base" style="padding: 15px; background-color: rgb(255, 255, 255); border-width: 1px; border-style: solid; border-color: rgb(242, 242, 242)">
                  <a style="text-decoration: none; display: flex; flex-direction: row; align-items: center; text-align: center; vertical-align: middle; justify-content: center; width: 256px; height: 256px"> <img :src="item.goods.img" style="display: block; max-width: 256px; max-height: 256px; cursor: pointer" /></a>
                </div>
                <div class="b-base" style="margin-top: 8px">
                  <div style="position: relative; box-sizing: border-box; display: inline-block; flex-direction: column; align-content: flex-start; flex-shrink: 0; width: 100%">
                    <span numberoflines="2" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-size: 13px; line-height: 18px; width: 288px; height: 36px; color: rgb(51, 51, 51); overflow: hidden; text-overflow: ellipsis; cursor: pointer; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2">{{ item.goods.name }}</span>
                  </div>
                  <div class="b-base" style="margin-top: 5px">
                    <span numberoflines="1" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-weight: 700; font-size: 18px; line-height: 24px; font-style: italic; overflow: hidden; text-overflow: ellipsis; color: rgb(0, 0, 0); padding-right: 2px; white-space: nowrap">US ${{ item.goods.price }}</span>
                  </div>
                  <div class="b-base" style="width: 100%">
                    <span numberoflines="1" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; font-size: 13px; line-height: 18px; color: rgb(153, 153, 153); text-decoration: line-through; overflow: hidden; text-overflow: ellipsis; font-style: italic; padding-right: 2px; white-space: nowrap">US ${{ item.goods.cost_price }}</span>
                    <div style="position: absolute; box-sizing: border-box; display: flex; flex-direction: column; align-content: flex-start; flex-shrink: 0; width: 50px; height: 18px; background: rgb(255, 71, 71); border-radius: 2px; right: 0px; bottom: 0px">
                      <span numberoflines="1" style="position: relative; box-sizing: border-box; flex-direction: column; align-content: flex-start; flex-shrink: 0; margin-top: 0px; margin-bottom: 0px; width: 100%; font-size: 13px; line-height: 18px; color: rgb(255, 255, 255); text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">-{{ (((item.goods.cost_price - item.goods.price) / item.goods.cost_price) * 100).toFixed(0) }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="b-base" style="width: 100%"></div>
          </div>
          <div class="b-base" style=""></div>
          <div class="ae-pc-smartJustForYou-footer b-base" style="width: 1200px; height: 0px; background-color: transparent; margin-bottom: 0px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'template1Vue',
  props: {
    store_id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      store: {},
      hot: [],
      novidades: [],
      recommend: []
    }
  },
  created() {
    this.getStoreTemplate()
  },
  methods: {
    getStoreTemplate() {
      this.$api.storeInfo.getStoreTemplate({ id: this.store_id, template: 2 }).then((res) => {
        this.store = res.data.store
        this.hot = res.data.hot
        this.novidades = res.data.novidades
        this.recommend = res.data.recommend
      })
    }
  }
}
</script>

<style>
.b-base {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
}
</style>
