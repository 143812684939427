<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-inner site-inner-wrapper clearfix" style="max-width: 1440px">
      <div id="breadcrumb">
        <a class="first">Home</a>
        <span class="separator"></span>
        <a>{{ $t('_abt._gywm') }}</a>
        <span class="separator"></span>
        <span>{{ $t('_abt._qyzr') }}</span>
      </div>
    </div>
  </div>
  <div id="contentwrapper" class="home-content">
    <div id="contentwrapperinner" class="site-wrapper">
      <!-- For Skip to Main Content Only -->
      <div class="container-fluid">
        <div class="row">
          <div class="investorscontentwrapper box-landing-container">
            <div class="investors-bottom-box-container col-xs-12">
              <div class="row">
                <div class="investors-bottom-box-1-inner clearfix">
                  <!--SHARE PRICE BOX -->
                  <div v-for="(vo, i) in docsList" :key="i" :class="['box-container home-box col-md-4 col-sm-6 col-xs-12', i % 2 == 0 ? 'investor-bottom-3-box' : 'investors-bottom-1-box gg-taken gg-g-1']" :style="['cursor: pointer;height: 200px;overflow: hidden;', i % 2 == 0 ? 'color: rgb(255, 255, 255); border-color: rgb(255, 255, 255)' : 'background-color: rgb(255, 255, 255); color: rgb(12, 12, 12); border-color: rgb(12, 12, 12); height: 200px']" :data-background-hover="[i % 2 == 0 ? '#007FA1' : '']" :data-background="[i % 2 == 0 ? '' : '#fff']">
                    <router-link :to="{ name: 'about_liabilitys', query: { key: vo.key } }" style="text-decoration: none">
                      <div class="box-inner-container">
                        <div class="box-header">
                          <a>{{ vo.title }}</a>
                        </div>
                        <div class="box-content">
                          <p>{{ vo.desc }}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!--RNS BOX -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'liabilityVue',
  data() {
    return {
      docsList: [],
      group: 'liability'
    }
  },
  created() {
    this.getDocsByGroup()
  },
  methods: {
    getDocsByGroup() {
      //获取文章
      let that = this
      this.$api.docs.getDocsByGroup({ group: this.group }).then((res) => {
        that.docsList = res.data
      })
    }
  }
}
</script>

<style></style>
