<template>
  <div class="container">
    <div class="task-h1">
      <span>{{ $t('task.rwxq') }}</span>
    </div>
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link :to="{ name: 'index' }">{{ $t('task.sy') }}</router-link></a-breadcrumb-item
      >
      <a-breadcrumb-item
        ><router-link :to="{ name: 'task' }">{{ $t('task.rwlbo') }}</router-link></a-breadcrumb-item
      >
      <a-breadcrumb-item>{{ $t('task.rwxqo') }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-card style="margin: 20px 0" :class="'container-shadow ' + (info.status == 1 ? 'qdz' : info.status == 2 ? 'ywc' : 'djy')">
      <div>
        <!-- 加载更多 -->
        <div v-show="loadmore" class="load-bt">
          <img class="_2O2Fm" src="@/static/img/loading.gif" />
        </div>

        <div class="task-name">
          <div style="">
            <a-tag color="red" v-show="info.status == 1">{{ $t('task.qdz') }}</a-tag>
            <a-tag color="green" v-show="info.status == 2">{{ $t('task.ywc') }}</a-tag>
            <a-tag color="blue" v-show="info.status == 3">{{ $t('task.djy') }}</a-tag>
          </div>
          <div>{{ info.name }}</div>
        </div>
        <div v-html="info.content" class="task-content"></div>
        <div class="tg-bk01-box" style="margin: 20px 0">
          <router-link :to="{ name: 'detail', query: { id: goods.id } }" v-for="(goods, i) in info.columns" :key="i">
            <div class="tg-item">
              <div class="tg-img">
                <img :src="goods.img" alt="" />
              </div>
              <div class="tg-info">
                <div class="tg-name">{{ goods.name }}</div>
              </div>
            </div>
          </router-link>
        </div>
        <div :class="'task-desc ' + (info.status == 1 ? 'qdzs' : info.status == 2 ? 'ywcs' : 'djys')">
          <div>
            <span>{{ $t('task.ddje') }}：</span>
            <span>${{ info.total_min }} ~ ${{ info.total_max }}</span>
          </div>
          <div>
            <span>{{ $t('task.cyrs') }}：</span>
            <span>{{ info.join_num }}</span>
          </div>
          <div>
            <span>{{ $t('task.ewsy') }}：</span>
            <span>{{ info.income_rate }} %</span>
          </div>
          <div>
            <span>{{ $t('task.jzsj') }}：</span>
            <span>{{ info.cuttoff_time }}</span>
          </div>
        </div>

        <div class="task-btn" v-if="info.status === 1 && !info.join">
          <a href="javascript:" class="_SOk" @click="onJoin">{{ $t('task.ljcy') }}</a>
          <!-- <a href="javascript:" class="_SOk Sk1_X">查看详情</a> -->
        </div>
      </div>
    </a-card>
    <a-card style="margin: 20px 0">
      <div>
        <span>{{ $t('task.cylb') }}</span>
      </div>
      <div class="user-fg"></div>
      <div class="user-box-main">
        <div class="user-div">
          <div class="user-box" v-for="(item, i) in list" :key="i">
            <div class="user-head">
              <img :src="item.avatar" style="width: 30px; height: 30px" />
            </div>
            <div class="_name">{{ item.name }}</div>
            <div class="task-sted" v-if="item.store_id == info.store_id"><check-outlined style="font-size: 30px" /></div>
            <!-- <div>2023/05/05 12:10</div> -->
          </div>
        </div>

        <div class="example" v-if="load">
          <a-spin />
        </div>
        <div style="margin-top: 15px">
          <a-pagination v-model:current="page" :total="total" @change="getTaskContentJoinList" :pageSize="size" :pageSizeOptions="pageSizeOptions" show-less-items />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { CheckOutlined } from '@ant-design/icons-vue'
export default {
  name: 'docsVue',
  components: {
    CheckOutlined
  },
  data() {
    return {
      info: {},
      list: [],
      id: 0,
      loadmore: true,

      load: false,
      total: 0,
      page: 1,
      size: 28,
      pageSizeOptions: ['14', '28', '42', '56']
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getTaskContent()
    this.getTaskContentJoinList(this.page, this.size)
  },
  methods: {
    languageNotify(e) {
      console.log(e, 'docs lang')
      this.getDocs()
    },
    getTaskContent() {
      //获取文章
      let that = this
      this.$api.orderTask.getTaskContent({ id: this.id }).then((res) => {
        that.info = res.data.info
        that.loadmore = false
      })
    },
    getTaskContentJoinList(page = 1, pageSize = 10) {
      this.page = page
      this.size = pageSize
      //获取文章
      let that = this
      this.load = true
      this.$api.orderTask.getTaskContentJoinList({ id: this.id, page: page, size: pageSize }).then((res) => {
        that.list = res.data.data
        that.total = parseInt(res.data.totalCount)
        that.load = false
      })
    },
    onJoin() {
      let that = this
      this.$confirm({
        // title: '确定要参与此订单任务吗?',
        content: this.$t('task.qdcyrw'),
        okText: this.$t('task.sd'),
        cancelText: this.$t('task.bue'),
        onOk() {
          // 提交数据
          that.$message.loading({ content: 'loading...', key: 'msg' })
          that.$api.orderTask.joinTask({ id: that.id }).then((res) => {
            that.$message.destroy()
            if (res.code === 1) {
              that.$message.error({
                content: res.msg,
                key: 'msg'
              })
              return
            }
            // 刷新表格
            that.getTaskContent()
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.task-h1 {
  font-size: 19px;
  font-weight: 700;
  margin-top: 15px;
}
._SOk {
  cursor: pointer;
  background: #ffb500;
  border-radius: 15px;
  padding: 6px 28px;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0;
}
/* .docs-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
} */

.task-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  font-weight: 700;
}

.task-desc {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}

.task-desc span:nth-child(1) {
  font-weight: 700;
  font-size: 16px;
}

.task-desc span:nth-child(2) {
  font-weight: 700;
  font-size: 16px;
  color: #4caf50;
}

.task-btn {
  margin-top: 30px;
}

.task-content {
  color: #4e4e4e;
  font-size: 15px;
  margin-top: 10px;
}

.user-fg {
  height: 1px;
  background: #f4f4f4;
  margin: 10px 0;
}

.user-div {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.user-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 67px;
  padding: 5px;
}
.user-box ._name {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.task-sted {
  position: absolute;
  border: 1px dashed #4caf5085;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4caf5026;
  color: #009688;
}

.user-head {
  border-radius: 50%;
  overflow: hidden;
}

.user-box-main {
  position: relative;
  display: flex;
  flex-direction: column;
}
.example {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 0.01);
  border-radius: 4px;
}
</style>
<style scoped>
@import url('@/static/css/task.css');
</style>
