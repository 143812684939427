<template>
  <div>
    <a-button type="primary" @click="onOpenAddress">{{ $t('bind.address.title') }}</a-button>
  </div>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreAddress">
    <template #bodyCell="{ column, record }">
      <template v-if="record && column.dataIndex === 'operate'">
        <a @click="onEdit(record)">{{ $t('bind.bj') }}</a>
        <a-popconfirm :title="$t('bind.qrsc')" :ok-text="$t('products.yes')" :cancel-text="$t('products.no')" @confirm="onDel(record)">
          <a style="margin-left: 10px">{{ $t('bind.sc') }}</a>
        </a-popconfirm>
      </template>
    </template>
  </a-table>
  <!-- 绑定地址 -->
  <WithdrawalBindAddress ref="bindaddress" @BindAddressSuccess="BindAddressSuccess"></WithdrawalBindAddress>
</template>

<script>
import WithdrawalBindAddress from '@/components/store/WithdrawalBindAddress.vue'
export default {
  name: 'TransferList',
  components: {
    WithdrawalBindAddress
  },
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          // sorter: true,
          width: '50px'
        },
        {
          title: this.$t('_txcd._skr'),
          dataIndex: 'name'
        },
        {
          title: this.$t('_txcd._skzh'),
          dataIndex: 'account',
          width: '150px'
        },
        {
          title: this.$t('_txcd._yhlx'),
          dataIndex: 'bank_type',
          width: '150px'
        },
        {
          title: this.$t('_txcd._yhly'),
          dataIndex: 'routing',
          width: '150px'
        },
        {
          title: this.$t('_txcd._yhdm'),
          dataIndex: 'code',
          width: '180px'
        },
        {
          title: this.$t('_txcd._yhmc'),
          dataIndex: 'bank_name',
          width: '180px'
        },
        {
          title: this.$t('_txcd._yhdz'),
          dataIndex: 'bank_address',
          width: '180px'
        },
        {
          title: this.$t('bind.cz'),
          dataIndex: 'operate',
          width: '200px'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {
        search: ''
      }
    }
  },
  created() {
    this.getStoreBankAddress(this.pagination)
  },
  methods: {
    /**
     * 提现
     */
    onEdit(record) {
      this.$refs.bindaddress.show(record)
    },
    onDel(record) {
      this.$api.store.delStoreBankAddress({ id: record.id }).then((res) => {
        if (res.code == 1) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success(res.msg)
        this.refresh()
      })
    },
    /**
     * 提现
     */
    onOpenAddress() {
      this.$refs.bindaddress.show()
    },
    // 绑定地址
    BindAddressSuccess() {
      this.refresh()
    },
    refresh() {
      this.getStoreBankAddress({ total: 0, current: 1, pageSize: 10 })
    },
    getStoreBankAddress(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.store.getStoreBankAddress(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>
