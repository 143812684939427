import { context } from 'ant-design-vue/lib/vc-image/src/PreviewGroup';
<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-inner site-inner-wrapper clearfix">
      <div id="breadcrumb">
        <a class="first">Home</a>
        <span class="separator"></span>
        <a>{{ $t('_abt._gywm') }}</a>
        <span class="separator"></span>
        <span>{{ docs.title }}</span>
      </div>
    </div>
  </div>
  <div id="contentwrapper" class="">
    <div id="contentwrapperinner" class="site-wrapper">
      <div class="content-template-container">
        <div class="site-inner-wrapper">
          <div class="main-content-wrapper" v-html="docs.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactVue',
  data() {
    return {
      docs: {},
      key: ''
    }
  },
  created() {
    this.key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    getDocs() {
      //获取文章
      let that = this
      this.$api.docs.getDocs({ key: this.key }).then((res) => {
        that.docs = res.data
      })
    }
  }
}
</script>

<style></style>
