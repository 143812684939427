<template>
  <div class="placeorder-header">
    <div class="container search-wrapper-box">
      <a href="/" class="logo">
        <img src="@/static/logo10.png" alt="globalsources logo" style="width: 100%" />
      </a>
      <!-- <router-link :to="{ name: 'index' }" class="ae-logo"> </router-link> -->
    </div>
  </div>
  <div id="root">
    <div id="container" class="">
      <div class="placeorder-wrap large" style="padding-bottom: 0px">
        <div id="placeorder_wrap__inner">
          <div class="pl-place-order-container large">
            <div class="pl-main-container large">
              <!-- 地址板块 -->
              <div class="pl-block-container large">
                <div class="pl-address-item-container large">
                  <div class="pl-address-item__title large">
                    {{ $t('confirm.shipping.address') }}
                  </div>
                  <!-- 有地址 -->
                  <div class="pl-address-item__content-wrap large" v-if="currentAddress">
                    <div class="pl-address-item__head">
                      <span class="pl-address-item__head__bold">{{ currentAddress.contact }}</span
                      ><span>{{ currentAddress.phone_country }} {{ currentAddress.phone }}</span>
                    </div>
                    <div class="pl-address-item__content large">
                      {{ currentAddress.address }}
                    </div>
                    <span @click="onChangeAddress()" class="pl-address-item__arrrow large">
                      <a href="javascript:;">{{ $t('confirm.change') }}</a>
                    </span>
                  </div>
                  <!-- 没有地址 -->
                  <div class="pl-address-item__new-btn-wrap" v-if="!currentAddress">
                    <button type="button" class="comet-btn comet-btn-link pl-address-item__new-btn" @click="onCreateAddress()">
                      <span class="comet-icon comet-icon-add" style="font-size: 16px">
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M864 480a32 32 0 0 1 1.877333 63.946667L864 544H160a32 32 0 0 1-1.877333-63.946667L160 480h704z"></path>
                          <path d="M512 128a32 32 0 0 1 31.946667 30.122667L544 160v704a32 32 0 0 1-63.946667 1.877333L480 864V160a32 32 0 0 1 32-32z"></path></svg></span
                      ><span><span style="font-size: 16px">Add New Address</span> </span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- 支付模式 -->
              <div class="pl-block-container large placeorder-page-payment-container">
                <div class="chosen-channel--chosen-channel-container--2TwO5WE chosen-channel--pc--2DZIgIG" style="display: block">
                  <div class="chosen-channel--chosen-channel-head-title--1ktpV2H chosen-channel--pc--2DZIgIG">
                    {{ $t('confirm.payment.methods') }}
                  </div>
                  <!-- 已选择 -->
                  <div class="chosen-channel--chosen-channel-content--3OYmsMr" v-if="currentWallet">
                    <span class="chosen-channel--chosen-channel-main--12tZAt1">
                      <span class="chosen-channel--chosen-channel-desc--BHzkH6r" style="align-items: center">
                        <!-- <img :src="currentWallet.icon" class="credit-card--card-brand-icon--2bUnkAV" style="margin-right: 5px" /> -->
                        <span style="margin-right: 5px">
                          <dollar-outlined style="color: #4caf50" />
                        </span>
                        <span class="chosen-channel--chosen-channel-title--2cCH5Jy chosen-channel--pc--2DZIgIG">${{ currentWallet.balance }}</span>
                      </span>
                    </span>
                    <button @click="onChangePayment()" type="button" class="comet-btn comet-btn-link chosen-channel--chosen-channel-change--2RQRzpW chosen-channel--pc--2DZIgIG">
                      <span>{{ $t('confirm.change') }}</span>
                    </button>
                  </div>
                  <!-- 未选择 -->
                  <!-- <div class="chosen-channel--chosen-channel-content--3OYmsMr">
                    <span class="chosen-channel--chosen-channel-main--12tZAt1">
                      <span @click="onChangePayment()" class="chosen-channel--chosen-channel-desc--BHzkH6r">
                        <span class="chosen-channel--chosen-channel-title--2cCH5Jy chosen-channel--no-select--xkF0Vac chosen-channel--pc--2DZIgIG">Select Payment Method</span>
                      </span>
                    </span>
                  </div> -->
                </div>
              </div>

              <!-- 商品列表 -->
              <div class="pl-block-container large">
                <!-- 店铺列表 -->
                <div v-for="(item, i) in orderList" :key="i" class="pl-store-container large">
                  <div class="pl-title large">
                    <div class="pl-title-left">
                      <img src="@/static/img/store.png" class="pl-title-icon" />
                      <div class="pl-title-left-name">{{ item.name }}</div>
                    </div>
                    <div class="pl-title-right">
                      <img class="pl-title-icon pl-title-right-rightIcon" src="@/static/img/remark.png" />
                    </div>
                  </div>
                  <!-- 商品列表 -->
                  <div v-for="(vo, k) in item.goods_list" :key="k" class="pl-product-container large">
                    <div class="pl-product large">
                      <div class="pl-product-left">
                        <img :src="vo.goods.img" alt="" />
                      </div>
                      <div class="pl-product-right">
                        <div class="pl-product-right-top">
                          <div class="pl-product-right-top-title">
                            {{ vo.goods.name }}
                          </div>
                          <div class="pl-product-right-top-skc">
                            <span v-for="(svo, sk) in vo.sku.remark" :key="sk" style="margin-left: 5px">{{ sk }}:{{ svo }}</span>
                          </div>
                        </div>
                        <div class="pl-product-right-bottom">
                          <div class="pl-product-right-bottom-price">
                            <p style="color: rgb(34, 34, 34); font-size: 16px">${{ vo.sku.price }}</p>
                          </div>
                          <div class="pl-product-right-bottom-count">
                            <div class="comet-input-number">
                              <!-- 减少数量 -->
                              <div :class="['comet-input-number-btn', vo.num <= 1 ? 'comet-input-number-btn-disabled' : '']" @click="onSubNum(vo)">
                                <span class="comet-icon comet-icon-subtract">
                                  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                    <path d="M864 480a32 32 0 0 1 1.877333 63.946667L864 544H160a32 32 0 0 1-1.877333-63.946667L160 480h704z"></path>
                                  </svg>
                                </span>
                              </div>
                              <input type="text" class="comet-input-number-input" v-model="vo.num" @input="onNumber(vo)" />
                              <!-- 增加数量 -->
                              <div :class="['comet-input-number-btn', vo.num >= vo.sku.total ? 'comet-input-number-btn-disabled' : '']" @click="onAddNum(vo)">
                                <span class="comet-icon comet-icon-add">
                                  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                    <path d="M864 480a32 32 0 0 1 1.877333 63.946667L864 544H160a32 32 0 0 1-1.877333-63.946667L160 480h704z"></path>
                                    <path d="M512 128a32 32 0 0 1 31.946667 30.122667L544 160v704a32 32 0 0 1-63.946667 1.877333L480 864V160a32 32 0 0 1 32-32z"></path>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pl-shipping large">
                      <div class="pl-shipping-left">
                        <div class="pl-shipping-left-title">
                          <span data-v-3803ee32="" style="color: rgb(1, 169, 113)">{{ $t('detail.free.shipping') }}</span>
                        </div>
                        <div class="pl-shipping-left-cost">
                          {{ $t('base.delivery') }}
                        </div>
                        <div class="pl-shipping-left-tags"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 总金额 -->
            <div class="pl-side-container large">
              <div class="pl-block-container large">
                <div class="pl-summary-container">
                  <div class="pl-summary__items">
                    <div class="pl-summary__item-pc large">
                      <div class="pl-summary__item-title-pc">
                        <div class="pl-summary__item-title__main">
                          <span style="color: rgb(34, 34, 34); font-size: 14px; font-weight: 700">{{ $t('confirm.summary') }}</span>
                        </div>
                      </div>
                      <div class="pl-summary__item-content-pc">
                        <div style="color: rgb(34, 34, 34); font-size: 14px; font-weight: 700"></div>
                      </div>
                    </div>
                    <div class="pl-summary__item-pc large">
                      <div class="pl-summary__item-title-pc">
                        <div class="pl-summary__item-title__main">
                          <span style="color: rgb(34, 34, 34)">{{ $t('confirm.total.item.costs') }}</span>
                        </div>
                      </div>
                      <div class="pl-summary__item-content-pc">
                        <div style="color: rgb(34, 34, 34)">${{ subTotal }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 延时下单 -->
              <div class="pl-wrap-container large" v-if="sp_info?.real == 2">
                <div class="pl-order-toal-container">
                  <div class="pl-order-toal-container__inner large">
                    <div class="pl-order-toal-container__item">
                      <span class="pl-order-toal-container__item-title-wrap" style="color: rgb(34, 34, 34); font-size: 15px; font-weight: 700">{{ $t('order.sxsj') }}</span>
                    </div>
                    <!-- 生效类型 -->
                    <a-radio-group v-model:value="timeType">
                      <a-radio :value="1">{{ $t('order.ljsj') }}</a-radio>
                      <a-radio :value="2">{{ $t('order.dssj') }}</a-radio>
                    </a-radio-group>
                  </div>
                  <div class="pl-order-toal-container__inner large" v-show="timeType == 2">
                    <!-- 生效时间 -->
                    <a-date-picker -disabled-date="disabledDate" :disabled-time="disabledDateTime" :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" placeholder="Select Time" @change="onChangeTime" @ok="onOkTime" />
                  </div>
                </div>
              </div>
              <div class="pl-wrap-container large">
                <div class="pl-order-toal-container">
                  <div class="pl-order-toal-container__inner large">
                    <div class="pl-order-toal-container__item">
                      <span class="pl-order-toal-container__item-title-wrap" style="color: rgb(34, 34, 34); font-size: 15px; font-weight: 700">{{ $t('confirm.total') }}</span>
                      <span class="pl-order-toal-container__item-content" style="color: rgb(34, 34, 34); font-size: 15px; font-weight: 700">${{ sumTotal }}</span>
                    </div>
                    <!-- 立即下单 -->
                    <div class="pl-order-toal-container__btn-box">
                      <button @click="onSubmit()" type="button" class="comet-btn comet-btn-primary comet-btn-large pl-order-toal-container__btn">
                        <span>{{ $t('confirm.place.order') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 条款与政策 -->
              <div class="pl-text-container-pc">
                {{ $t('confirm.order.desc') }}
                <a href="javascript:" target="_blank">{{ $t('confirm.order.policy') }}</a
                >.
              </div>
              <div class="safe-info-hook-container">
                <div class="safe-info-hook safe-info--safe-info--3ZGOx0g">
                  <!-- <img class="safe-info--safe-info-img--3jcX9GA" src="//ae01.alicdn.com/kf/H8e0188e31d864f94ab796d13535dd096g.png" /> -->
                  <div class="safe-info--safe-info-text--gXkN0DK">
                    {{ $t('confirm.payment') }}
                  </div>
                  <img class="safe-info--safe-info-img--3jcX9GA" src="../static/img/H5e.png" />
                </div>
              </div>
              <!-- <div class="survey-link"><a href="javascript:;"></a></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 选择地址 -->
  <create-address ref="address" @setAddress="setAddress"></create-address>

  <!-- 支付方式 -->
  <payment ref="payment" :isInit="true" @setWallet="setWallet"></payment>

  <!-- 页脚 -->
  <Footer></Footer>
</template>

<script>
import dayjs from 'dayjs'
import Footer from '@/components/Footer.vue'
import CreateAddress from '@/components/member/CreateAddress.vue'
import Payment from '@/components/member/Payment.vue'
import { DollarOutlined } from '@ant-design/icons-vue'
const TYPE = {
  goods: 'goods',
  cart: 'cart'
}
export default {
  components: { CreateAddress, Payment, Footer, DollarOutlined },
  name: 'confirmVue',
  data() {
    return {
      sp_info:{},

      //生效
      timeType: 1,
      timeStr: '',

      //类型
      type: '',
      goods: {
        goods_id: 0,
        num: 0,
        sku_id: 0
      },
      cart: [],

      //数据列表
      orderList: [],

      //总计金额
      subTotal: '0.00',
      sumTotal: '0.00',

      //当前地址
      currentAddress: null,
      currentWallet: null
    }
  },
  setup() {
    // const range = (start, end) => {
    //   const result = []

    //   for (let i = start; i < end; i++) {
    //     result.push(i)
    //   }

    //   return result
    // }

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < dayjs().startOf('day')
    }

    const disabledDateTime = () => {
      return {
        // disabledHours: () => range(0, 24).splice(4, 20)
        // disabledMinutes: () => range(30, 60),
        // disabledSeconds: () => [55, 56]
      }
    }
    return {
      dayjs,
      disabledDate,
      disabledDateTime
    }
  },
  created() {
    //缓存中获取token
    this.sp_info = this.$common.getInfo();
    if(this.sp_info?.real){
      this.sp_info.real = 2
    }
    // console.log(this.sp_info)

    //console.log("参数", this.$route.query);
    let query = this.$route.query
    this.type = query.type

    let that = this
    //直接商品
    if (query.type == TYPE.goods) {
      this.goods = query
      this.$api.order
        .getConfirm(query)
        .then((res) => {
          that.orderList = res.data
          that.setTotal()
        })
        .catch(() => {
          setTimeout(() => {
            that.$router.push({ name: 'index' })
          }, 1000)
        })
    } else if (query.type == TYPE.cart) {
      this.cart = query
      //购物车
      this.$api.order.getConfirmList(query).then((res) => {
        that.orderList = res.data
        that.setTotal()
      })
    }
  },
  methods: {
    onChangeTime(e, dateString) {
      // console.log(e.value, dateString)
      this.timeStr = dateString
    },
    onOkTime() {},
    onChangeAddress() {
      this.$refs.address.open(1)
    },
    onCreateAddress() {
      this.$refs.address.open(2)
    },
    /**
     * 地址控件
     * @param {*} address
     */
    setAddress(address) {
      console.log(address, '默认地址')
      this.currentAddress = address
    },
    onChangePayment() {
      this.$refs.payment.open()
    },
    setWallet(wallet) {
      console.log(wallet, '默认钱包')
      this.currentWallet = wallet
    },
    onSubNum(vo) {
      if (vo.num <= 1) {
        return
      }
      vo.num--

      //请求接口
      if (this.type == TYPE.goods) {
        this.goods.num--
      } else if (this.type == TYPE.cart) {
        this.$api.order.saveShopCart({ cart_id: vo.cart_id, type: 'sub' }).then((res) => {
          console.log(res)
        })
      }

      //计算总价
      this.setTotal()
    },
    onAddNum(vo, num = 0) {
      if (vo.num > vo.sku.total) {
        return
      }
      if (num == 0) {
        vo.num++
      }

      //请求接口
      if (this.type == TYPE.goods) {
        this.goods.num++
      } else if (this.type == TYPE.cart) {
        this.$api.order.saveShopCart({ cart_id: vo.cart_id, num: num, type: 'add' }).then((res) => {
          console.log(res)
        })
      }

      //计算总价
      this.setTotal()
    },
    onNumber(vo) {
      console.log(vo)
      let num = vo.sku.total
      if (vo.num > num) {
        vo.num = num
      }

      this.goods.num = vo.num

      if (this.type == TYPE.cart) {
        this.onAddNum(vo, vo.num)
      }

      //计算总价
      this.setTotal()
    },
    /**
     * 计算总金额
     */
    setTotal() {
      let total = 0
      for (var i in this.orderList) {
        for (var k in this.orderList[i].goods_list) {
          let goods = this.orderList[i].goods_list[k]
          let price = goods.sku.price
          let num = goods.num
          total += parseFloat(parseInt(num) * parseFloat(price))

          //
        }
      }

      if (total > 0) {
        this.subTotal = total.toFixed(2)
        this.sumTotal = total.toFixed(2)
      } else {
        this.subTotal = '0.00'
        this.sumTotal = '0.00'
      }
      console.log('总金额', this.subTotal, this.sumTotal, this.goods, this.cart)
    },
    /**
     * 立即下单支付
     */
    onSubmit() {
      let param = {}

      if (!this.currentAddress) {
        this.$message.warning('must get address')
        return
      }

      if (this.type == TYPE.goods) {
        param = {
          type: this.type,
          goods_id: this.goods.goods_id,
          num: this.goods.num,
          sku_id: this.goods.sku_id,
          address_id: this.currentAddress.id,
          time_type: this.timeType,
          time_str: this.timeStr
        }
      } else if (this.type == TYPE.cart) {
        param = {
          type: this.type,
          cart_ids: this.cart.cart_ids,
          address_id: this.currentAddress.id,
          time_type: this.timeType,
          time_str: this.timeStr
        }
      }

      let that = this
      this.$message.loading({ content: 'Submit order...', key: 'msg' })
      this.$api.order.saveOrder(param).then((res) => {
        //支付
        let payParam = {
          wallet_id: that.currentWallet.id,
          order_sn: res.data
        }

        this.$message.loading({ content: 'Payment order...', key: 'msg' })

        that.$api.order.payOrder(payParam).then((res) => {
          that.$message.success({
            content: res.msg,
            key: 'msg'
          })

          that.$router.push({ name: 'orderAll' })
        })
      })
    }
  }
}
</script>

<style scoped src="../static/css/confirm.css"></style>
<style scoped src="../static/css/confirm-index.css"></style>
<style scoped>
.search-wrapper-box .logo {
    height: 50px;
    margin-right: 40px;
    overflow: hidden;
    width: 260px;
    z-index: 1;
}
</style>
